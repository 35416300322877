// TODO - Dev only file. Remove eventually.
// Utilities used only during code development while backend is being developed

import ReadinessClient, {
    IPersonnelCriterion,
    IPersonnelCriterionStatus,
    IReadinessScopeDefinition,
    IReadinessStatus,
    ReadinessScopeStatus,
} from 'clients/readiness-client';
import { IAuthContext } from 'contexts/auth-context';
import { useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';

const CRITERIA_COUNT = 40;
const SCOPE_COUNT = 8;

const MIN_CRITERIA_COUNT = 1; // Min number of criteria a scope can depend on. Used for creating random scopes.
const MAX_CRITERIA_COUNT = 5; // Max number of criteria a scope can depend on. Used for creating random scopes.

export let placeholderCriteria: IPersonnelCriterion[] = [];
export let placeholderScopes: IReadinessScopeDefinition[] = [];
let placeholderPersonnelCriterionStatus: ReadinessScopeStatus = {};

function createPlaceholderValues(): void {
    placeholderCriteria = createPlaceholderCriteria(CRITERIA_COUNT);
    placeholderScopes = createPlaceholderScopes(
        SCOPE_COUNT,
        placeholderCriteria,
        MIN_CRITERIA_COUNT,
        MAX_CRITERIA_COUNT,
    );
    placeholderPersonnelCriterionStatus = createPersonnelCriterionStatus(
        placeholderCriteria,
        personnelIds,
    );
}
/*  Generating placeholder data
    The reason for the following timeout shenanigans is that
    the function createPersonnelCriterionStatus needs to run
    Object.values(IReadinessStatus) at the beginning but the
    enum IReadinessStatus is not available at that time. The
    timeout below allows its value to become available.

    TODO - Once the Readiness effort resumes, move the following
           to a more suitable place, perahps under the component "Readiness".
*/

// setTimeout(createPlaceholderValues, 0);

const personnelIds: string[] = [];
/*
TODO - When development on Readiness resumes, reinstate
       the following or add personnel Ids a different way.
const personnelIds = [
    '33666',
    '1239838',
    '146354',
    '445772',
    '1456413',
    '288964',
    '1201300',
    '1543581',
    '6190890',
    '1406029',
    '1544678',
    '6184510',
    '6203715',
    '6087167',
    '1479487',
    '6183762',
    '1523333',
    '1399041',
    '1470079',
];
*/

// Pick a given number of random items from a list.
function pickRandomItems<T>(list: T[], count: number): T[] {
    const result: T[] = [];
    const len = list.length;
    const alreadyPicked = new Set<number>();
    for (let ix = 0; ix < count; ix++) {
        const pickIx = Math.floor(Math.random() * len);
        if (!alreadyPicked.has(pickIx)) {
            result.push(list[pickIx]);
            alreadyPicked.add(pickIx);
        }
    }
    return result;
}

// Pick a random number between the given numbers, inclusive.
function pickRandomNumber(min: number, max: number): number {
    const result = Math.floor(Math.random() * (max - min + 1)) + min;
    if (result > max || result < min) {
        console.error('Error in function pickRandomNumber', min, max, result);
        return min;
    }
    return result;
}

interface IUseCreatePlaceholderScopes {
    isNewScopesCreated: boolean;
}

export const useCreatePlaceholderScopes = (
    authContext: IAuthContext,
): IUseCreatePlaceholderScopes => {
    const [isNewScopesCreated, setIsNewScopesCreated] = useState<boolean>(false);

    const isMounted = useIsMounted();

    useEffect(() => {
        createPlaceholderScopes(authContext);
    }, []);

    // Create Scopes
    async function createPlaceholderScopes(authContext: IAuthContext): Promise<void> {
        // First check if there's already some scope available
        try {
            const scopes = await ReadinessClient.getScopes(authContext);
            if (scopes.length > 0) {
                // No need to create scopes. Some are already created.
                if (isMounted()) {
                    setIsNewScopesCreated(false);
                }
                return;
            }
            const a: number[] = [];
            for (let ix = 0; ix < SCOPE_COUNT; ix++) {
                a.push(ix);
            }
            await Promise.all(
                a.map((value) => {
                    return ReadinessClient.createScope(authContext, {
                        name: `Scope ${value + 1}`,
                    });
                }),
            );
            if (isMounted()) {
                setIsNewScopesCreated(true);
            }
        } catch {
            console.error('Unable to fetch scopes or create placeholder ones');
        }
    }
    return { isNewScopesCreated };
};

function createPlaceholderCriteria(criteriaCount: number): IPersonnelCriterion[] {
    const criteria: IPersonnelCriterion[] = [];
    for (let ix = 1; ix <= criteriaCount; ix++) {
        criteria.push({
            id: 'criterion-' + ix.toString(),
            name: 'Criterion ' + ix.toString(),
        });
    }
    return criteria;
}

function createPlaceholderScopes(
    scopeCount: number,
    criteria: IPersonnelCriterion[],
    minCriteriaCount: number,
    maxCriteriaCount: number,
): IReadinessScopeDefinition[] {
    const result: IReadinessScopeDefinition[] = [];
    for (let scopeIx = 1; scopeIx <= scopeCount; scopeIx++) {
        const randomCriteria = pickRandomItems<IPersonnelCriterion>(
            criteria,
            pickRandomNumber(minCriteriaCount, maxCriteriaCount),
        ).sort((c1, c2) => parseInt(c1.id) - parseInt(c2.id));
        const randomCriterion = pickRandomItems<IPersonnelCriterion>(randomCriteria, 1)[0].id;

        const scope: IReadinessScopeDefinition = {
            id: 'scope-' + scopeIx.toString(),
            name: 'Scope ' + scopeIx.toString(),
            criteria: randomCriteria,
            primaryCriterionId: randomCriterion,
        };
        result.push(scope);
    }
    return result;
}

function createPersonnelCriterionStatus(
    placeholderCriteria: IPersonnelCriterion[],
    personnelIds: string[],
): ReadinessScopeStatus {
    const personMinCriterionCount = 0;
    const personMaxCriterionCount = 20;
    const result: ReadinessScopeStatus = {};
    const readinessStatus = Object.keys(IReadinessStatus) as (keyof typeof IReadinessStatus)[];
    for (let personIx = 0; personIx < personnelIds.length; personIx++) {
        const personnelId = personnelIds[personIx];
        const statuses: IPersonnelCriterionStatus[] = [];
        result[personnelId] = statuses;
        const criteria = pickRandomItems<IPersonnelCriterion>(
            placeholderCriteria,
            pickRandomNumber(personMinCriterionCount, personMaxCriterionCount),
        );
        for (let criterionIx = 0; criterionIx < criteria.length; criterionIx++) {
            statuses.push({
                personnelId: personnelId,
                readinessStatus: readinessStatus[pickRandomNumber(0, readinessStatus.length - 1)],
                criterion: placeholderCriteria[criterionIx],
            });
        }
    }
    return result;
}
