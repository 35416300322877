import React, { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { Location } from 'history';
import { useParams } from 'react-router';
import { AssignmentsPageContentType } from 'components/user-assignments/user-assignments-constants';
import UserAssignmentsWrapper from 'components/user-assignments/user-assignments-wrapper';
import { Role } from 'configs/roles';
import { Redirect } from 'react-router';
import { FacilityUserType } from 'utils/facilities-utils';

interface IUserAssignmentsEmployeeOrVisitorProps {
    location: Location;
}

export default function UserAssignmentsEmployeeOrVisitor(
    props: IUserAssignmentsEmployeeOrVisitorProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const { visitorIdOrAlias } = useParams<{ visitorIdOrAlias?: string }>();

    if (!authContext.isInRole(Role.PersonnelAssignmentRead))
        return <Redirect to='/profile/user/me' push={false} />;

    // TODO: Consider replacing the props show* with a context
    return (
        <UserAssignmentsWrapper
            location={props.location}
            contentType={AssignmentsPageContentType.EmployeeOrVisitor}
            visitorIdOrAlias={visitorIdOrAlias}
            //
            showSearchBox={authContext.isInRole(Role.PersonnelAssignmentRead)}
            //
            showEmployeeInfo={authContext.isInRole(Role.PersonnelAssignmentRead)}
            showViewOthersProfileButton={authContext.isInRole(Role.PersonnelAssignmentRead)}
            //
            showVisitorInfo={authContext.isInRole(Role.VisitorRecordRead)}
            showEditVisitorButton={authContext.isInRole(Role.VisitorRecordWrite)}
            //
            showAttributes={authContext.isInRole(Role.PersonnelAttributesRead)}
            showAddAttributeButton={authContext.isInRole(Role.PersonnelAttributesAdd)}
            showDeleteAttributeButton={authContext.isInRole(Role.PersonnelAttributesDelete)}
            //
            showEligibilities={authContext.isInRole(Role.PersonnelEligibilitiesRead)}
            showAddEligibilityButton={authContext.isInRole(Role.PersonnelEligibilitiesAdd)}
            showDeleteEligibilityButton={authContext.isInRole(Role.PersonnelEligibilitiesDelete)}
            //
            showEquipment={userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole)}
        />
    );
}
