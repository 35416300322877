import config from 'environments/environment';
import {
    AUTH_PREFIX,
    GetAadHttpOptions,
    HttpContentTypeEnum,
    IPagedResults,
    JSON_CONTENT_TYPE,
} from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { ITimestampChange } from 'clients/cloud-screening-client';
import jwtDecode from 'jwt-decode';
import {
    getDate,
    isoDateStringToUtcMilliseconds,
    localDateToUTCDate,
    minutesToMilliseconds,
} from 'utils/time-utils';
import { IUserContext } from 'contexts/user-context';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import { readErrorMessageBody } from 'utils/misc-utils';
import { Dictionary } from 'assets/constants/global-constants';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { Moment } from 'moment';
import { IActivityToken } from 'clients/activities-client';
import { IFacilitiesFilterDefinitions } from 'components/facilities/facilities-management/facilities-management-equipment-page';
import { PartialEquipmentRecord } from 'components/user-assignments/equipment/equipment';
import { ISeatSlot } from 'components/facilities/common/facilities-timeslot-utils';
import { FacilityUserType } from 'utils/facilities-utils';

export enum ReservationView {
    Map = 'Map',
    Table = 'Table',
    Calendar = 'Calendar',
}

export const Regions = {
    West: 'West',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    West_Redmond: 'West (Redmond)',
    East: 'East',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'East_Reston_Elkridge': 'East (Reston, Elkridge)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    South_Atlanta: 'South (Atlanta)',
    Other: 'Other',
} as const;

export const EquipmentTypes = {
    CAC: 'CAC',
    FacilityBadge: 'Facility badge',
    ICBadge: 'IC badge',
    RSA: 'RSA',
    SIPR: 'SIPR',
    RSTARME: 'SmartCard (R*ME)',
    ESTARME: 'SmartCard (E*ME)',
    Yubikey: 'Yubikey',
    AccessFob: 'Access fob',
    PIV: 'PIV',
    Laptop: 'Laptop',
} as const;

export const Status = {
    Active: 'Active',
    Inactive: 'Inactive',
    Returned: 'Returned',
    NotApplicable: 'NotApplicable',
    CheckedIn: 'CheckedIn',
    CheckedOut: 'CheckedOut',
    Lost: 'Lost',
    Stolen: 'Stolen',
} as const;

export const emptyEquipmentRecord: IEquipmentRecord = {
    id: '',
    ownerPersonnelId: '',
    facilityId: '',
    region: Regions.West,
    type: EquipmentTypes.CAC,
    status: Status.Active,
    metaData: {},
    issued: { by: '', atUtc: 0 },
    expirationOnUtcMilliseconds: 0,
    returnedOnUtcMilliseconds: 0,
    reportedOnUtcMilliseconds: 0,
    createdTimestamp: { by: '', atUtc: 0 },
    lastModified: { by: '', atUtc: 0 },
    lastOperation: '',
};

type EquipmentSearchResults = {
    searchResults: IPagedResults<IEquipmentRecord>;
    totalFilteredRecordsCount: number;
};

const facilityKioskClientPaths = ['/facilities-kiosk', '/facilities-display'];

const validFacilityRoles: FacilityUserType[] = [
    FacilityUserType.UserRole,
    FacilityUserType.AdminService,
    FacilityUserType.ManagerRole,
];

const facilitiesConfig = config.facilitiesServiceConfig;
let cachedFacilitiesToken: undefined | IFacilitiesTokenResult = undefined;
class FacilitiesClient {
    static async getFacilitiesToken(
        authContext: IAuthContext,
        personnelId?: string,
        signal?: AbortSignal,
    ): Promise<IFacilitiesTokenResult | undefined> {
        if (
            personnelId === undefined &&
            cachedFacilitiesToken &&
            cachedFacilitiesToken.expires &&
            !isTokenExpired(cachedFacilitiesToken.expires)
        ) {
            return cachedFacilitiesToken;
        }
        try {
            if (
                !authContext.isKioskRenderMode() ||
                cachedFacilitiesToken === undefined ||
                personnelId !== undefined
            ) {
                const httpOptions = await GetAadHttpOptions(
                    authContext,
                    facilitiesConfig.aadScopes,
                );
                httpOptions.method = 'POST';
                httpOptions.signal = signal;
                if (personnelId !== undefined) {
                    httpOptions.headers = httpOptions.headers ?? {};
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (httpOptions.headers as any)['personnelId'] = personnelId;
                }
                const url = `${facilitiesConfig.baseUrl}${facilitiesConfig.tokenEndpoint}`;

                const facilitiesToken = await ClientUtils.doHttpRequest<IFacilitiesTokenResult>(
                    url,
                    httpOptions,
                );

                if (personnelId === undefined) {
                    cachedFacilitiesToken = facilitiesToken;
                }

                return facilitiesToken;
            } else {
                throw new Error('Facility Kiosk Token has expired or will be expiring soon');
            }
        } catch (ex) {
            console.error(ex);

            if (authContext.isKioskRenderMode()) {
                throw ex;
            }
        }
    }

    static deserializeFacilitiesToken(
        facilitiesToken: IFacilitiesTokenResult | undefined | string,
    ): IFacilitiesToken | undefined {
        if (facilitiesToken) {
            let token = '';
            if (typeof facilitiesToken === 'string') {
                token = facilitiesToken;
            } else {
                token = facilitiesToken.token;
            }
            try {
                return jwtDecode(token) as IFacilitiesToken;
            } catch {}
        }
        return undefined;
    }

    static canAccessFacilities(
        facilitiesToken: IFacilitiesTokenResult | undefined | string,
    ): boolean {
        const decodedToken = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);
        if (decodedToken && decodedToken.userRoles) {
            return FacilitiesClient._searchObjContainsValues(
                decodedToken.userRoles,
                validFacilityRoles,
            );
        }
        return false;
    }

    static canAccessFacility(
        facilitiesToken: IFacilitiesTokenResult | undefined | string,
        facilityId: string | undefined,
    ): boolean {
        if (facilityId) {
            const decodedToken = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);
            if (decodedToken && decodedToken.facilities) {
                return FacilitiesClient._searchObjContainsValues(decodedToken.facilities, [
                    facilityId,
                ]);
            }
        }
        return false;
    }

    static async downloadEquipmentReport(
        authContext: IAuthContext,
        userContext: IUserContext,
        urlParams: URLSearchParams,
    ): Promise<string> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        const { baseUrl, downloadEquipmentReportEndppint } = config.facilitiesServiceConfig;

        if (!facilitiesToken) {
            throw new Error('Unable to obtain facilties token');
        }

        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
            '',
            HttpContentTypeEnum.TextPlain,
        );

        const urlParamsObj = new URLSearchParams(urlParams);

        let url = `${baseUrl}${downloadEquipmentReportEndppint}`;
        const urlParamsStr = urlParamsObj.toString();
        if (urlParamsStr) {
            url += `?${urlParamsStr}`;
        }

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const equipmentResult = await response.json();
            return equipmentResult;
        } else {
            throw response;
        }
    }

    static providePersonnelFromFacilitiesToken(
        facilitiesToken: IFacilitiesTokenResult | undefined | string,
    ): string | undefined {
        const decodedToken = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);
        return decodedToken?.personnelId;
    }

    static hasUserTypeCachedToken(
        facilitiesUserType: FacilityUserType,
        facilitiesToken?: IFacilitiesTokenResult,
    ): boolean {
        return this.hasUserType(facilitiesToken ?? cachedFacilitiesToken, facilitiesUserType);
    }

    static hasUserType(
        facilitiesToken: IFacilitiesTokenResult | undefined | string,
        facilitiesUserType: FacilityUserType,
    ): boolean {
        const decodedToken = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);
        if (decodedToken && decodedToken.userRoles) {
            return FacilitiesClient._searchObjContainsValues(decodedToken.userRoles, [
                facilitiesUserType,
            ]);
        }
        return false;
    }

    static async getFacilityActivityToken(
        authContext: IAuthContext,
        facilitiesToken: IFacilitiesTokenResult,
    ): Promise<IActivityToken> {
        const url = facilitiesConfig.baseUrl + facilitiesConfig.activityTokenEndpoint;

        const httpOptions = await this._getFacilitiesHttpOptions(authContext, facilitiesToken);

        const res: Response = await fetch(url, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    private static _searchObjContainsValues(
        searchObj: undefined | string | string[],
        values: string[],
    ): boolean {
        if (searchObj) {
            if (typeof searchObj === 'string') {
                return values.indexOf(searchObj as string) !== -1;
            } else {
                return values.findIndex((x) => -1 !== searchObj.indexOf(x)) !== -1;
            }
        }
        return false;
    }

    private static async _getFacilityQuery(
        facilityId: string,
        httpOptions: RequestInit,
    ): Promise<IFacilityRecord> {
        const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}`;
        // TODO: Determine if we can switch this to a fetch instead
        return ClientUtils.doHttpRequest<IFacilityRecord>(url, httpOptions);
    }

    private static async _getMyFacilitySmartCardEquipmentQuery(
        facilityId: string,
        httpOptions: RequestInit,
    ): Promise<IPagedResults<IEquipmentRecord>> {
        const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/mySmartCardEquipment`;
        // TODO: Determine if we can switch this to a fetch instead
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getFacilityRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
    ): Promise<IFacilityRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );
            return FacilitiesClient._getFacilityQuery(facilityId, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async getFacilityRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
    ): Promise<IFacilityRecord[]> {
        return FacilitiesClient._getFacilityRecords(authContext, userContext, 'facilities');
    }

    static async getFacilityRecordsManager(
        authContext: IAuthContext,
        userContext: IUserContext,
    ): Promise<IFacilityRecord[]> {
        return FacilitiesClient._getFacilityRecords(authContext, userContext, 'managerOf');
    }

    static async getFacilityRecordsEquipmentOfficer(
        authContext: IAuthContext,
        userContext: IUserContext,
    ): Promise<IFacilityRecord[]> {
        return FacilitiesClient._getFacilityRecords(authContext, userContext, 'equipmentOfficerOf');
    }

    private static async _getFacilityRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        property: string,
    ): Promise<IFacilityRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const facilityTokenInfo = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);

            let promises: Promise<IFacilityRecord>[] = [];
            if (facilityTokenInfo) {
                const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                    authContext,
                    facilitiesToken,
                );

                if (typeof facilityTokenInfo[property as keyof IFacilitiesToken] === 'object') {
                    const facilityIds = facilityTokenInfo[property as keyof IFacilitiesToken];
                    promises = [
                        ...(facilityIds as string[])?.map(
                            (x): Promise<IFacilityRecord> => {
                                return FacilitiesClient._getFacilityQuery(x, httpOptions);
                            },
                        ),
                    ];
                } else if (facilityTokenInfo[property as keyof IFacilitiesToken]) {
                    const facilityId = facilityTokenInfo[property as keyof IFacilitiesToken];
                    promises.push(
                        FacilitiesClient._getFacilityQuery(facilityId as string, httpOptions),
                    );
                }
            }
            return Promise.all(promises);
        }
        return [];
    }

    static async getProfilePhoto(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        photoSize?: string,
    ): Promise<string> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/profilephoto`;

            const urlParams = new URLSearchParams();
            if (photoSize) {
                urlParams.append('photoSize', photoSize);
            }

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            return ClientUtils.doHttpRequest(url, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async getFacilityTimeslots(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        startDate?: Moment,
        endDate?: Moment,
    ): Promise<IFacilityTimeslotsResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations/timeslots`;

            const urlParams = new URLSearchParams();
            if (startDate) {
                urlParams.append('startDateUtc', startDate.tz('utc').toISOString());
            }
            if (endDate) {
                urlParams.append('endDateUtc', endDate.tz('utc').toISOString());
            }
            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            return ClientUtils.doHttpRequest(url, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async getSeatReservations(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        startDate?: Date,
        endDate?: Date,
    ): Promise<IReservationRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations/all`;

            const urlParams = new URLSearchParams();
            if (startDate) {
                urlParams.append('startDateUtc', startDate.toUTCString());
            }
            if (endDate) {
                urlParams.append('endDateUtc', endDate.toUTCString());
            }

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            return ClientUtils.doHttpRequest(url, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async getSeatAvailability(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        epochDateUtc?: number,
        continuationToken?: string,
    ): Promise<ISeatAvailabilityResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations/availability`;

            const urlParams = new URLSearchParams();
            if (epochDateUtc) {
                urlParams.append('epochDateUtc', epochDateUtc.toString());
            }

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
                continuationToken,
            );

            return ClientUtils.doHttpRequest(url, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async getSeatAvailabilityForCalendar(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        startDate?: Date,
        endDate?: Date,
        continuationToken?: string,
        includeMySeat?: boolean,
    ): Promise<ISeatAvailabilityCalendarResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();

        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations/availability/calendar`;

            const urlParams = new URLSearchParams();
            if (startDate) urlParams.append('startDateUtc', startDate.toUTCString());
            if (endDate) urlParams.append('endDateUtc', endDate.toUTCString());

            // Defaults to true, so only include URL query string parameter for this when false.
            if (includeMySeat !== undefined)
                urlParams.append('includeMySeat', includeMySeat.toString());

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
                continuationToken,
            );

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async preclaimSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        seatId: string,
        timeslot: number,
        includeNextTimeslotDetails?: boolean,
    ): Promise<IReservationRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/seat/reservation/preclaim`;

            if (includeNextTimeslotDetails !== undefined) {
                url += `?includeNextTimeslotDetails=${includeNextTimeslotDetails}`;
            }

            const request: IReservationPreClaimRequest = {
                facilityId: facilityId,
                seatId: seatId,
                timeslot: timeslot,
            };

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );
            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            switch (result.status) {
                case 200:
                    return result.json();
                case 400:
                    throw readErrorMessageBody(result);
                default:
                    throw new Error('Unable to pre claim seat reservation');
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async getNextTimeSlotInfo(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        seatId: string,
        timeslot: number,
    ): Promise<IFacilityTimeslotDetails> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/seat/reservation/nexttimeslotdetails`;

            const urlParams = new URLSearchParams();

            urlParams.append('facilityId', facilityId);
            urlParams.append('seatId', seatId);
            urlParams.append('timeslot', timeslot.toString());

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );
            httpOptions.method = 'GET';

            const result = await fetch(url, httpOptions);

            switch (result.status) {
                case 200:
                    return result.json();
                case 400:
                    throw readErrorMessageBody(result);
                default:
                    throw new Error('Unable to get the next time slot info');
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async getSeatById(
        authContext: IAuthContext,
        userContext: IUserContext,
        seatId: string,
    ): Promise<ISeatRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seat/${seatId}`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'GET';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error(`We're unable to obtain a seat record by seat id ${seatId}`);
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async getAllMyReservations(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId?: string,
    ): Promise<IReservationRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = !!facilityId
                ? `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations`
                : `${facilitiesConfig.baseUrl}v1/facilities/seats/reservations`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'GET';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error(
                    "We're unable to obtain any existing reservations for you at this time",
                );
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async confirmSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        claimCode: string,
        icmDescription?: string,
    ): Promise<IReservationRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seat/reservation/confirm`;

            const request: IReservationConfirmRequest = {
                facilityId: facilityId,
                claimCode: claimCode,
                icmDescription: icmDescription,
            };

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error('Unable to confirm reservation');
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async confirmSeatReservations(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        request: IReservationsConfirmRequest[],
    ): Promise<IReservationRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats/reservations/confirm`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getAllEquipmentByPersonnelId(
        authContext: IAuthContext,
        userContext: IUserContext,
        personnelId: string,
    ): Promise<EquipmentRecordResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (!facilitiesToken) {
            throw new Error('Facilities token is invalid');
        }

        const url = `${facilitiesConfig.baseUrl}v1/facilities/equipment/personnel/${personnelId}`;
        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
        );
        httpOptions.method = 'GET';

        const result = await fetch(url, httpOptions);

        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async cancelSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        reservationId: string,
    ): Promise<void> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seat/reservation/${reservationId}/cancel`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'DELETE';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return;
            }

            throw new Error('Unable to cancel reservation');
        }
        throw new Error('Facilities token is invalid');
    }

    static async checkInSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        reservationId: string,
    ): Promise<IReservationRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seat/reservation/${reservationId}/checkin`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error('Unable to Check-in to reservation');
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async checkOutSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        reservationId: string,
    ): Promise<IReservationRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seat/reservation/${reservationId}/checkout`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error('Unable to Check-out of reservation');
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getSmartCardEquipmentRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        currentUserEquipment?: boolean,
    ): Promise<IEquipmentRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();

        if (facilitiesToken) {
            let equipmentFinalResult: IEquipmentRecord[] = [];
            let continuationToken;
            let equipmentResult: IPagedResults<IEquipmentRecord>;

            const facilityTokenInfo = FacilitiesClient.deserializeFacilitiesToken(facilitiesToken);
            const loadEquipmentResults = async (continuationTokenParam?: string): Promise<void> => {
                if (currentUserEquipment) {
                    // this will all smart card equipments belong to user's personnel Id defined in facilitiesToken
                    const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                        authContext,
                        facilitiesToken,
                        continuationTokenParam,
                    );

                    equipmentResult = await FacilitiesClient._getMyFacilitySmartCardEquipmentQuery(
                        facilityId,
                        httpOptions,
                    );
                } else {
                    // this will get all smart card equipments for provided facility, requiring elevated permissions
                    const urlParams = new URLSearchParams();
                    urlParams.append('Facility', facilityId);
                    SmartCardArray.forEach((smartCard) =>
                        urlParams.append('Type', smartCard.value),
                    );

                    const result = await FacilitiesClient.searchEquipments(
                        authContext,
                        userContext,
                        urlParams,
                        500,
                        continuationTokenParam,
                    );
                    equipmentResult = result.searchResults;
                }

                continuationToken = equipmentResult.continuationToken;
                equipmentFinalResult = equipmentFinalResult.concat(equipmentResult?.results ?? []);
            };

            if (facilityTokenInfo) {
                await loadEquipmentResults();

                while (continuationToken) {
                    await loadEquipmentResults(continuationToken);
                }

                return equipmentFinalResult;
            }
        }

        return [];
    }

    static async searchEquipments(
        authContext: IAuthContext,
        userContext: IUserContext,
        urlParams: URLSearchParams,
        resultCount?: number,
        continuationToken?: string,
    ): Promise<EquipmentSearchResults> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        const { baseUrl, searchEquipmentEndpoint } = config.facilitiesServiceConfig;

        if (!facilitiesToken) {
            throw new Error('Unable to obtain facilties token');
        }

        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
            continuationToken,
        );

        const urlParamsObj = new URLSearchParams(urlParams);
        if (resultCount !== undefined) {
            urlParamsObj.append('resultCount', resultCount.toString());
        }

        let url = `${baseUrl}${searchEquipmentEndpoint}`;

        const urlParamsStr = urlParamsObj.toString();
        if (urlParamsStr) {
            url += `?${urlParamsStr}`;
        }

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const equipmentResult = await response.json();
            return equipmentResult;
        } else {
            throw response;
        }
    }

    static async multiCheckInOutEquipment(
        authContext: IAuthContext,
        userContext: IUserContext,
        equipmentIds: string[],
        facilityId: string,
        equipmentState: EquipmentState,
    ): Promise<ICheckInOutEquipmentListResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();

        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/equipment/${equipmentState}`;

            const request: ICheckInOutEquipmentListRequest = {
                ids: equipmentIds,
                facilityId: facilityId,
            };

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'PUT';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw new Error(
                    `We're unable to ${equipmentState} equipment with ids ${equipmentIds.join(
                        ',',
                    )}`,
                );
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async getFacilitiesHttpOptionsWithFacilitiesToken(
        authContext: IAuthContext,
        userContext?: IUserContext,
    ): Promise<RequestInit> {
        const facilitiesToken = userContext
            ? await userContext.refreshFacilitiesToken()
            : await FacilitiesClient.getFacilitiesToken(authContext);
        if (!facilitiesToken) {
            throw new Error('Failed to retrieve facilities token');
        }

        return await FacilitiesClient._getFacilitiesHttpOptions(authContext, facilitiesToken);
    }

    private static async _getFacilitiesHttpOptions(
        context: IAuthContext,
        facilitiesToken: IFacilitiesTokenResult,
        continuationToken?: string,
        contentType?: HttpContentTypeEnum,
    ): Promise<RequestInit> {
        const authToken = await context.getToken(facilitiesConfig.aadScopes);
        return {
            headers: {
                Authorization: AUTH_PREFIX + authToken,
                'Content-Type': contentType?.toString() ?? JSON_CONTENT_TYPE,
                'facilities-token': facilitiesToken.token,
                'x-continuation-token': continuationToken ? continuationToken : '',
            },
        };
    }

    static async getSeatsByFacilityId(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        continuationToken?: string,
    ): Promise<IPagedResults<ISeatRecord>> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/seats`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
                continuationToken,
            );

            return ClientUtils.doHttpRequest(url, httpOptions);
        }
        throw new Error('Facilities token is invalid');
    }

    static async createSeatRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: ICreateSeatRequest[],
    ): Promise<ISeatRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seats`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async updateSeatRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: IUpdateSeatRequestWithId[],
    ): Promise<ISeatRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seats`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'PUT';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async deleteSeatRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        seatId: string,
    ): Promise<string> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seat/${seatId}`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'DELETE';
            const result = await fetch(url, httpOptions);
            if (result.status === 200) {
                return result.text();
            } else {
                throw result;
            }
        } else {
            throw new Error('Facilities token is invalid');
        }
    }

    static async createEquipmentRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: PartialEquipmentRecord[],
    ): Promise<IEquipmentRecord[]> {
        const { baseUrl, equipmentEndpoint } = config.facilitiesServiceConfig;

        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (!facilitiesToken) {
            throw new Error('Facilities token is invalid');
        }

        const url = `${baseUrl}${equipmentEndpoint}`;
        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
        );

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deleteEquipmentRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        equipmentId: string,
        justification: string,
    ): Promise<void> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const { baseUrl, equipmentEndpoint } = config.facilitiesServiceConfig;
            const url = `${baseUrl}${equipmentEndpoint}/${equipmentId}`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            const request = {
                justification: justification,
            };
            httpOptions.method = 'DELETE';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return;
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async updateEquipmentRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: PartialEquipmentRecord[],
    ): Promise<IEquipmentRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/Equipment`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'PUT';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async uploadEquipmentRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: UploadEquipmentRecord[],
    ): Promise<string | undefined> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (!facilitiesToken) {
            throw new Error('Facilities token is invalid');
        }
        const { baseUrl, equipmentUploadEndpoint } = config.facilitiesServiceConfig;
        const url = `${baseUrl}${equipmentUploadEndpoint}`;

        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
        );

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return result.json();
            case 204:
                return;
            default:
                const text = await result.text();
                console.error(text);
                let json;
                try {
                    json = JSON.parse(text);
                } catch {}
                throw new Error(json?.message || result);
        }
    }

    static async changeSeatReservation(
        authContext: IAuthContext,
        userContext: IUserContext,
        request: IReservationSeatChangeRequest,
    ): Promise<IReservationSeatChangeResponse> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/seat/reservation/change`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'PUT';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async createLogBookRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        personnelId?: string,
    ): Promise<ILogBookRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/logbook`;

            const urlParams = new URLSearchParams();
            if (personnelId) {
                urlParams.append('personnelId', personnelId);
            }

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async deleteLogBookRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        personnelId?: string,
    ): Promise<void> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/logbook`;

            const urlParams = new URLSearchParams();
            if (personnelId) {
                urlParams.append('personnelId', personnelId);
            }

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'DELETE';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return;
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getLogBookRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
    ): Promise<ILogBookRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/logbook`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getAllLogBookRecords(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        continuationToken?: string,
    ): Promise<IPagedResults<ILogBookRecord>> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/logbook/all`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
                continuationToken,
            );

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async createBlockedFacilityUserRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        personnelId: string,
    ): Promise<IBlockedFacilityUserRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/blockeduser`;

            const urlParams = new URLSearchParams();
            urlParams.append('personnelId', personnelId);
            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async deleteBlockedFacilityUserRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        personnelId: string,
    ): Promise<void> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/blockeduser`;

            const urlParams = new URLSearchParams();
            urlParams.append('personnelId', personnelId);
            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'DELETE';

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return;
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getBlockedFacilityUserRecordById(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
    ): Promise<IBlockedFacilityUserRecord> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/blockeduser`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getAllBlockedFacilityUserRecordsByFacility(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        continuationToken?: string,
    ): Promise<IPagedResults<IBlockedFacilityUserRecord>> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/blockeduser/all`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
                continuationToken,
            );

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async createFacilityUserFeedbackRecord(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        request: IFacilityUserFeedbackRequest,
    ): Promise<IReservationRecord[]> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            const url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/ratings`;

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );

            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify(request);

            const result = await fetch(url, httpOptions);

            if (result.status === 200) {
                return result.json();
            } else {
                throw result;
            }
        }
        throw new Error('Facilities token is invalid');
    }

    static async getFacilityUserFeedbackStats(
        authContext: IAuthContext,
        userContext: IUserContext,
        facilityId: string,
        startTime: number,
        endTime: number,
    ): Promise<IFacilityUserFeedbackStats> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (facilitiesToken) {
            let url = `${facilitiesConfig.baseUrl}v1/facilities/${facilityId}/ratings`;

            const urlParams = new URLSearchParams();

            urlParams.append('facilityId', facilityId);
            urlParams.append('startTime', startTime.toString());
            urlParams.append('endTime', endTime.toString());

            const urlParamsString = urlParams.toString();
            if (urlParamsString) {
                url = `${url}?${urlParamsString}`;
            }

            const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
                authContext,
                facilitiesToken,
            );
            httpOptions.method = 'GET';

            const result = await fetch(url, httpOptions);

            switch (result.status) {
                case 200:
                    return result.json();
                case 400:
                    throw readErrorMessageBody(result);
                default:
                    throw new Error('Unable to get the user feedback slot info');
            }
        }

        throw new Error('Facilities token is invalid');
    }

    static async getManageEquipmentFilterDefinitions(
        authContext: IAuthContext,
        userContext: IUserContext,
    ): Promise<IFacilitiesFilterDefinitions> {
        const facilitiesToken = await userContext.refreshFacilitiesToken();
        if (!facilitiesToken) {
            throw new Error('Unable to obtain facilities token');
        }

        const url = `${facilitiesConfig.baseUrl}v1/facilities/equipment/filterDefinitions`;
        const httpOptions = await FacilitiesClient._getFacilitiesHttpOptions(
            authContext,
            facilitiesToken,
        );
        httpOptions.method = 'GET';

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return await result.json();
            case 400:
                throw readErrorMessageBody(result);
            default:
                throw new Error('Unable to obtain filter definitions');
        }
    }
}

export default FacilitiesClient;

export const EarlyCheckinGracePeriodInMinutes = 10;
export const LateCheckoutGracePeriodInMinutes = 30;

export enum LogBookActivityName {
    BookLogCreated = 'book_log_created',
    BookLogDeleted = 'book_log_deleted',
}

export enum EquipmentActivityName {
    EquipmentCreated = 'equipment_created',
    EquipmentUpdated = 'equipment_updated',
    EquipmentDeleted = 'equipment_deleted',
    EquipmentCheckedOut = 'equipment_checked_out',
    EquipmentCheckedIn = 'equipment_checked_in',
}

export enum ReservationActivityName {
    ReservationPreclaimed = 'reservation_preclaimed',
    ReservationConfirmed = 'reservation_confirmed',
    ReservationCheckedIn = 'reservation_checked_in',
    ReservationCheckedOut = 'reservation_checked_out',
    ReservationMissed = 'reservation_missed',
    ReservationCancel = 'reservation_cancelled',
    ReservationSeatChanged = 'reservation_seat_changed',
}

export enum SeatActivityName {
    SeatCreated = 'seat_created',
    SeatUpdated = 'seat_updated',
    SeatDeleted = 'seat_deleted',
}

export enum FacilityActivityName {
    FacilityCreated = 'facility_created',
    FacilityUpdated = 'facility_updated',
    FacilityDeleted = 'facility_deleted',
}

export enum FacilityBlockedUserActivityName {
    BlockedUserRecordCreated = 'blocked_facility_user_record_created',
    BlockedUserRecordDeleted = 'blocked_facility_user_record_deleted',
}

export const userFeedbackSubmittedActivityName = 'user_feedback_record_created' as const;

// colors found at https://developer.microsoft.com/en-us/fluentui#/styles/web/colors/shared
export const SeatStatuses = {
    Available: {
        value: 'Available',
        text: 'Available',
        index: 0,
        color: '#00AD56', // GreenCyan10
    },
    AvailableForICM: {
        value: 'AvailableForICM',
        text: 'Available for ICM',
        index: 1,
        color: '#8764b8', // BlueMagenta20
    },
    Unavailable: {
        value: 'Unavailable',
        text: 'Unavailable',
        index: 2,
        color: '#0078D4', // CyanBlue10
    },
    OutOfOrder: {
        value: 'OutOfOrder',
        text: 'Out of Order',
        index: 3,
        color: '#A0AEB2', // Gray10
    },
    MySeat: {
        value: 'MySeat',
        text: 'My Seat',
        index: 4,
        color: '#D13438', // Red10
    },
};

export const SeatStatusArray = [
    SeatStatuses.Available,
    SeatStatuses.AvailableForICM,
    SeatStatuses.Unavailable,
    SeatStatuses.OutOfOrder,
    SeatStatuses.MySeat,
];

export const SeatQualifiers = {
    CustomerFacingAVC: {
        value: 'Customer Facing AVC',
        key: 'CustomerFacingAVC',
    },
    CustomerFacingMPO: {
        value: 'Customer Facing MPO',
        key: 'CustomerFacingMPO',
    },
    CustomerFacingSIPR: {
        value: 'Customer Facing SIPR',
        key: 'CustomerFacingSIPR',
    },
    SupportDesk: {
        value: 'Support Desk',
        key: 'SupportDesk',
    },
    PhoneAtDesk: {
        value: 'Phones',
        key: 'Phones',
    },
};

export const AvailableSeatStatusArray = [SeatStatuses.Available, SeatStatuses.AvailableForICM];
export const UnavailableSeatStatusArray = [
    SeatStatuses.Unavailable,
    SeatStatuses.OutOfOrder,
    SeatStatuses.MySeat,
];

export const SmartCards = {
    RStarME: {
        value: 'RSTARME',
        text: 'R*ME',
        color: '#cc0001',
    },
    EStarME: {
        value: 'ESTARME',
        text: 'E*ME',
        color: '#fdf004',
    },
    Yubikey: {
        value: 'YUBIKEY',
        text: 'YubiKey',
        color: '#3b9036',
    },
};

export const SmartCardArray = [SmartCards.RStarME, SmartCards.EStarME, SmartCards.Yubikey];

export enum ReservationState {
    Preclaimed = 'Preclaimed',
    Confirmed = 'Confirmed',
    Cancelled = 'Cancelled',
    CheckedOut = 'CheckedOut',
    CheckedIn = 'CheckedIn',
    Missed = 'Missed',
}

export enum ProvisionType {
    General = 'General',
    Admin = 'Admin',
    LiveSite = 'Live Site',
    Hierarchy = 'Hierarchy',
}

export enum EquipmentState {
    CheckIn = 'checkin',
    CheckOut = 'checkout',
}

export enum ActivityObject {
    PersonnelType = 'personnelId',
    StateType = 'stateType',
    PerformedAt = 'actionPerformedAt',
    FacilityType = 'facilityId',
    SeatType = 'seatId',
    ReservationType = 'reservationId',
    ReservationStartTime = 'reservationStartTime',
    ReservationEndTime = 'reservationEndTime',
    ReservationCheckInType = 'reservationCheckInActivityId',
    ReservationCheckOutType = 'reservationCheckOutActivityId',
    ReservationCheckedInTime = 'reservationCheckedInTime',
    ReservationCheckedOutTime = 'reservationCheckedOutTime',
    ReservationCheckedOutBy = 'reservationCheckedOutBy',
    ReservationCheckedInBy = 'reservationCheckedInBy',
}

export enum FacilitiesManageCategory {
    Equipment = 'equipment',
    Seats = 'seats',
    Audit = 'audit',
    LogBook = 'logbook',
    BlockedUsers = 'blockedusers',
    Metrics = 'metrics',
}

export enum FacilityTimeslotStatus {
    Free = 'Free',
    Taken = 'Taken',
    Yours = 'Yours',
}

const timeslotEndingAlertWindowInMinutes = 25;

export function isTimeslotEnding(timeslotItem: IFacilityTimeslotItem, date?: Date): boolean {
    date = date ?? new Date();
    return (
        date.getTime() + minutesToMilliseconds(timeslotEndingAlertWindowInMinutes) >=
        timeslotItem.endDateTimeUTCMilliseconds
    );
}

export function getProvisionTypeColor(provisionType: string): BadgeColorHex {
    switch (provisionType) {
        case ProvisionType.General:
            return BadgeColorHex.GREEN;
        case ProvisionType.Admin:
            return BadgeColorHex.RED;
        case ProvisionType.Hierarchy:
            return BadgeColorHex.BLUE;
        case ProvisionType.LiveSite:
            return BadgeColorHex.YELLOW;
        default:
            return BadgeColorHex.GRAY;
    }
}

export function getAvailableSeatStatus(seat?: ISeatRecord): string {
    return seat?.provisionInfo.provisionType === ProvisionType.LiveSite
        ? SeatStatuses.AvailableForICM.value
        : SeatStatuses.Available.value;
}

export function generateReservationId(
    facilityId: string,
    seatId: string,
    startTimeSlotUtcMilliseconds: number,
): string {
    return `${facilityId}.${seatId}.${startTimeSlotUtcMilliseconds}`;
}

export function generateLogBookRecordId(personnelId: string, facilityId: string): string {
    return `${personnelId}.${facilityId}`;
}

export function generateBlockedFacilityUserRecordId(
    personnelId: string,
    facilityId: string,
): string {
    return `${personnelId}.${facilityId}`;
}

export function isEmployeeInSeatHierarchies(seatRecord: ISeatRecord, employee: IEmployee): boolean {
    if (seatRecord.provisionInfo.provisionType !== ProvisionType.Hierarchy) {
        return true;
    }

    const hierarchyIdSet = new Set(
        (seatRecord.provisionInfo as IHierarchyProvisionInfo).hierarchyIds,
    );

    for (let i = employee.employeeLvl; i > 0; i--) {
        const personnelId =
            i !== employee.employeeLvl
                ? EmployeeClient.getHierarchyLevel(employee, i).toString()
                : employee.id;
        if (hierarchyIdSet.has(personnelId)) {
            return true;
        }
    }

    return false;
}

export function isSeatAvailable(seat?: ISeatRecord, seatStatuses?: ISeatSlot[]): boolean {
    if (!seat || !seatStatuses) {
        return false;
    }

    const seatStatus = seatStatuses.find((x) => x.seatId === seat.id);
    return (
        !!seatStatus &&
        AvailableSeatStatusArray.find((x) => x.value === seatStatus.status) !== undefined
    );
}

export function isMySeat(seat?: ISeatRecord, seatStatuses?: ISeatSlot[]): boolean {
    if (!seat || !seatStatuses) {
        return false;
    }

    const seatStatus = seatStatuses.find((x) => x.seatId === seat.id);
    return !!seatStatus && seatStatus.status === SeatStatuses.MySeat.value;
}

export function getMySeatStatus(seatStatuses?: ISeatSlot[]): ISeatSlot | undefined {
    if (!seatStatuses) {
        return undefined;
    }

    return seatStatuses.find((x) => x.status === SeatStatuses.MySeat.value);
}

export function hasReservationPassed(
    reservationRecord: IReservationRecord | undefined,
    utcMilliseconds?: number | undefined,
): boolean {
    const currentDateTimeUtc = utcMilliseconds ?? new Date().getTime();

    return (
        !!reservationRecord &&
        reservationRecord?.reservationTimeSlot.endDateTimeUTCMilliseconds < currentDateTimeUtc
    );
}

export function updateSeatsTimeslotStatusesForDates(
    obj1: ISeatsTimeslotStatusesForDate[],
    obj2: ISeatsTimeslotStatusesForDate[],
): void {
    for (const currentObj of obj2) {
        const existingObj = obj1.find(
            (obj) =>
                obj.dateTimeslots.dateUtcMilliseconds ===
                currentObj.dateTimeslots.dateUtcMilliseconds,
        );

        if (existingObj) {
            const seatIds = Object.keys(currentObj.seatTimeslotsStatusesForDateDict);
            for (const seatId of seatIds) {
                existingObj.seatTimeslotsStatusesForDateDict[seatId] =
                    currentObj.seatTimeslotsStatusesForDateDict[seatId];
            }
        } else {
            obj1.push(currentObj);
        }
    }
}

export function areTimeSlotItemsEqual(
    timeslotItem1: IFacilityTimeslotItem,
    timeslotItem2: IFacilityTimeslotItem,
): boolean {
    // Avoid comparing endDateTimeUTCMilliseconds as sometimes we end up with values like 16:59:59 rather than 17:00:00.
    // Once the back-end has this issue fixed we can start using it for comparisons.
    return (
        timeslotItem1.startDateTimeUTCMilliseconds === timeslotItem2.startDateTimeUTCMilliseconds
    );
}

export function isFacilityKioskClientPath(): boolean {
    return facilityKioskClientPaths.some((x) => window.location.pathname.includes(x));
}

export function isTokenExpired(expires: string): boolean {
    const currentTime = getDate().Now();
    return (
        isoDateStringToUtcMilliseconds(localDateToUTCDate(currentTime).toString()) +
            minutesToMilliseconds(1) >
        isoDateStringToUtcMilliseconds(expires)
    );
}

export interface ISeatAvailabilityCalendarResponse {
    seatsTimeslotStatusesForDates: ISeatsTimeslotStatusesForDate[];
    continuationToken: string;
}

export interface ISeatsTimeslotStatusesForDate {
    dateTimeslots: IFacilityTimeslots;
    seatTimeslotsStatusesForDateDict: Dictionary<ITimeslotSeatStatus[]>;
}

export interface ITimeslotSeatStatus {
    timeslotItem: IFacilityTimeslotItem;
    seatStatus: ISeatStatus;
}

export interface IFacilityPreClaimException {
    correlationId: string;
    message: string;
}

export interface IReservationPreClaimRequest {
    facilityId: string;
    seatId: string;
    timeslot: number;
}

export interface IReservationConfirmRequest {
    facilityId: string;
    claimCode: string;
    icmDescription?: string;
}

export interface IReservationsConfirmRequest {
    claimCode: string;
    icmDescription?: string;
}

export interface ISeatAvailabilityResponse {
    facilityId: string;
    seats: ISeatStatus[];
    availableTimeSlot?: IFacilityTimeslotItem;
    continuationToken: string;
}

export interface ISeatStatus {
    seatId: string;
    status: string;
    reservationState?: string;
}

export interface IFacilityUserFeedbackRequest {
    rating: number;
}

export interface IFacilityUserFeedbackStats {
    total: number;
    average: number;
}

export interface IReservationRecord {
    id: string;
    facilityId: string;
    seatId: string;
    reserverId?: string;
    personnelId: string;
    icmDescription?: string;
    state: string;
    claimCode: IReservationClaim;
    reservationTimeSlot: IFacilityTimeslotItem;
    nextTimeslotDetails?: IFacilityTimeslotDetails;
    createdTimestamp: ITimestampChange;
    lastModified: ITimestampChange;
    lastOperation: string;
    ttl?: number;
}

export interface IFacilityTimeslotDetails {
    timeslotItem: IFacilityTimeslotItem;
    status: string;
}

export interface ICalendarReservationRecord extends IReservationRecord {
    isIcm?: boolean;
}

export interface IReservationSeatChangeRequest {
    facilityId: string;
    oldSeatId: string;
    newSeatId: string;
    timeslot: number;
    icmDescription?: string;
}

export interface IReservationSeatChangeResponse {
    oldReservationRecord: IReservationRecord;
    newReservationRecord: IReservationRecord;
}

export interface IReservationClaim {
    code: string;
    expiresDateTimeUTCMilliseconds: number;
    expiresDateTimeUTCMiliaryTime?: string;
}

export interface IFacilityTimeslotsResponse {
    facilityId: string;
    timeslots: IFacilityTimeslots[];
}

export interface IFacilityTimeslots {
    date: string;
    dateUtcMilliseconds: number;
    timeslotItems: IFacilityTimeslotItem[];
}

export interface IFacilityTimeslotItem {
    startDateTimeUTCMilliseconds: number;
    endDateTimeUTCMilliseconds: number;
}

export interface IAzureIndoorMapDataInfo {
    uploadUDID?: string;
    convertId?: string;
    datasetId?: string;
    tilesetInfo?: ITilesetInfo;
}

export interface ITilesetInfo {
    id?: string;
    boundingBox?: number[];
}

export interface IFacilityRecord {
    id: string;
    facilityName: string;
    facilityType: string;
    facilityRegion?: Region;
    facilityId: string;
    recordType: string;
    createdTimestamp: ITimestampChange;
    lastModified: ITimestampChange;
    lastOperation: string;
    ttl?: number;
    facilityStatus: string;
    requiredEligibilities: string[];
    cssoPointsOfContact: string[];
    issoPointsOfContact: string[];
    itPointsOfContact: string[];
    azureIndoorMapDataInfo?: IAzureIndoorMapDataInfo;
    svgMap?: string;
    facilityReservationInfo: IFacilityReservationInfo;
    timeZone: string;
    isEquipmentEnabled: boolean;
    facilitySupportPointOfContact: string;
}

export interface IFacilityReservationInfo {
    timeSlotDurationMinutes: number;
    timeSlotStartHours: number;
    timeSlotEndHours: number;
}

export interface ICreateEquipmentRequest {
    facilityId: string;
    ownerPersonnelId?: string;
    name: string;
    type: string;
}

export interface IUpdateEquipmentRequest {
    equipmentId: string;
    ownerPersonnelId?: string;
    name?: string;
    type?: string;
    isCheckedOut?: boolean;
}

export interface IEquipmentCheckinCheckout {
    checkedOut: boolean;
    lastCheckinCheckout: ITimestampChange;
}

export type EquipmentRecordResponse = {
    results: IEquipmentRecord[];
    continuationToken: string;
};

export type Status = keyof typeof Status;
export type EquipmentType = keyof typeof EquipmentTypes;
export type Region = keyof typeof Regions;

export type TimestampChange = {
    by: string;
    atUtc: number;
};

export type IEquipmentRecord = {
    id: string;
    ownerPersonnelId?: string;
    facilityId: string;
    clearanceId?: string;
    contractId?: string;
    region: Region;
    type: EquipmentType;
    make?: string;
    model?: string;
    assetNumber?: string;
    status: Status;
    checkinCheckoutInfo?: IEquipmentCheckinCheckout;
    metaData: Record<string, string>;
    issued: TimestampChange;
    expirationOnUtcMilliseconds: number;
    returnedOnUtcMilliseconds: number;
    reportedOnUtcMilliseconds: number;
    createdTimestamp: TimestampChange;
    lastModified: TimestampChange;
    lastOperation: string;
    ttl?: number;
};

export type UploadEquipmentRecord = {
    id?: string;
    ownerPersonnelId: string;
    facilityId: string;
    clearanceId?: string;
    contractId?: string;
    region: Region;
    type: EquipmentType;
    make?: string;
    model?: string;
    assetNumber: string;
    status?: Status;
    checkinCheckoutInfo?: IEquipmentCheckinCheckout;
    issuedBy: string;
    issuedAt: number;
    expirationOnUtcMilliseconds?: number;
    returnedOnUtcMilliseconds?: number;
    reportedOnUtcMilliseconds?: number;
    uploadStatus?: EquipmentUploadStatusEnum;
};

export enum UploadEquipmentColumns {
    facilityId = 'Facility id', // always required for create
    ownerPersonnelId = 'Personnel id', // always required for create
    region = 'Region', // always required for create
    type = 'Equipment type', // always required for create
    make = 'Make',
    model = 'Model',
    assetNumber = 'Asset or card number', // always required for create
    status = 'Equipment status',
    issuedAt = 'Issued on',
    expirationOnUtcMilliseconds = 'Expiration',
    returnedOnUtcMilliseconds = 'Returned on',
    reportedOnUtcMilliseconds = 'Reported on',
    issuedBy = 'Issued by personnel id', // always required for create
    clearanceId = 'Clearance record id',
    contractId = 'Contract',
    id = 'Equipment id',
    uploadStatus = 'Upload status',
}

// This type can be replaced with type BulkUploadResult from upload-utils.
export type UploadEquipmentRecordResult = {
    // This record has many more fields, but at this moment (Jun,15,2023)
    // the code only needs to access "Upload status".
    [UploadEquipmentColumns.uploadStatus]: string;
};

export type EquipmentUploadStatusEnum = 'Success' | 'Failed' | 'Skipped';

export interface ICheckInOutEquipmentListRequest {
    ids: string[];
    facilityId: string;
}

export interface IIgnoredJustification {
    id: string;
    justification: string;
}

export interface ICheckInOutEquipmentListResponse {
    processed: IEquipmentRecord[];
    ignored: IIgnoredJustification[];
}

export interface ICreateSeatRequest {
    seatName: string;
    isOutOfOrder: boolean;
    facilityId: string;
    provisionInfo: IProvisionInfo;
    unitId?: string;
    occupiedBy?: string;
    qualifiers: string[];
}

export interface IUpdateSeatRequest {
    seatName?: string;
    isOutOfOrder?: boolean;
    provisionInfo?: IProvisionInfo;
    unitId?: string;
    occupiedBy?: string;
    qualifiers?: string[];
}

export interface IUpdateSeatRequestWithId extends IUpdateSeatRequest {
    seatId: string;
}

export interface ISeatRecord {
    id: string;
    facilityId: string;
    recordType: string;
    seatName: string;
    isOutOfOrder: boolean;
    provisionInfo: IProvisionInfo;
    unitId?: string;
    occupiedBy?: ITimestampChange;
    createdTimestamp: ITimestampChange;
    lastModified: ITimestampChange;
    lastOperation: string;
    ttl?: number;
    qualifiers: string[];
}

export interface ILogBookRecord {
    id: string;
    personnelId: string;
    facilityId: string;
    createdTimestamp: ITimestampChange;
    lastModified: ITimestampChange;
    lastOperation: string;
    ttl?: number;
}

export interface IBlockedFacilityUserRecord {
    id: string;
    personnelId: string;
    facilityId: string;
    createdTimestamp: ITimestampChange;
    lastModified: ITimestampChange;
    lastOperation: string;
    ttl?: number;
}

export interface IProvisionInfo {
    provisionType: string;
}

export type IGeneralProvisionInfo = IProvisionInfo;

export interface IHierarchyProvisionInfo extends IProvisionInfo {
    hierarchyIds: string[];
}

export type ILiveSiteProvisionInfo = IProvisionInfo;

export type IAdminProvisionInfo = IProvisionInfo;

export interface IFacilitiesTokenResult {
    token: string;
    expires: string;
}

export interface IFacilitiesToken {
    alias: string;
    aud: string;
    facilities: string | string[] | undefined;
    azureMapClientId: string;
    exp: number;
    iss: string;
    nbf: number;
    oid: string;
    personnelId: string;
    tenantId: string;
    userRoles: string | string[] | undefined;
    managerOf: string | string[] | undefined;
    equipmentOfficerOf: string | string[] | undefined;
}
