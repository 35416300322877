import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles, Stack } from '@fluentui/react';
import React, { ReactNode } from 'react';

/**
 *  Recommended pattern:
        <MetricTiles ariaLabel='Metric tiles'>
            <MetricTilesContainer>
                <MetricTile count={12} label='Apple' />
                <MetricTile count={8} label='Grape Fruit' />
            </MetricTilesContainer>
            <MetricTilesLegend
                title={'Legend'}
                legend={[
                    {
                        label: 'Apple',
                        color: 'red',
                    },
                    {
                        label: 'Grape Fruit',
                        color: 'yellow',
                    },
                ]}
            >
            </MetricTilesLegend>
        </MetricTiles>
 */

interface MetricTilesProps {
    children: ReactNode;
    ariaLabel: string;
}

export default function MetricTiles(props: MetricTilesProps): JSX.Element {
    return (
        <Stack
            horizontal
            horizontalAlign='space-between'
            className={styles}
            aria-label={props.ariaLabel}>
            {props.children}
        </Stack>
    );
}

const styles = mergeStyles(
    {
        padding: 15,
    },
    globalStyles.boxShadow,
);
