import { IconNames } from 'assets/constants/global-constants';
import Spacer from 'components/common/spacer';
import { ActionButton, Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import { ReadinessFilterContext } from 'components/readiness/top/readiness-filter-context';

export default function ReadinessMyOrgFilter(): JSX.Element {
    const filterContext = useContext(ReadinessFilterContext);
    const {
        employeeFilterElement,
        reportsToFilterElement,
        scopeStatusFilterElement,
        scopeFilterElement,
        clearFilters,
    } = filterContext;

    return (
        <div>
            {employeeFilterElement()}
            {reportsToFilterElement()}
            <Spacer marginTop={10} />
            {scopeStatusFilterElement()}
            <Spacer marginTop={10} />
            {scopeFilterElement()}
            <Spacer marginTop={10} />
            <Stack horizontal horizontalAlign='end'>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={clearFilters}>
                    <span>Clear filters</span>
                </ActionButton>
            </Stack>
        </div>
    );
}
