import { Stack } from '@fluentui/react';
import React, { ReactNode } from 'react';

/**
 * See the recommended pattern in metric-tiles.tsx.
 */

interface MetricTilesContainerProps {
    children?: ReactNode; // Is supposed to be instances of <MetricTile>
}

export default function MetricTilesContainer(props: MetricTilesContainerProps): JSX.Element {
    return (
        <Stack
            horizontal
            verticalAlign='center'
            horizontalAlign='start'
            tokens={{ childrenGap: 10 }}>
            {props.children}
        </Stack>
    );
}
