import {
    DatePicker,
    ActionButton,
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    IStackItemStyles,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
} from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ISuitabilityRecord } from 'clients/suitability-client';
import {
    BasicDetailsModal,
    ButtonParams,
    EditParams,
    SaveParams,
} from 'components/personnel-profile/common/basic-details-modal';
import {
    agencyDropdownOptions,
    suitabilityDropdownOptions,
    suitabilityStatusSpan,
    suitabilityStatusDropdownOptions,
    suitabilityTypeDropdownOptions,
    continuousEvaluationTypeDropdownOptions,
    getAgencyEnumValueFromKey,
} from 'components/personnel-profile/suitability/profile-suitability-utils';
import {
    ContinuousEvaluationTypes,
    labelStackItem,
} from 'components/personnel-profile/common/common-types';
import { dateToLocalDate, getDate } from 'utils/time-utils';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { toTitleCase } from 'utils/string-utils';
import ContractPicker from 'components/common/contract-picker';
import { transformContractToPersona } from 'utils/internal-persona-utils';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { displayRequiredFieldsErrors } from 'utils/error-display-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { Dictionary } from 'assets/constants/global-constants';
import { ContractStatus, IContract } from 'components/screening/us-gov/IContract';
import { nameof } from 'utils/object-utils';
import {
    SuitabilityStatuses,
    SuitabilityLevels,
    SuitabilityAgencies,
} from 'components/personnel-profile/suitability/profile-suitability-types';

// 'contractId' and its required indicator removed because of card - https://msazure.visualstudio.com/Microsoft%20Personnel/_boards/board/t/Screening/Stories/?workitem=10073448
const validFields: string[] = [
    'suitabilityLevel',
    'status',
    'requestingAgency',
    'personnelId',
    'contractId',
];
const statusEditFieldName = 'status';

enum Mode {
    Edit,
    View,
}

export interface SuitabilityDetailsProps {
    suitability?: ISuitabilityRecord;
    employee?: IEmployeeWithEditableData;
    buttonText: string;
    buttonIcon: string;
    modalTitle: string;
    upsertRecordFunction: (record: ISuitabilityRecord) => Promise<boolean>;
    deleteRecordFunction?: (id: string) => Promise<boolean>;
    contracts: IContract[];
}

export default function SuitabilityDetails(props: SuitabilityDetailsProps): JSX.Element {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentMode, setMode] = useState<Mode>(Mode.View);
    const [basicDetailsModalEditButton, setBasicDetailsModalEditButton] = useState<ButtonParams>(
        props.suitability ? EditParams : SaveParams,
    );
    const [basicDetailsModalCloseButtonTitle, setBasicDetailsModalCloseButtonTitle] = useState<
        'Close' | 'Cancel'
    >('Close');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [errorMsg, setErrorMsg] = useState<JSX.Element>();

    const [saveObject, setSaveObject] = useState<ISuitabilityRecord | undefined>();

    const [isDeleteDialogAutoClosed, setDeleteDialogAutoClosed] = useState<boolean>(false);

    const valueStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            flex: 1,
        },
    };

    useEffect(() => {
        if (props.suitability) {
            setMode(Mode.View);
        } else {
            setMode(Mode.Edit);
        }
    }, [props.suitability]);

    function onEditButtonPress(): void {
        if (currentMode === Mode.View) {
            setMode(Mode.Edit);
        } else {
            handleStatusAdjustOnDebrief(saveObject);
            if (saveObject && validateSuitability(saveObject)) {
                setIsDisabled(true);
                const newSaveObject = structuredClone(saveObject);
                newSaveObject.requestingAgency = getAgencyEnumValueFromKey(
                    newSaveObject.requestingAgency,
                ) as SuitabilityAgencies;
                props.upsertRecordFunction(newSaveObject).then((result: boolean) => {
                    setIsDisabled(false);
                    if (result) {
                        setModalOpen(false);
                    } else {
                        setErrorMsg(<span>There was an error saving.</span>);
                    }
                });
            }
        }
    }

    function handleStatusAdjustOnDebrief(suitabilityRecord: ISuitabilityRecord | undefined): void {
        if (
            suitabilityRecord &&
            suitabilityRecord.debriefDateUTCMilliseconds &&
            suitabilityRecord.debriefDateUTCMilliseconds > 0
        ) {
            suitabilityRecord.status = SuitabilityStatuses.Inactive;
        }
    }

    function validateSuitability(suitabilityRecord: ISuitabilityRecord | undefined): boolean {
        if (suitabilityRecord) {
            const foundValid: string[] = [];
            for (const [key, value] of Object.entries(suitabilityRecord)) {
                const foundField = validFields.find((x) => x === key);
                if (foundField && value) {
                    foundValid.push(foundField);
                }
            }

            let isDebriefValid = true;
            if (
                suitabilityRecord.debriefDateUTCMilliseconds &&
                suitabilityRecord.debriefDateUTCMilliseconds > 0
            ) {
                isDebriefValid = suitabilityRecord.status === SuitabilityStatuses.Inactive;
            }

            const isCEValid = { status: true, field: '' };
            if (
                (suitabilityRecord.continuousEvaluationType ===
                    ContinuousEvaluationTypes.Deferred ||
                    suitabilityRecord.continuousEvaluationType ===
                        ContinuousEvaluationTypes.Other) &&
                !suitabilityRecord.continuousEvaluationEnrollmentDateUTCMilliseconds
            ) {
                isCEValid.status = false;
                isCEValid.field = 'CE enrollment date';
            }

            if (
                suitabilityRecord.continuousEvaluationType ===
                    ContinuousEvaluationTypes.Unenrolled &&
                !suitabilityRecord.continuousEvaluationUnenrollmentDateUTCMilliseconds
            ) {
                isCEValid.status = false;
                isCEValid.field = 'CE unenrollment date';
            }

            if (foundValid.length === validFields.length && isDebriefValid && isCEValid.status) {
                return true;
            }

            const invalidFields = validFields
                .filter((x) => !foundValid.some((y) => y === x))
                .map((x) => {
                    if (x === 'contractId') {
                        return 'Contract';
                    } else if (x === 'suitabilityLevel') {
                        return 'Suitability Level';
                    } else if (x === 'requestingAgency') {
                        return 'Agency';
                    }
                    return toTitleCase(x);
                });

            if (!isDebriefValid) {
                invalidFields.push('Debrief On');
            }

            if (!isCEValid.status) {
                invalidFields.push(isCEValid.field);
            }

            setErrorMsg(displayRequiredFieldsErrors(invalidFields));
        }

        return false;
    }

    function onDeleteButtonPress(): void {
        if (props.deleteRecordFunction && saveObject && saveObject.id) {
            setIsDisabled(true);
            props.deleteRecordFunction(saveObject.id).then((result: boolean) => {
                setIsDisabled(false);
                if (result) {
                    setModalOpen(false);
                } else {
                    setErrorMsg(<span>There was an error deleting.</span>);
                }

                setDeleteDialogAutoClosed(!isDeleteDialogAutoClosed);
            });
        }
    }

    function onCloseButtonPress(): void {
        if (currentMode === Mode.View || props.suitability === undefined) {
            setModalOpen(false);
        } else {
            setMode(Mode.View);
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            setErrorMsg(undefined);
            setSaveObject(
                props.suitability
                    ? { ...props.suitability }
                    : ({ personnelId: props.employee?.data?.id } as ISuitabilityRecord),
            );
            if (currentMode === Mode.Edit) {
                setBasicDetailsModalEditButton(SaveParams);
                setBasicDetailsModalCloseButtonTitle('Cancel');
            } else {
                setBasicDetailsModalEditButton(EditParams);
                setBasicDetailsModalCloseButtonTitle('Close');
            }
        }
    }, [isModalOpen, currentMode]);

    function canEdit(): boolean {
        return currentMode === Mode.Edit;
    }

    function canDelete(): boolean {
        return props.deleteRecordFunction !== undefined && currentMode === Mode.View;
    }

    function changeValue(property: string, value: string | number | boolean | undefined): void {
        // Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        if (property.includes('.')) {
            const [mainProperty, subProperty] = property.split('.');
            newSaveObject[mainProperty] = newSaveObject[mainProperty]
                ? { ...newSaveObject[mainProperty], [subProperty]: value }
                : { [subProperty]: value };
        } else {
            newSaveObject[`${property}`] = value;
        }

        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    function changeValues(propertyDict: Dictionary<string | number | boolean | undefined>): void {
        // Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        Object.keys(propertyDict).forEach((x) => {
            newSaveObject[`${x}`] = propertyDict[x];
        });
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    const activeUsGovContracts = useMemo(
        () =>
            props.contracts.filter(
                (c) =>
                    c.contractType === 'PublicTrust' && c.contractStatus === ContractStatus.Active,
            ),
        [props.contracts],
    );

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: props.buttonIcon }}
                text={props.buttonText}
                styles={{ root: { maxHeight: contentMaxHeight } }}
            />

            <BasicDetailsModal
                title={props.modalTitle}
                editButton={basicDetailsModalEditButton}
                closeTitle={basicDetailsModalCloseButtonTitle}
                isOpen={isModalOpen}
                onEditClick={onEditButtonPress}
                onCloseClick={onCloseButtonPress}
                onDeleteClick={canDelete() ? onDeleteButtonPress : undefined}
                deleteDialogProps={{
                    title: 'Delete Confirmation',
                    subText: 'Are you sure you want to permanently delete this suitability record?',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    autoClosed: isDeleteDialogAutoClosed,
                }}
                actionButtonDisabled={isDisabled}>
                <Stack>
                    <ActionButton
                        // The purpose of this button is to catch the initial propagated onClick event
                        // when the 'add suitability' button is clicked to open up this modal.
                        // Otherwise the first stack item's onChange event will trigger e.g. in this case
                        // the dropdown will select and display the top item from the dropdown selection
                        style={{ maxHeight: 0, maxWidth: 0, display: 'none' }}
                        hidden={true}
                        text='Stop initial propagation'
                        onClick={(event): void => {
                            event.stopPropagation();
                        }}
                    />
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Suitability level</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {
                                            SuitabilityLevels[
                                                props.suitability?.suitabilityLevel.replace(
                                                    /\s/g,
                                                    '',
                                                ) as keyof typeof SuitabilityLevels
                                            ]
                                        }
                                    </>
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('suitabilityLevel', option?.key);
                                        }}
                                        placeholder='Select suitability level'
                                        options={suitabilityDropdownOptions(
                                            props.suitability?.suitabilityLevel,
                                        )}
                                        ariaLabel='Suitability Level'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Agency</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {props.suitability?.requestingAgency
                                            ? getAgencyEnumValueFromKey(
                                                  props.suitability?.requestingAgency,
                                                  true,
                                              )
                                            : ''}
                                    </>
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('requestingAgency', option?.key);
                                        }}
                                        placeholder='Select agency'
                                        options={agencyDropdownOptions(
                                            props.suitability?.requestingAgency,
                                        )}
                                        ariaLabel='Agency'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Status</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() &&
                                    suitabilityStatusSpan(
                                        props.suitability,
                                        props.suitability?.status.length,
                                    )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue(statusEditFieldName, option?.key);
                                        }}
                                        placeholder='Select status'
                                        options={suitabilityStatusDropdownOptions(
                                            props.suitability?.status,
                                        )}
                                        ariaLabel='Status'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Suitability type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.suitability?.suitabilityType}
                                    />
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('suitabilityType', option?.key);
                                        }}
                                        placeholder='Select suitability type'
                                        options={suitabilityTypeDropdownOptions(
                                            props.suitability?.suitabilityType,
                                        )}
                                        ariaLabel='Suitability Type'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Suitability basis</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.suitability?.suitabilityBasis}
                                    />
                                )}
                                {canEdit() && (
                                    <TextField
                                        ariaLabel='Suitability Basis'
                                        defaultValue={props.suitability?.suitabilityBasis}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('suitabilityBasis', newValue);
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Investigated on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.investigationDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        placeholder='Select investigated date'
                                        allowTextInput={true}
                                        value={
                                            saveObject &&
                                            saveObject.investigationDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.investigationDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'investigationDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                        ariaLabel='Investigated On'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Granted on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.grantDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Granted Date'
                                        placeholder='Select granted date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.grantDateUTCMilliseconds
                                                ? new Date(saveObject.grantDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'grantDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Briefed on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.briefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Briefed Date'
                                        placeholder='Select briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.briefDateUTCMilliseconds
                                                ? new Date(saveObject.briefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'briefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Next briefed on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.nextBriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Next Briefed Date'
                                        placeholder='Select next briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.nextBriefDateUTCMilliseconds
                                                ? new Date(saveObject.nextBriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'nextBriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>NDA signed on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.ndaSignedDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='NDA Signed Date'
                                        placeholder='Select NDA signed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.ndaSignedDateUTCMilliseconds
                                                ? new Date(saveObject.ndaSignedDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'ndaSignedDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Debriefed on</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability?.debriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Debrief Date'
                                        placeholder='Select debrief Date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.debriefDateUTCMilliseconds
                                                ? new Date(saveObject.debriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'debriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Debrief justification</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <p
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            maxHeight: 260,
                                            overflowY: 'auto',
                                        }}>
                                        {props.suitability?.debriefJustification}
                                    </p>
                                )}
                                {canEdit() && (
                                    <TextField
                                        multiline
                                        rows={4}
                                        defaultValue={props.suitability?.debriefJustification}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('debriefJustification', newValue);
                                        }}
                                        ariaLabel='Debrief Justification'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Contract</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{props.suitability?.contractId}</>}
                                {canEdit() && (
                                    <>
                                        <ContractPicker
                                            placeHolder='Select valid and active contract'
                                            contracts={activeUsGovContracts}
                                            selectedPersona={transformContractToPersona(
                                                props.contracts.find((x) => {
                                                    return props.suitability?.contractId === x.id;
                                                }),
                                            )}
                                            onContractSelected={(persona?: IPersonaProps): void => {
                                                if (persona && persona.itemProp) {
                                                    changeValue(
                                                        'contractId',
                                                        JSON.parse(persona.itemProp).id,
                                                    );
                                                } else {
                                                    changeValue('contractId', undefined);
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Continuous Evaluation type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.suitability?.continuousEvaluationType}
                                    />
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValues({
                                                'continuousEvaluationType': option?.key,
                                                'continuousEvaluationEnrollmentDateUTCMilliseconds':
                                                    option?.key ===
                                                        nameof<typeof ContinuousEvaluationTypes>(
                                                            'Deferred',
                                                        ) ||
                                                    option?.key ===
                                                        nameof<typeof ContinuousEvaluationTypes>(
                                                            'Other',
                                                        )
                                                        ? getDate().Today().getTime()
                                                        : undefined,
                                                'continuousEvaluationUnenrollmentDateUTCMilliseconds':
                                                    option?.key ===
                                                    nameof<typeof ContinuousEvaluationTypes>(
                                                        'Unenrolled',
                                                    )
                                                        ? getDate().Today().getTime()
                                                        : undefined,
                                            });
                                        }}
                                        placeholder='Select CE type'
                                        options={continuousEvaluationTypeDropdownOptions(
                                            props.suitability?.continuousEvaluationType,
                                        )}
                                        ariaLabel='Continuous Evaluation Type'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label
                                    required={
                                        canEdit() &&
                                        (saveObject?.continuousEvaluationType ===
                                            nameof<typeof ContinuousEvaluationTypes>('Deferred') ||
                                            saveObject?.continuousEvaluationType ===
                                                nameof<typeof ContinuousEvaluationTypes>('Other'))
                                    }>
                                    CE enrollment date
                                </Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability
                                                ?.continuousEvaluationEnrollmentDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='CE Enrollment Date'
                                        placeholder='Select CE enrollment date'
                                        allowTextInput={true}
                                        disabled={
                                            saveObject?.continuousEvaluationType !==
                                                nameof<typeof ContinuousEvaluationTypes>(
                                                    'Deferred',
                                                ) &&
                                            saveObject?.continuousEvaluationType !==
                                                nameof<typeof ContinuousEvaluationTypes>('Other')
                                        }
                                        value={
                                            saveObject &&
                                            saveObject.continuousEvaluationEnrollmentDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.continuousEvaluationEnrollmentDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'continuousEvaluationEnrollmentDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label
                                    required={
                                        canEdit() &&
                                        saveObject?.continuousEvaluationType ===
                                            nameof<typeof ContinuousEvaluationTypes>('Unenrolled')
                                    }>
                                    CE unenrollment date
                                </Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.suitability
                                                ?.continuousEvaluationUnenrollmentDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='CE Unenrollment Date'
                                        placeholder='Select CE unenrollment date'
                                        allowTextInput={true}
                                        disabled={
                                            saveObject?.continuousEvaluationType !==
                                            nameof<typeof ContinuousEvaluationTypes>('Unenrolled')
                                        }
                                        value={
                                            saveObject &&
                                            saveObject.continuousEvaluationUnenrollmentDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.continuousEvaluationUnenrollmentDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'continuousEvaluationUnenrollmentDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {errorMsg && (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </BasicDetailsModal>
        </Stack>
    );
}
