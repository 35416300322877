import React, { useContext, useState } from 'react';
import { Role } from 'configs/roles';
// eslint-disable-next-line no-restricted-imports -- This common file has been updated to handle UserContext not always being present
import { IUserContext, UserContext } from 'contexts/user-context';
import { Menu, IMenuItem } from 'components/common/page-shell/menu';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { AuthContext } from 'contexts/auth-context';
import { FacilityUserType, FacilitiesMenuNavigationText } from 'utils/facilities-utils';
import { ScreeningPageNames } from 'components/common/constants';
import { manageTitle } from 'assets/constants/global-constants';
// eslint-disable-next-line no-restricted-imports -- Not used when user context is undefined
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import {
    FeatureFlagKeys,
    FeatureFlagResponse,
    LoadingState,
    useFeatureFlag,
} from 'utils/use-feature-flags';
// eslint-disable-next-line no-restricted-imports -- useCanShowContractAdminPage degrades gracefully when UserContext is not present
import { useCanShowContractAdminPage } from 'components/screening/screening-utils';
import { GroupsUserContext, IGroupsUserContext } from 'contexts/user-contexts/groups-user-context';
// eslint-disable-next-line no-restricted-imports -- Download report menu entries are disabled in core
import ScreeningReportsModal from 'components/screening/common/screening-reports-modal';
import { IPrincipalUserContext, PrincipalUserContext } from 'contexts/principal-user-context';

type FeatureFlagSubset = Pick<
    typeof FeatureFlagKeys,
    | 'emailsCore'
    | 'formsCore'
    | 'profileVisitor'
    | 'profileUsGov'
    | 'screeningCore'
    | 'scaCore'
    | 'facilitiesCore'
    | 'readinessCore'
    | 'staffingCore'
    | 'groupsCore'
    | 'screeningContracts'
    | 'personnelCoreAttributes'
    | 'suitabilityRecords'
    | 'employeeCore'
    | 'cloudReadinessReportDownload'
    | 'supportLinkToAka'
>;
type MenuFlags = Record<keyof FeatureFlagSubset, FeatureFlagResponse>;

export function PersonnelNavigationMenu(): JSX.Element {
    const menuFlags: MenuFlags = {
        emailsCore: useFeatureFlag(FeatureFlagKeys.emailsCore),
        formsCore: useFeatureFlag(FeatureFlagKeys.formsCore),
        profileVisitor: useFeatureFlag(FeatureFlagKeys.profileVisitor),
        profileUsGov: useFeatureFlag(FeatureFlagKeys.profileUsGov),
        screeningCore: useFeatureFlag(FeatureFlagKeys.screeningCore),
        scaCore: useFeatureFlag(FeatureFlagKeys.scaCore),
        staffingCore: useFeatureFlag(FeatureFlagKeys.staffingCore),
        facilitiesCore: useFeatureFlag(FeatureFlagKeys.facilitiesCore),
        readinessCore: useFeatureFlag(FeatureFlagKeys.readinessCore),
        groupsCore: useFeatureFlag(FeatureFlagKeys.groupsCore),
        screeningContracts: useFeatureFlag(FeatureFlagKeys.screeningContracts),
        personnelCoreAttributes: useFeatureFlag(FeatureFlagKeys.personnelCoreAttributes),
        suitabilityRecords: useFeatureFlag(FeatureFlagKeys.suitabilityRecordsTab),
        employeeCore: useFeatureFlag(FeatureFlagKeys.employeeCore),
        cloudReadinessReportDownload: useFeatureFlag(FeatureFlagKeys.cloudReadinessReportDownload),
        supportLinkToAka: useFeatureFlag(FeatureFlagKeys.supportLinkToAka),
    };

    const userContext = useContext(UserContext) as IUserContext | null;
    const principalUserContext = useContext(PrincipalUserContext);
    const groupsUserContext = useContext(GroupsUserContext);
    const authContext = useContext(AuthContext);
    const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);

    const isLoading = Object.values(menuFlags).some(
        (flag) => flag.loadingState === LoadingState.loading,
    );

    const [shouldForceMenuRefresh, setForceMenuRefresh] = useState(false);
    const onMenuItemClicked = (): void => setForceMenuRefresh((prev) => !prev);

    const canShowContractAdminPage = useCanShowContractAdminPage(authContext);

    const openReportModal = (): void => setIsReportModalOpen(true);
    const closeReportModal = (): void => setIsReportModalOpen(false);

    const msalUser = authContext.getUserProfile();
    const menuItems = getMenuBasedOnUserPermissions(
        (msalUser && msalUser.roles) || [],
        userContext,
        principalUserContext,
        groupsUserContext,
        openReportModal,
        menuFlags,
        canShowContractAdminPage,
    );

    return (
        <nav
            aria-label='primary'
            style={{ marginRight: 'auto', paddingTop: '.2rem', minWidth: '0', flexGrow: '1' }}>
            {isLoading ? (
                <Menu menuItems={[]} />
            ) : (
                <Menu
                    key={shouldForceMenuRefresh.toString()}
                    onMenuItemClicked={onMenuItemClicked}
                    menuItems={menuItems}
                />
            )}
            {menuFlags.screeningCore.enabled && (
                <ScreeningReportsModal isOpen={isReportModalOpen} close={closeReportModal} />
            )}
        </nav>
    );
}

function isPermitted(userRoles: string[], requiredPermissions: Role[]): boolean {
    if (!Array.isArray(userRoles) || !userRoles.length) return false;
    if (userRoles.includes(Role.PortalAdmin)) return true;
    return requiredPermissions.some((item) => userRoles.includes(item));
}

function getMenuBasedOnUserPermissions(
    msalUserRoles: string[],
    userContext: IUserContext | null,
    principalUserContext: IPrincipalUserContext,
    groupsUserContext: IGroupsUserContext | undefined,
    openReportModal: () => void,
    menuFlags: MenuFlags,
    canShowContractAdminPage: boolean,
): IMenuItem[] {
    return [
        {
            title: 'Profile',
            visible: menuFlags.employeeCore.enabled,
            subMenu: [
                {
                    title: 'My info',
                    link: '/profile/user/me/info',
                    visible: true,
                },
                {
                    title: 'Assignments',
                    link: '/profile/user/search',
                    visible: isPermitted(msalUserRoles, [Role.PersonnelAssignmentRead]),
                },
                {
                    title: 'Visitors',
                    link: '/profile/visitor',
                    visible:
                        menuFlags.profileVisitor.enabled &&
                        isPermitted(msalUserRoles, [Role.VisitorRecordRead]),
                },
                {
                    // When the page is loading up, value of property "visible" depends
                    // on when the function userContext.hasUsGovScreeningUserType() below
                    // returns. That will take a second or two. At that time, if this item
                    // is in the middle of the list, order of subsequent items may change.
                    // As a result, fast users may click on an item in the list that they
                    // didn't intend to. Therefore, this item is placed after the rest so
                    // that fast users won't encounter that scenario.
                    title: 'US Gov',
                    link: '/profile/us-gov',
                    visible:
                        menuFlags.profileUsGov.enabled &&
                        !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST),
                },
                {
                    title: ScreeningPageNames.Manage,
                    link: '/profile/manage/attributes',
                    visible: isPermitted(msalUserRoles, [
                        Role.EligibilitiesRead,
                        Role.AttributesRead,
                    ]),
                },
            ],
        },
        {
            title: 'Attributes',
            visible: menuFlags.personnelCoreAttributes.enabled,
            subMenu: [
                {
                    title: 'My attributes',
                    link: `/profile/attributes/user/${principalUserContext.principalRecord.id}`,
                    visible: true,
                },
                {
                    title: 'User assignments',
                    link: '/profile/attributes/user/search',
                    visible: true,
                },
                {
                    title: 'Attribute sets',
                    link: '/profile/attributesets',
                    visible: true,
                },
            ],
        },
        ...(!userContext
            ? []
            : [
                  {
                      title: 'Screening',
                      visible: menuFlags.screeningCore.enabled,
                      subMenu: [
                          // TODO: make submenu options visible once US Citizenship and its functionalities have been implemented
                          {
                              title: 'US Citizenship',
                              visible: false,
                              subMenu: [
                                  {
                                      title: 'My status',
                                      link: '/screening/us-citizenship/my-status',
                                      visible: false,
                                  },
                                  {
                                      title: 'Requests',
                                      link: '/screening/us-citizenship/requests',
                                      visible: false,
                                  },
                                  {
                                      title: ScreeningPageNames.Manage,
                                      link: '/screening/us-citizenship/manage',
                                      visible: false,
                                  },
                              ],
                          },
                          {
                              title: 'Cloud',
                              visible: true,
                              subMenu: [
                                  {
                                      title: ScreeningPageNames.MyScreenings,
                                      link: '/screening/cloud/my-screenings',
                                      visible: true,
                                  },
                                  {
                                      title: 'My org',
                                      link: '/screening/cloud/my-org',
                                      visible:
                                          userContext.hasCloudScreeningUserType(
                                              CloudScreeningUserType.Manager,
                                          ) ||
                                          userContext.hasCloudScreeningUserType(
                                              CloudScreeningUserType.ManagerDelegate,
                                          ) ||
                                          userContext.hasCloudScreeningUserType(
                                              CloudScreeningUserType.Admin,
                                          ),
                                  },
                                  {
                                      title: ScreeningPageNames.Manage,
                                      link: '/screening/cloud/admin',
                                      visible: userContext.hasCloudScreeningUserType(
                                          CloudScreeningUserType.Admin,
                                      ),
                                  },
                              ],
                          },
                          {
                              title: 'US Gov',
                              visible: true,
                              subMenu: [
                                  {
                                      title: ScreeningPageNames.MyScreenings,
                                      link: '/screening/us-gov/my-screenings',
                                      visible: true,
                                  },
                                  {
                                      title: ScreeningPageNames.MyOrg,
                                      link: '/screening/us-gov/my-org',
                                      visible: true,
                                  },
                                  {
                                      title: ScreeningPageNames.MyContracts,
                                      link: '/screening/us-gov/my-contracts',
                                      visible: userContext.hasUsGovScreeningUserType(
                                          UsGovScreeningUserType.ContractOwner,
                                      ),
                                  },
                                  {
                                      title: ScreeningPageNames.MyNominees,
                                      link: '/screening/us-gov/my-nominees',
                                      visible: userContext.hasUsGovScreeningUserType(
                                          UsGovScreeningUserType.Nominator,
                                      ),
                                  },
                                  {
                                      title: ScreeningPageNames.ClearanceRecords,
                                      link: '/screening/us-gov/clearance-records',
                                      visible:
                                          userContext.hasUsGovScreeningUserType(
                                              UsGovScreeningUserType.NST,
                                          ) ||
                                          userContext.hasUsGovScreeningUserType(
                                              UsGovScreeningUserType.ContractOwner,
                                          ),
                                  },
                                  {
                                      title: ScreeningPageNames.Manage,
                                      link: '/screening/us-gov/manage',
                                      visible: userContext.hasUsGovScreeningUserType(
                                          UsGovScreeningUserType.NST,
                                      ),
                                  },
                              ],
                          },
                          {
                              title: 'Public Trust',
                              visible: true,
                              subMenu: [
                                  {
                                      title: ScreeningPageNames.MyScreenings,
                                      link: '/screening/public-trust/my-screenings',
                                      visible: true,
                                  },
                                  {
                                      title: ScreeningPageNames.MyOrg,
                                      link: '/screening/public-trust/my-org',
                                      visible: true,
                                  },
                                  {
                                      title: ScreeningPageNames.MyContracts,
                                      link: '/screening/public-trust/my-contracts',
                                      visible: userContext.hasPublicTrustUserType(
                                          UsGovScreeningUserType.ContractOwner,
                                      ),
                                  },
                                  {
                                      title: ScreeningPageNames.MyNominees,
                                      link: '/screening/public-trust/my-nominees',
                                      visible: userContext.hasPublicTrustUserType(
                                          UsGovScreeningUserType.Nominator,
                                      ),
                                  },
                                  {
                                      title: ScreeningPageNames.SuitabilityRecords,
                                      link: '/screening/public-trust/suitability-records',
                                      visible:
                                          menuFlags.suitabilityRecords.enabled &&
                                          (userContext.hasUsGovScreeningUserType(
                                              UsGovScreeningUserType.NST,
                                          ) ||
                                              userContext.hasUsGovScreeningUserType(
                                                  UsGovScreeningUserType.ContractOwner,
                                              )),
                                  },
                                  {
                                      title: ScreeningPageNames.Manage,
                                      link: '/screening/public-trust/manage',
                                      visible: userContext.hasPublicTrustUserType(
                                          UsGovScreeningUserType.NST,
                                      ),
                                  },
                              ],
                          },
                          {
                              title: ScreeningPageNames.Contracts,
                              link: '/screening/contracts',
                              visible: canShowContractAdminPage,
                          },
                          {
                              title: ScreeningPageNames.Reports,
                              visible:
                                  userContext.hasUsGovScreeningUserType(
                                      UsGovScreeningUserType.NST,
                                  ) || menuFlags.cloudReadinessReportDownload.enabled,
                              onClick: openReportModal,
                          },
                      ],
                  },
                  {
                      title: FacilitiesMenuNavigationText.Facilities,
                      visible:
                          menuFlags.facilitiesCore.enabled &&
                          userContext.isFacilitiesTokenLoaded &&
                          userContext.hasFacilitiesUserType(FacilityUserType.UserRole),
                      subMenu: [
                          {
                              title: FacilitiesMenuNavigationText.Reservations,
                              visible:
                                  userContext.isFacilitiesTokenLoaded &&
                                  userContext.hasFacilitiesUserType(FacilityUserType.UserRole),
                              subMenu: [
                                  {
                                      title: FacilitiesMenuNavigationText.NewReservation,
                                      link: '/facilities/reservations/new',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          userContext.hasFacilitiesUserType(
                                              FacilityUserType.UserRole,
                                          ),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.MyReservations,
                                      link: '/facilities/reservations/my',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          userContext.hasFacilitiesUserType(
                                              FacilityUserType.UserRole,
                                          ),
                                  },
                              ],
                          },
                          {
                              title: manageTitle,
                              visible:
                                  userContext.isFacilitiesTokenLoaded &&
                                  (userContext.hasFacilitiesUserType(
                                      FacilityUserType.AdminService,
                                  ) ||
                                      userContext.hasFacilitiesUserType(
                                          FacilityUserType.ManagerRole,
                                      ) ||
                                      userContext.hasFacilitiesUserType(
                                          FacilityUserType.EquipmentOfficerRole,
                                      )),
                              subMenu: [
                                  {
                                      title: FacilitiesMenuNavigationText.Equipment,
                                      link: '/facilities/manage/equipment',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.EquipmentOfficerRole,
                                              )),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.Seats,
                                      link: '/facilities/manage/seats',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              )),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.Audit,
                                      link: '/facilities/manage/audit',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.EquipmentOfficerRole,
                                              )),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.LogBook,
                                      link: '/facilities/manage/logbook',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              )),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.BlockedUsers,
                                      link: '/facilities/manage/blockedusers',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              )),
                                  },
                                  {
                                      title: FacilitiesMenuNavigationText.Metrics,
                                      link: '/facilities/manage/metrics',
                                      visible:
                                          userContext.isFacilitiesTokenLoaded &&
                                          (userContext.hasFacilitiesUserType(
                                              FacilityUserType.AdminService,
                                          ) ||
                                              userContext.hasFacilitiesUserType(
                                                  FacilityUserType.ManagerRole,
                                              )),
                                  },
                              ],
                          },
                      ],
                  },
                  {
                      title: 'Emails',
                      visible:
                          menuFlags.emailsCore.enabled &&
                          isPermitted(msalUserRoles, [
                              Role.EmailRead,
                              Role.EmailTemplateCreate,
                              Role.EmailTemplateRead,
                              Role.EmailTemplateDelete,
                              Role.EmailTemplateModify,
                          ]),
                      subMenu: [
                          {
                              title: 'Search',
                              link: '/email/search',
                              visible: isPermitted(msalUserRoles, [Role.EmailRead]),
                          },
                          {
                              title: 'Templates',
                              link: '/email/template',
                              visible: isPermitted(msalUserRoles, [
                                  Role.EmailTemplateCreate,
                                  Role.EmailTemplateRead,
                                  Role.EmailTemplateDelete,
                                  Role.EmailTemplateModify,
                              ]),
                          },
                          {
                              title: 'Manage',
                              link: '/email/manage',
                              visible: isPermitted(msalUserRoles, [Role.EmailCoordinator]),
                          },
                      ],
                  },
                  {
                      title: 'Forms',
                      visible: menuFlags.formsCore.enabled,
                      subMenu: [
                          {
                              title: 'Available forms',
                              link: '/forms/available-forms',
                              visible: true,
                          },
                          {
                              title: 'My forms',
                              link: '/forms/my-forms',
                              visible: true,
                          },
                      ],
                  },
                  {
                      title: 'SCA',
                      // permissions check -- if user is SCA admin or SCA exec
                      // also need to check if user is SCA member or SCA manager, which requires calling the SCA client to determine these two attributes
                      visible:
                          menuFlags.scaCore.enabled &&
                          (isPermitted(msalUserRoles, [Role.SCAAdmin, Role.SCAExec]) ||
                              userContext.isScaMember ||
                              userContext.isScaManager),
                      subMenu: [
                          {
                              title: 'My history',
                              link: '/sca/my-history',
                              visible: userContext.isScaMember, // SCA Auth
                          },
                          {
                              title: 'My team',
                              link: '/sca/my-team',
                              visible: userContext.isScaManager, // SCA Auth
                          },
                          {
                              title: 'My org',
                              link: '/sca/my-org',
                              visible: isPermitted(msalUserRoles, [Role.SCAExec]),
                          },
                          {
                              title: 'Program details',
                              link: '/sca/program-details',
                              visible: userContext.isScaManager,
                          },
                          {
                              title: ScreeningPageNames.Manage,
                              link: '/sca/manage',
                              visible: isPermitted(msalUserRoles, [Role.SCAAdmin]),
                          },
                      ],
                  },
              ]),
        {
            title: 'Groups',
            visible: menuFlags.groupsCore.enabled,
            subMenu: [
                {
                    title: 'My groups',
                    link: '/groups',
                    visible: true,
                },
                {
                    title: ScreeningPageNames.Manage,
                    link: '/groups/manage',
                    visible: isPermitted(msalUserRoles, [Role.GroupAdmin]),
                },
            ],
        },
        {
            title: 'Staffing',
            link: '/staffing',
            visible:
                menuFlags.staffingCore.enabled &&
                isPermitted(msalUserRoles, [
                    Role.StaffingAdminEdit,
                    Role.StaffingAdminRead,
                    Role.StaffingOrgEdit,
                    Role.StaffingOrgRead,
                ]),
        },
        {
            title: 'Readiness',
            visible: menuFlags.readinessCore.enabled,
            subMenu: [
                {
                    title: 'My View',
                    link: '/readiness/my-view',
                    visible: true,
                },
                {
                    title: 'My Org',
                    link: '/readiness/my-org',
                    visible: true,
                },
            ],
        },
        {
            title: 'Support',
            link: menuFlags.supportLinkToAka.enabled
                ? 'https://aka.ms/personnel/support'
                : '/support',
            visible: true,
        },
    ];
}
