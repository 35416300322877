import React, { useContext } from 'react';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import MetricTiles from 'components/common/metric-tiles/metric-tiles';
import MetricTilesContainer from 'components/common/metric-tiles/metric-tiles-container';
import MetricTile from 'components/common/metric-tiles/metric-tile';
import MetricTilesLegend from 'components/common/metric-tiles/metric-tiles-legend';
import { ReadinessVariableContext } from 'components/readiness/top/readiness-variable-context-provider';
import { ReadinessFilterContext } from 'components/readiness/top/readiness-filter-context';
import ReadinessMyOrgFilter from 'components/readiness/my-org/readiness-my-org-filter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReadinessMyOrgProps {}

// TODO: Remove if never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ReadinessMyOrg(props: ReadinessMyOrgProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const varContext = useContext(ReadinessVariableContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filterContext = useContext(ReadinessFilterContext);

    return (
        <SidebarAndContents>
            <SidebarPane>
                <ReadinessMyOrgFilter />
            </SidebarPane>
            <ContentPane>
                <MetricTiles ariaLabel='Metric tiles'>
                    <MetricTilesContainer>
                        <MetricTile
                            count={12}
                            label={'Red ripe big fresh organic homegrown apples'}
                        />
                        <MetricTile count={27} label={'Sour, Bitter Grapefruits'} />
                        <MetricTile count={10} label={'Juicy Oranges'} />
                    </MetricTilesContainer>
                    <MetricTilesLegend
                        title={'Legend'}
                        legend={[
                            {
                                label: 'Apple',
                                color: 'red',
                            },
                            {
                                label: 'Grapefruit',
                                color: 'yellow',
                            },
                            {
                                label: 'Orange',
                                color: 'orange',
                            },
                        ]}
                    />
                </MetricTiles>
            </ContentPane>
        </SidebarAndContents>
    );
}
