import React, { createContext, useState } from 'react';

export const CacheContext = createContext<ICacheContext>(null!);

export interface CacheContextProviderProps {
    cache: Map<string, unknown>;
    children: JSX.Element;
}

export function CacheContextProvider(props: CacheContextProviderProps): JSX.Element {
    const [cache] = useState<Map<string, unknown | undefined>>(props.cache);

    function store<T>(key: string, value: T): void {
        cache.set(key, value);
    }

    function retrieve<T>(key: string): T | undefined {
        return cache.get(key) as T | undefined;
    }

    function remove(key: string): void {
        cache.delete(key);
    }

    return (
        <CacheContext.Provider
            value={{
                store: store,
                retrieve: retrieve,
                remove: remove,
            }}>
            {props.children}
        </CacheContext.Provider>
    );
}

export interface ICacheContext {
    store: <T>(key: string, value: T) => void;
    retrieve: <T>(key: string) => T | undefined;
    remove: <T>(key: string) => void;
}
