import { useState, useEffect } from 'react';
import GraphClient, {
    IBatchPhotoResponse,
    IImageRequestType,
    PhotoSize,
} from 'clients/graph-client';
import { IAuthContext } from 'contexts/auth-context';
import { Dictionary } from 'assets/constants/global-constants';
import { ICacheContext } from 'contexts/cache-context';
import config from 'environments/environment';

const maxPhotoBatchSize = 20;

export const fullTimeEmployeeCardColor = '#0985BA';
export const vendorEmployeeCardColor = '#EBA338';

export const getInitials = (text: string): string => {
    if (text.length < 2) {
        return '??';
    }

    const textPronounsRemoved = text.replace(/\s*[\(\[].*?[\)\]]/g, '');
    const tokens = textPronounsRemoved.split(' ');

    if (tokens.length < 2) {
        return text.substring(0, 2);
    } else {
        const firstName = tokens.slice(0)[0];
        const lastName = tokens.slice(-1)[0];
        return firstName.charAt(0) + lastName.charAt(0);
    }
};

export function getAlias(upn?: string): string | undefined {
    if (config.defaultDomain && upn?.endsWith(config.defaultDomain)) {
        return upn.substring(0, upn.length - config.defaultDomain.length);
    }

    return undefined;
}

export function getEmployeeInitials(
    emp:
        | {
              displayName?: string;
              preferredFirstName?: string;
              preferredLastName?: string;
              firstName?: string;
              lastName?: string;
              fullName?: string;
          }
        | undefined,
): string {
    const displayName = getDisplayNameOrDefault(emp);
    const nameSplit = displayName.split(' ');
    const firstName = nameSplit[0] ?? ' ';
    const lastName = nameSplit[1] ?? ' ';
    return `${firstName[0]}${lastName[0]}`?.trim()?.toUpperCase();
}

export function getDisplayNameOrDefault(
    providedEmployee:
        | {
              displayName?: string;
              preferredFirstName?: string;
              preferredLastName?: string;
              firstName?: string;
              lastName?: string;
              fullName?: string;
          }
        | undefined,
    defaultValue = '',
): string {
    if (providedEmployee) {
        if (providedEmployee.displayName) {
            return providedEmployee.displayName;
        } else if (providedEmployee.preferredFirstName && providedEmployee.preferredLastName) {
            return `${providedEmployee?.preferredFirstName} ${providedEmployee?.preferredLastName}`;
        } else if (providedEmployee.firstName && providedEmployee.lastName) {
            return `${providedEmployee?.firstName} ${providedEmployee?.lastName}`;
        } else if (providedEmployee.fullName) {
            return providedEmployee.fullName;
        }
    }
    return defaultValue;
}

export const fetchPictureBase = 'data:image/jpeg;base64,';

export const fetchPictures = async (
    authContext: IAuthContext,
    employees: IImageRequestType[],
    dimensions?: PhotoSize,
    cacheContext?: ICacheContext,
): Promise<Dictionary<string>> => {
    const pictures: Dictionary<string> = {};

    try {
        const promises: Promise<IBatchPhotoResponse>[] = [];

        for (let i = 0; i < employees.length; i += maxPhotoBatchSize) {
            const partitionedEmployees = employees.slice(i, i + maxPhotoBatchSize);
            promises.push(GraphClient.getBatchPhoto(authContext, partitionedEmployees, dimensions));
        }

        const resolved = await Promise.allSettled(promises);
        resolved.forEach((x) => {
            if (x.status === 'rejected') {
                return;
            }

            x.value.responses.forEach((result) => {
                if (result.status === 200) {
                    const { id, body } = result;
                    pictures[id.toString()] = fetchPictureBase + body;
                } else {
                    const { id } = result;
                    pictures[id.toString()] = fetchPictureBase;
                }
            });
        });

        // Employee Cards rely on the CacheContext for photos so provide it here if you plan to instantiate large amounts of them
        // Only cache default dimensions
        if (cacheContext && !dimensions) {
            for (let i = 0; i < employees.length; i++) {
                cacheContext.store(imageCacheId(employees[i]), pictures[employees[i].id]);
            }
        }
    } catch (e) {
        console.error('Could not batch-fetch pictures of employees', e);
    }

    return pictures;
};

export const fetchOrUseCachePictures = async (
    authContext: IAuthContext,
    employees: IImageRequestType[],
    cacheContext: ICacheContext,
): Promise<Dictionary<string>> => {
    const picturesFromCache: Dictionary<string> = {};

    const cacheMissEmployees: IImageRequestType[] = [];

    employees.forEach((x) => {
        const cachedPicture = getCachedPicture(x, cacheContext);
        if (cachedPicture) {
            picturesFromCache[x.id] = cachedPicture;
        } else {
            cacheMissEmployees.push(x);
        }
    });

    const newlyFetchedPictures = await fetchPictures(
        authContext,
        cacheMissEmployees,
        undefined,
        cacheContext,
    );

    return {
        ...picturesFromCache,
        ...newlyFetchedPictures,
    };
};

export const getCachedPicture = (
    employee: IImageRequestType,
    cacheContext: ICacheContext,
): string | undefined => {
    return cacheContext.retrieve<string>(imageCacheId(employee));
};

interface IDetermineImageParamsType {
    employee?: IImageRequestType;
    authContext?: IAuthContext;
    cacheContext: ICacheContext;
}

interface IUseImageParamsType extends IDetermineImageParamsType {
    base64ImageString?: string; // If the image is already available, pass it to base64ImageString.
}

/**
 * Returns Promise of a picture or a blank string.
 * @param params: IDetermineImageParamsType
 * @returns
 */
const determineImage = async (params: IDetermineImageParamsType): Promise<string> => {
    const cacheId = imageCacheId(params.employee);
    let imageStr = params.cacheContext.retrieve<string>(cacheId);
    if (params.employee && params.authContext && imageStr === undefined) {
        // No need for try/catch
        // The function fetchPictures() catches.
        const { authContext, employee } = params;
        try {
            const pictures = await fetchPictures(authContext, [employee]);
            if (pictures[employee.id]) {
                imageStr = pictures[employee.id];
                params.cacheContext.store(cacheId, imageStr);
            }
        } catch (e) {
            console.warn('Failed to get image for ', params, ' exception - ', e);
        }
    }
    return imageStr ?? '';
};

function imageCacheId(employee?: IImageRequestType): string {
    return `imageCache-${employee?.id}`;
}

/**
 * React custom hook.
 * Uses function determineImage to determine the image.
 * Returns Promise of a picture or a blank string.
 * @param props: IUseImageParamsType
 * @returns
 */
export const useDetermineImage = (props: IUseImageParamsType): string => {
    const [image, setImage] = useState<string>('');
    const [employee, setEmployee] = useState<IImageRequestType | undefined>();

    const callDetermineImage = async (isMountedFunc: () => boolean): Promise<void> => {
        const imageVar = await determineImage({
            authContext: props.authContext,
            employee: employee,
            cacheContext: props.cacheContext,
        });

        if (isMountedFunc()) {
            setImage(imageVar);
        }
    };

    useEffect((): (() => void) => {
        let isMounted = true;
        const isMountedFunc = (): boolean => {
            return isMounted;
        };

        if (props.base64ImageString) {
            setImage(props.base64ImageString);
        } else if (employee) {
            const imageCache = props.cacheContext.retrieve<string>(imageCacheId(employee));
            if (imageCache) {
                setImage(imageCache);
            } else {
                callDetermineImage(isMountedFunc);
            }
        } else {
            setImage('');
        }
        return (): void => {
            isMounted = false;
        };
    }, [employee]);

    useEffect((): void => {
        if (props.employee?.id !== '' && (!image || props.employee?.id !== employee?.id)) {
            const img = props.cacheContext.retrieve<string>(imageCacheId(props.employee));
            if (img) {
                setImage(img);
            } else {
                setEmployee(props.employee);
            }
        }
    }, [props.employee]);
    return image;
};
