import {
    DefaultButton,
    FluentTheme,
    FontIcon,
    IPersonaProps,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import {
    CreateAttributeSetRequest,
    EditAttributeSetRequest,
    GetAllAttributeSetsResult,
    GetAttributeSetByIdResult,
} from 'personnel-core-clients';
import { CoreMultiOidFromGraphPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import React, { useContext, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { AttributeSetNameRegex } from 'utils/cor-utils';
import { newGuid } from '@microsoft/applicationinsights-core-js';
import { CoreAttributeSetsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { IconNames } from 'assets/constants/global-constants';
import Spacer from 'components/common/spacer';
import { IGraphPrincipal, isGraphServicePrincipal } from 'clients/graph-client';

export enum AttributeSetModalType {
    Add = 'Add',
    Edit = 'Edit',
}

export interface IAddEditAttributeSetsPanelProps {
    isOpen?: boolean;
    modalType: AttributeSetModalType;
    initAttributeSet?: GetAllAttributeSetsResult;
    initAttributeSets?: GetAllAttributeSetsResult[];
    onCreateOrUpdate: () => void;
    onDismiss: () => void;
}

export default function AddEditAttributeSetsPanel(
    props: IAddEditAttributeSetsPanelProps,
): JSX.Element {
    const EmployeePickerListLimit = 50;
    const authContext = useContext(AuthContext);
    const [isPrimaryDisabled, setIsPrimaryDisabled] = useState<boolean>(false);

    const buttonStyles = { root: { marginRight: 8 } };
    const [name, setName] = useState<string>();
    const [uniqueIdentifier, setUniqueIdentifier] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [shouldUseAutoID, { toggle: setUseAutoID }] = useBoolean(true);
    const [showErrors, setShowErrors] = useState(false);
    const [owners, setOwners] = useState<string[]>([]);
    const [managers, setManagers] = useState<string[]>([]);
    const [readers, setReaders] = useState<string[]>([]);
    const [ownerPrincipals, setOwnerPrincipals] = useState<IGraphPrincipal[]>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (
            props.initAttributeSet &&
            props.isOpen === true &&
            props.modalType === AttributeSetModalType.Edit
        ) {
            setUniqueIdentifier(props.initAttributeSet.id);
            setName(props.initAttributeSet.name);
            setDescription(props.initAttributeSet.description);
            setOwners(props.initAttributeSet.owners);
            setManagers(props.initAttributeSet.managers);
            setReaders(props.initAttributeSet.readers);
        }
    }, [props.initAttributeSet, props.isOpen]);

    const isNameValid = (): boolean => {
        return props.modalType === AttributeSetModalType.Add
            ? !!name &&
                  name.length >= MinLens.name &&
                  name.length <= MaxLens.name &&
                  AttributeSetNameRegex.test(name)
            : true;
    };

    const isUniqueIdValid = (): boolean => {
        return !!uniqueIdentifier && !uniqueIdentifier.match(/^[0-9]/g);
    };

    const isDescriptionValid = (): boolean => {
        return (
            !!description &&
            description.length >= MinLens.description &&
            description.length <= MaxLens.description
        );
    };

    const isOwnersValid = (): boolean => {
        if (!owners) {
            return false;
        }

        const hasServicePrincipals = ownerPrincipals?.some((p) => isGraphServicePrincipal(p));
        if (ownerPrincipals === undefined || hasServicePrincipals) {
            return owners.length >= MinLens.servicePrincipalOwners;
        } else {
            return owners.length >= MinLens.userOwners;
        }
    };

    const validateFields = (): void => {
        if (
            props.initAttributeSets &&
            props.initAttributeSets.map((attSets) => attSets.id).includes(uniqueIdentifier!) &&
            props.modalType === AttributeSetModalType.Add
        ) {
            throw new Error(
                'An Attribute set already exists with that ID. Please provide a unique identifier and try again',
            );
        } else if (!isUniqueIdValid() || !isDescriptionValid() || !isOwnersValid()) {
            throw new Error('Please check fields for errors and try again');
        } else {
            setShowErrors(false);
            setError(undefined);
        }
    };

    const onOwnersSelected = (oids?: string[], graphPrincipals?: IGraphPrincipal[]): void => {
        if (oids && graphPrincipals) {
            setOwners(oids);
            setOwnerPrincipals(graphPrincipals);
        }
    };

    const onManagersSelected = (info?: string[]): void => {
        if (info && info.length <= EmployeePickerListLimit) {
            setManagers(info);
        }
    };

    const onReadersSelected = (info?: string[]): void => {
        if (info && info.length <= EmployeePickerListLimit) {
            setReaders(info);
        }
    };

    useEffect(() => {
        if (name && props.modalType === AttributeSetModalType.Add && shouldUseAutoID) {
            setSanitizedLowerCaseName();
        }
    }, [name, props.modalType]);

    const setSanitizedLowerCaseName = (): void => {
        const sanitized = name?.replace(/[^a-zA-Z0-9]/g, '');
        setUniqueIdentifier(sanitized);
    };

    useEffect(() => {
        updateUniqueIdentifier();
    }, [shouldUseAutoID]);

    const updateUniqueIdentifier = (): void => {
        if (!shouldUseAutoID) {
            let generatedId = newGuid();
            while (!generatedId.match(/^[a-zA-Z]/g)) {
                generatedId = newGuid();
            }
            setUniqueIdentifier(generatedId.replace(/[^a-zA-Z0-9]/g, ''));
        } else if (name) {
            setSanitizedLowerCaseName();
        } else {
            setUniqueIdentifier('');
        }
    };

    const onClick = async (): Promise<void> => {
        try {
            setIsPrimaryDisabled(true);
            validateFields();
            const client = new CoreAttributeSetsClient(authContext);
            const ownerOids = owners?.filter((o) => !!o);
            const managerOids = managers?.filter((o) => !!o);
            const readerOids = readers?.filter((o) => !!o);

            const createAttributeSetRequest: CreateAttributeSetRequest = new CreateAttributeSetRequest(
                {
                    id: uniqueIdentifier!,
                    name: name!,
                    description: description!,
                    owners: ownerOids!,
                    managers: managerOids!,
                    readers: readerOids!,
                },
            );

            const editAttributeSetRequest: EditAttributeSetRequest = new EditAttributeSetRequest({
                description: description!,
                name: name!,
                managers: managerOids!,
                owners: ownerOids!,
                readers: readerOids!,
            });

            try {
                const attSet =
                    props.modalType === AttributeSetModalType.Add
                        ? await client.create(createAttributeSetRequest)
                        : await client.edit(editAttributeSetRequest, uniqueIdentifier!);
                if (attSet) {
                    onSuccess();
                } else {
                    setShowErrors(true);
                }
            } catch (e) {
                throw new Error(
                    `An error occurred ${
                        props.modalType === AttributeSetModalType.Add ? 'creating' : 'saving'
                    } the attribute set. Please try again.`,
                );
            }
        } catch (e) {
            setIsPrimaryDisabled(false);
            setShowErrors(true);
            setError(e.message);
            throw e;
        } finally {
            setIsPrimaryDisabled(false);
        }
    };

    const onSuccess = async (): Promise<void> => {
        props.onCreateOrUpdate();
        onCancel();
    };

    const onCancel = (): void => {
        props.onDismiss();
        resetFormFields();
        setIsPrimaryDisabled(false);
    };

    const resetFormFields = (): void => {
        setName('');
        setUniqueIdentifier('');
        setDescription('');
        setOwners([]);
        setManagers([]);
        setReaders([]);
        setOwnerPrincipals(undefined);
        setShowErrors(false);
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack>
                {showErrors && (
                    <div>
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        <Spacer marginTop={10} />
                    </div>
                )}
                <div>
                    <PrimaryButton
                        onClick={onClick}
                        styles={buttonStyles}
                        disabled={isPrimaryDisabled}>
                        {props.modalType === AttributeSetModalType.Add ? 'Add' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
                </div>
            </Stack>
        ),
        [onCancel],
    );

    return (
        <Panel
            headerText={
                props.modalType === AttributeSetModalType.Add
                    ? 'Add attribute set'
                    : 'Edit attribute set'
            }
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={(): void => onCancel()}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'400px'}
            styles={styles.panel}>
            <TextField
                label='Attribute set name'
                required
                placeholder='New Attribute set name'
                maxLength={MaxLens.name}
                minLength={MinLens.name}
                validateOnFocusOut={true}
                value={name}
                onChange={(ev, newVal): void => {
                    setName(newVal);
                }}
                errorMessage={
                    showErrors && !isNameValid()
                        ? 'Attribute set name must contain only letters and numbers (6-32 characters and cannot start with a number)'
                        : ''
                }
                styles={styles.element}
            />
            <TextField
                onRenderLabel={(): JSX.Element => {
                    return (
                        <div>
                            <LabelInfoIcon
                                iconHoverContent={
                                    'Assigned to each attribute set as a permanent ID.'
                                }
                                iconName={IconNames.Info}>
                                Unique identifier
                                <span style={{ color: FluentTheme.palette.red }}> *</span>
                            </LabelInfoIcon>
                        </div>
                    );
                }}
                placeholder={!shouldUseAutoID ? uniqueIdentifier : 'Auto-generated ID'}
                maxLength={MaxLens.name}
                value={uniqueIdentifier}
                disabled
                errorMessage={
                    showErrors && !isUniqueIdValid()
                        ? 'Unique Identifier must be unique and not start with a number'
                        : ''
                }
                styles={styles.element}
            />
            <div style={styles.button}>
                <DefaultButton
                    toggle
                    text={shouldUseAutoID ? 'Generate secure ID' : 'Use formatted name'}
                    disabled={props.modalType === AttributeSetModalType.Edit}
                    onClick={(): void => {
                        setUseAutoID();
                    }}
                />
            </div>
            <TextField
                label='Description'
                placeholder='New Attribute set description'
                maxLength={MaxLens.description}
                minLength={MinLens.description}
                value={description}
                onChange={(ev, newVal): void => setDescription(newVal)}
                errorMessage={
                    showErrors && !isDescriptionValid()
                        ? 'Description must be between 6-128 characters'
                        : ''
                }
                required
                styles={styles.element}
            />
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Owners'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Owners'
                        onChange={onOwnersSelected}
                        selectedItems={owners}
                        required
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
            {showErrors && !isOwnersValid() && (
                <span
                    style={{
                        color: FluentTheme.semanticColors.errorText,
                        fontSize: 12,
                    }}>
                    A minimum of {MinLens.userOwners} user owners or{' '}
                    {MinLens.servicePrincipalOwners} service principal owners are required.
                </span>
            )}
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Managers'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Managers'
                        onChange={onManagersSelected}
                        selectedItems={managers}
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Readers'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Readers'
                        onChange={onReadersSelected}
                        selectedItems={readers}
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
        </Panel>
    );
}

const MaxLens = {
    name: 32,
    description: 128,
    support: 250,
};

const MinLens = {
    name: 6,
    description: 6,
    userOwners: 2,
    servicePrincipalOwners: 1,
};

const styles = {
    button: {
        marginTop: '10px',
        marginBottom: '5px',
        padding: 0,
    },
    element: {
        root: {
            marginTop: '25px',
            width: '100%',
        },
    },
    panel: {
        root: {
            marginTop: '65px',
        },
    },
};

const mergedstyles = mergeStyleSets({
    searchIcon: {
        position: 'absolute',
        right: '5px',
        top: '37px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
    searchBoxWrapper: {
        marginTop: '20px',
        marginBottom: '5px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    searchBox: {
        position: 'relative',
        display: 'flex',
        width: '661px',
    },
});
function createUnknownPersona(oid: string): IPersonaProps {
    return {
        imageInitials: '?',
        text: oid,
        itemID: oid,
    };
}
