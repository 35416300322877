import React, { useContext, useMemo } from 'react';
import { AuthContext } from 'contexts/auth-context';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { ReadinessVariableContext } from 'components/readiness/top/readiness-variable-context-provider';
import { ReadinessFilterContext } from 'components/readiness/top/readiness-filter-context';
import ReadinessMyOrgFilter from 'components/readiness/my-org/readiness-my-org-filter';
import { tableColumnsMyView } from 'components/readiness/my-view/table-columns-my-view';
import { Table } from 'components/common/table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReadinessMyViewProps {}

// TODO: Remove props if never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ReadinessMyView(props: ReadinessMyViewProps): JSX.Element {
    const authContext = useContext(AuthContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const varContext = useContext(ReadinessVariableContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filterContext = useContext(ReadinessFilterContext);

    const columns = useMemo(() => {
        return tableColumnsMyView({
            scopes: varContext.scopes,
            scopeFilter: filterContext.scopeFilter,
        });
    }, [varContext.scopes, filterContext.scopeFilter]);

    return (
        <SidebarAndContents>
            <SidebarPane>
                <ReadinessMyOrgFilter />
            </SidebarPane>
            <ContentPane>
                <Table tableColumns={columns} rows={[]} isFetchingData={false} />
            </ContentPane>
        </SidebarAndContents>
    );
}
