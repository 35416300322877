import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import { EditCsaStateRequest } from 'personnel-core-clients';
import React, { useContext, useEffect, useState } from 'react';

export interface IUpdateCsaAadStatusProps {
    principalId: string;
    isDialogOpen: boolean;
    CurrentSyncStatus: boolean | undefined;
    onDismissed: () => void;
    onSuccess: () => void;
}

export function UpdateCsaAadStatusModal(props: IUpdateCsaAadStatusProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [messageType, setMessageType] = useState<MessageBarType>(
        props.CurrentSyncStatus ? MessageBarType.warning : MessageBarType.info,
    );
    const [message, setMessage] = useState<string | undefined>(
        props.CurrentSyncStatus
            ? 'Caution, once CSA/AAD Enabled is disabled, the User will no longer have CSAs in AAD and if reenabled, there is no guarantee the same CSAs will be synced to AAD.'
            : 'Enable CSA/AAD Sync for this user?',
    );
    const [shouldShowSubmit, setShouldShowSubmit] = useState<boolean>(true);
    const updateCsaAad = async (): Promise<void> => {
        try {
            setShouldShowSubmit(false);
            const principalClient = new CorePrincipalsClient(authContext);
            const editCsaSyncStateRequest: EditCsaStateRequest = new EditCsaStateRequest({
                enableCsaSyncState: !props.CurrentSyncStatus,
            });

            await principalClient.editCsaSyncState(editCsaSyncStateRequest, props.principalId);

            props.onSuccess();
            props.onDismissed();
        } catch (e) {
            setMessageType(MessageBarType.error);
            setMessage(
                `An error occurred while ${
                    props.CurrentSyncStatus ? 'disabling' : 'enabling'
                } the CSA/AAD sync.`,
            );
        }
    };

    useEffect(() => {
        setMessage(
            props.CurrentSyncStatus
                ? 'Caution, once CSA/AAD Enabled is disabled, the User will no longer have CSAs in AAD and if reenabled, there is no guarantee the same CSAs will be synced to AAD.'
                : 'Enable CSA/AAD Sync for this user?',
        );
        setMessageType(props.CurrentSyncStatus ? MessageBarType.warning : MessageBarType.info);
        setShouldShowSubmit(true);
    }, [props.CurrentSyncStatus]);

    const onClose = async (): Promise<void> => {
        props.onDismissed();
        setShouldShowSubmit(true);
    };

    return (
        <Dialog
            styles={{
                main: {
                    width: 'auto',
                },
            }}
            hidden={!props.isDialogOpen}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title: `${props.CurrentSyncStatus ? 'Disable' : 'Enable'} CSA/AAD sync`,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {messageType === MessageBarType.warning &&
                    'Are you sure you want to disable the CSA/AAD sync?'}
                {messageType === MessageBarType.info &&
                    'Are you sure you want to enable the CSA/AAD sync?'}
            </div>
            <DialogFooter>
                {shouldShowSubmit && (
                    <PrimaryButton
                        onClick={updateCsaAad}
                        text={props.CurrentSyncStatus ? 'Disable' : 'Enable'}
                    />
                )}

                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
