import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn } from '@fluentui/react';
import { IReadinessScopeDefinition } from 'clients/readiness-client';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { ICheckboxArrayV1Props } from 'components/common/use-input/use-checkbox-array-v1';

interface ITableColumnsMyView {
    scopes: IReadinessScopeDefinition[];
    scopeFilter: ICheckboxArrayV1Props[] | undefined;
}

export function tableColumnsMyView(params: ITableColumnsMyView): IColumn[] {
    const showAllScopes = params.scopeFilter?.every((entry) => !entry.isChecked);

    const columns: IColumn[] = [];
    params.scopes.forEach((scope, ix) => {
        if (showAllScopes || (!!params.scopeFilter && params.scopeFilter[ix].isChecked)) {
            columns.push({
                key: scope.name,
                name: scope.name,
                ariaLabel: scope.name,
                minWidth: 55,
                maxWidth: 55 * xLargeMaxWidthCoeff,
                onRender: (): JSX.Element => <TableCell>Placeholder</TableCell>,
            });
        }
    });

    return columns;
}
