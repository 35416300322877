import { IEmployee } from 'clients/employee-client';
import {
    IInitEmployeePickerParams,
    useEmployeePicker,
} from 'components/common/use-input/use-employee-picker';
import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import {
    ICheckboxArrayV1Props,
    useCheckboxArrayV1,
} from 'components/common/use-input/use-checkbox-array-v1';
import { enumEntries } from 'utils/misc-utils';
import { IReadinessStatus } from 'clients/readiness-client';
import { ReadinessVariableContext } from 'components/readiness/top/readiness-variable-context-provider';

type FilterContextStateType = {
    employeeFilter: IEmployee | undefined;
    initEmployeeFilter: (initValue?: IInitEmployeePickerParams | undefined) => void;
    employeeFilterElement: () => JSX.Element;
    reportsToFilter: IEmployee | undefined;
    initReportsToFilter: (initValue?: IInitEmployeePickerParams | undefined) => void;
    reportsToFilterElement: () => JSX.Element;
    scopeStatusFilter: ICheckboxArrayV1Props[] | undefined;
    initScopeStatusFilter: (initValue?: ICheckboxArrayV1Props[]) => void;
    scopeStatusFilterElement: () => JSX.Element;
    scopeFilter: ICheckboxArrayV1Props[] | undefined;
    initScopeFilter: (initValue?: ICheckboxArrayV1Props[]) => void;
    scopeFilterElement: () => JSX.Element;
    // Clear all
    clearFilters: () => void;
};

export const ReadinessFilterContext = createContext<FilterContextStateType>(null!);

export interface IReadinessFilterProviderProps {
    children: ReactNode;
}

export default function ReadinessFilterProvider(props: IReadinessFilterProviderProps): JSX.Element {
    const varContext = useContext(ReadinessVariableContext);

    const {
        value: employeeFilter,
        initialize: initEmployeeFilter,
        theElement: employeeFilterElement,
    } = useEmployeePicker({
        label: 'Employee',
        placeHolder: 'Employee name or alias',
    });

    const {
        value: reportsToFilter,
        initialize: initReportsToFilter,
        theElement: reportsToFilterElement,
    } = useEmployeePicker({
        label: 'Reports to',
        placeHolder: 'Employee name or alias',
    });

    const {
        value: scopeStatusFilter,
        initialize: initScopeStatusFilter,
        theElement: scopeStatusFilterElement,
    } = useCheckboxArrayV1({
        label: 'Scope Status',
        options: enumEntries(IReadinessStatus).map((option) => ({
            enabled: true,
            isChecked: false,
            label: option.value as string, // Safe typecast because values of ScopeStatusEnum are string.
        })),
    });

    const {
        value: scopeFilter,
        initialize: initScopeFilter,
        theElement: scopeFilterElement,
    } = useCheckboxArrayV1({
        label: 'Scopes',
        options: initScopeFilterValue(),
    });

    function initScopeFilterValue(): ICheckboxArrayV1Props[] {
        return varContext.scopes?.map((scope) => ({
            enabled: true,
            isChecked: false,
            label: scope.name,
        }));
    }

    useEffect(() => {
        // Scopes are fetched from server.
        // Update the filter box when they are available.
        initScopeFilter(initScopeFilterValue());
    }, [varContext.scopes]);

    const clearFilters = (): void => {
        initEmployeeFilter(undefined);
        initReportsToFilter(undefined);
        initScopeStatusFilter();
        initScopeFilter(undefined);
    };

    const contextValue = {
        employeeFilter,
        initEmployeeFilter,
        employeeFilterElement,
        reportsToFilter,
        initReportsToFilter,
        reportsToFilterElement,
        scopeStatusFilter,
        initScopeStatusFilter,
        scopeStatusFilterElement,
        scopeFilter,
        initScopeFilter,
        scopeFilterElement,
        clearFilters,
    };

    return (
        <ReadinessFilterContext.Provider value={contextValue}>
            {props.children}
        </ReadinessFilterContext.Provider>
    );
}
