import React, { useContext, useEffect, useState } from 'react';
import ReadinessMyOrg from 'components/readiness/my-org/readiness-my-org';
import ReadinessMyView from 'components/readiness/my-view/readiness-my-view';
import { Redirect, useParams } from 'react-router-dom';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { readinessBreadcrumbs, ReadinessTabs } from 'components/readiness/readiness-utils';
import { useCreatePlaceholderScopes } from 'components/readiness/dev/readiness-dev-utils';
import { AuthContext } from 'contexts/auth-context';
import { useFetchSimple } from 'utils/misc-hooks';
import ReadinessClient, { IReadinessScopeDefinition } from 'clients/readiness-client';
import { ReadinessVariableContext } from 'components/readiness/top/readiness-variable-context-provider';
import { doNothing } from 'utils/misc-utils';
import { strCmp } from 'utils/sort-utils';

// TODO: Remove "props" if neither of the children ever used it.

// Please forgive the type "any" below. This module is just
// passing along whatever it's received from module Routes.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Readiness(props: any): JSX.Element {
    const authContext = useContext(AuthContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const varContext = useContext(ReadinessVariableContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    // TODO - Remove when real scopes are ready.
    const { isNewScopesCreated } = useCreatePlaceholderScopes(authContext);

    const { tab } = useParams<{ tab?: string }>();

    const [redirectTo, setRedirectTo] = useState<string>();

    const tabKeys = Object.values(ReadinessTabs) as string[];

    const onTabChange = (itemKey?: string): void => {
        if (itemKey && tabKeys.includes(itemKey)) {
            setRedirectTo(`/readiness/${itemKey}`);
        }
    };

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(readinessBreadcrumbs(tab));
    }, [tab]);

    useFetchSimple({
        dependencies: [isNewScopesCreated],
        canPerformFetch: true,
        fetchFunc: async (): Promise<IReadinessScopeDefinition[]> => {
            return ReadinessClient.getScopes(authContext);
        },
        onSuccess: (result: IReadinessScopeDefinition[]) => {
            varContext.setScopes(result.sort((s1, s2) => strCmp(s1.name, s2.name)));
        },
        onError: doNothing,
        onFinally: doNothing,
    });

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo]);

    if (!tab) {
        return <Redirect to={'/readiness/my-org'} />;
    }

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    }

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <Tabs selectedKey={tab} onChange={onTabChange}>
                <TabbedContent
                    key={ReadinessTabs.MyView}
                    tabHeader='My View'
                    itemKey={ReadinessTabs.MyView}>
                    <ReadinessMyView {...props} />
                </TabbedContent>
                <TabbedContent
                    key={ReadinessTabs.MyOrg}
                    tabHeader='My Org'
                    itemKey={ReadinessTabs.MyOrg}>
                    <ReadinessMyOrg {...props} />
                </TabbedContent>
            </Tabs>
        </div>
    );
}
