import React, { useContext, useEffect, useMemo, useState } from 'react';
import GraphClient from 'clients/graph-client';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { useDetermineImage } from 'components/common/employee/employee-utils';
import { IRenderFunction } from '@fluentui/react';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import { getAppInsights } from 'utils/telemetry-utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { SystemGuid } from 'utils/cor-utils';

export interface ICardPrincipalProviderFromGraphProps {
    oid?: string;
    cardPrincipal?: ICardPrincipal;
    onRender: IRenderFunction<ICardPrincipal>;
    onCardPrincipalProvided?: (data: ICardPrincipal | undefined) => void;
}

export default function CardPrincipalProviderFromGraph(
    props: ICardPrincipalProviderFromGraphProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const { oid, cardPrincipal, onRender, onCardPrincipalProvided } = props;

    const [graphDataCardPrincipal, setGraphDataCardPrincipal] = useState<Partial<ICardPrincipal>>();

    useEffect(() => {
        (async (): Promise<void> => {
            if (oid && oid !== SystemGuid) {
                try {
                    const graphResult = await GraphClient.getUserByOidQuery(
                        authContext,
                        oid,
                        '$select=displayName,jobTitle,officeLocation,userPrincipalName,mail,department&$expand=manager($levels=1;$select=displayName,userPrincipalName)',
                    );
                    setGraphDataCardPrincipal({
                        oid: props.oid,
                        displayName: graphResult?.displayName,
                        jobTitle: graphResult?.jobTitle,
                        officeLocation: graphResult?.officeLocation,
                        upn: graphResult?.userPrincipalName,
                        email: graphResult?.mail,
                        organization: graphResult?.department,
                        manager: graphResult?.manager?.displayName,
                        managerUpn: graphResult?.manager?.userPrincipalName,
                    });
                } catch (e) {
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                    setGraphDataCardPrincipal({});
                }
            } else {
                setGraphDataCardPrincipal({});
            }
        })();
    }, [authContext, oid]);

    const cacheContext = useContext(CacheContext);
    const image = useDetermineImage({
        authContext: authContext,
        employee: oid
            ? {
                  id: oid,
                  oid: oid,
              }
            : undefined,
        cacheContext: cacheContext,
    });

    // Prefer graph data, then provided props, then an empty card principal with a blank display name
    const combinedCardPrincipal = useMemo(
        () =>
            ({
                imageUrl: image,
                ...cardPrincipal,
                ...graphDataCardPrincipal,
            } as ICardPrincipal),
        [cardPrincipal, graphDataCardPrincipal, image],
    );

    useEffect(() => {
        if (onCardPrincipalProvided) {
            onCardPrincipalProvided(combinedCardPrincipal);
        }
    }, [combinedCardPrincipal, onCardPrincipalProvided]);

    return <>{onRender(combinedCardPrincipal)}</>;
}
