import { IFiltersClearanceRecords } from 'contexts/filters-clearance-records';
import { IFiltersSuitabilityRecords } from 'contexts/filters-suitability-records';

export enum BrowserCacheKeys {
    // Employee
    getEmployeeCache = 'getEmployeeCache',
    getEmployeesCache = 'getEmployeesCache',
    getPositionCache = 'getPositionCache',
    getBasicEmployeesCache = 'getBasicEmployeesCache',
    // Screening
    hierarchyReportStorageAndCacheKey = 'hierarchyReportRecords',
    hierarchyReportTimestampKey = 'hierarchyReportTimestamp',
    usgovShouldShowClearanceAndScreeningHelpLink = 'usgov.shouldShowClearanceAndScreeningHelpLink',
    usgovScreeningsManageSearchFilterSettings = 'usgov.screenings.manage.searchFilterSettings',
    facilitiesManageEquipmentSearchFilterSettings = 'facilities.manage.equipment.searchFilterSettings',
    // Forms
    formsTeachingBubble = 'formsTeachingBubble',
    loginOriginalRequestUriKey = 'loginOriginalRequestUri',
}

export function generateCacheKeyContract(cacheKey: string): string {
    return `${cacheKey}+Contracts`;
}

export function generateCacheKeyEmployeeNames(
    filtersContext: IFiltersClearanceRecords | IFiltersSuitabilityRecords,
): string {
    return `${filtersContext.location.pathname}${filtersContext.location.search}EmployeeNames`;
}
