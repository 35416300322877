import React from 'react';
import ReadinessVariableContextProvider from 'components/readiness/top/readiness-variable-context-provider';
import ReadinessFilterProvider from 'components/readiness/top/readiness-filter-context';
import Readiness from 'components/readiness/top/readiness';

// TODO: Remove "props" if neither of the children ever used it.

// Please forgive the type "any" below. This module is just
// passing along whatever it's received from module Routes.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ReadinessWrapper(props: any): JSX.Element {
    return (
        <ReadinessVariableContextProvider>
            <ReadinessFilterProvider>
                <Readiness />
            </ReadinessFilterProvider>
        </ReadinessVariableContextProvider>
    );
}
