import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { AttributeStatus, EditAttributeRequest, GetAttributeResult } from 'personnel-core-clients';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

export interface IAttributeActionModalProps {
    attribute: GetAttributeResult | undefined;
    actionType: 'deactivate' | 'reactivate';
    onDismissed: () => void;
    onSuccess: () => void;
}

const DEFAULT_DEACTIVATE_MESSAGE =
    'Caution, deactivating this attribute will move it to the Deactivated attribute view.';
const DEFAULT_REACTIVATE_MESSAGE =
    'Caution, reactivating this attribute will move it to the Reactivated attribute view.';

export function AttributeActionModal(props: IAttributeActionModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const { attribute, onDismissed, onSuccess } = props;
    const [isActionConfirmed, setIsActionConfirmed] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(
        props.actionType === 'deactivate' ? DEFAULT_DEACTIVATE_MESSAGE : DEFAULT_REACTIVATE_MESSAGE,
    );

    const onClose = async (): Promise<void> => {
        onDismissed();
        setIsActionConfirmed(false);
        setMessageType(MessageBarType.warning);
        setMessage(
            props.actionType === 'deactivate'
                ? DEFAULT_DEACTIVATE_MESSAGE
                : DEFAULT_REACTIVATE_MESSAGE,
        );
    };

    const handleAttributeAction = async (): Promise<void> => {
        if (attribute === undefined) {
            return;
        }

        try {
            setIsActionConfirmed(true);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'Deactivating attribute set...'
                    : 'Reactivating attribute set...',
            );
            const attributeClient = new CoreAttributesClient(authContext);
            const status =
                props.actionType === 'deactivate'
                    ? AttributeStatus.Deprecated
                    : AttributeStatus.Active;

            await attributeClient.edit(
                new EditAttributeRequest({
                    status: status,
                }),
                attribute.id,
            );

            setMessageType(MessageBarType.success);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'Attribute has been successfully deactivated.'
                    : 'Attribute has been successfully reactivated.',
            );
            onSuccess();
        } catch (e) {
            console.log(e);
            setMessageType(MessageBarType.error);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'An error occurred deactivating the attribute.'
                    : 'An error occurred reactivating the attribute.',
            );
        }
    };

    return (
        <Dialog
            hidden={false}
            onDismiss={onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title:
                    props.actionType === 'deactivate'
                        ? 'Deactivate Attribute'
                        : 'Reactivate Attribute',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {!isActionConfirmed && attribute && (
                    <div>
                        Are you sure you want to {props.actionType} the attribute:
                        <b> {attribute.name}</b>?
                    </div>
                )}
            </div>
            <DialogFooter>
                {!isActionConfirmed && (
                    <PrimaryButton
                        onClick={handleAttributeAction}
                        text={props.actionType === 'deactivate' ? 'Deactivate' : 'Reactivate'}
                    />
                )}
                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
