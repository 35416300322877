import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { Icon, mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';

/**
 * See the recommended pattern in metric-tiles.tsx.
 */

interface MetricTilesLegendProps {
    title: string;
    legend: {
        label: string;
        color: string;
    }[];
}

export default function MetricTilesLegend(props: MetricTilesLegendProps): JSX.Element {
    return (
        <Stack className={styles.top} horizontalAlign='start'>
            <div>{props.title}</div>
            <Stack.Item>
                {props.legend.map((legend) => {
                    const { label, color } = legend;
                    return (
                        <Stack
                            tokens={{ childrenGap: 10 }}
                            key={label}
                            horizontal
                            horizontalAlign='start'
                            verticalAlign='center'>
                            <Stack.Item>
                                <Icon
                                    iconName={IconNames.CircleFill}
                                    className={mergeStyles({
                                        color,
                                        width: 10,
                                        fontSize: 10,
                                    })}
                                />
                            </Stack.Item>
                            <Stack.Item className={styles.label}>{label}</Stack.Item>
                        </Stack>
                    );
                })}
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    top: {
        width: 120,
    },
    label: mergeStyles(globalStyles.smallMediumFont, globalStyles.boldFont),
});
