import { IReadinessScopeDefinition } from 'clients/readiness-client';
import React, { createContext, ReactNode, useState } from 'react';
import { SetStateFunc } from 'types/global-types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type ReadinessContextType = {
    scopes: IReadinessScopeDefinition[];
    setScopes: SetStateFunc<IReadinessScopeDefinition[]>;
};

export const ReadinessVariableContext = createContext<ReadinessContextType>(null!);

interface ReadinessContextProviderProps {
    children: ReactNode;
}

export default function ReadinessVariableContextProvider(
    props: ReadinessContextProviderProps,
): JSX.Element {
    const [scopes, setScopes] = useState<IReadinessScopeDefinition[]>([]);

    const contextValue = {
        scopes,
        setScopes,
    };
    return (
        <ReadinessVariableContext.Provider value={contextValue}>
            {props.children}
        </ReadinessVariableContext.Provider>
    );
}
