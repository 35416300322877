import {
    ActionButton,
    ColumnActionsMode,
    CommandBar,
    IButtonStyles,
    ICommandBarItemProps,
    IContextualMenuProps,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Stack,
    Toggle,
    mergeStyleSets,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import {
    CoreAttributeSetsClient,
    CorePrincipalSourceDataFieldsClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { TableCell } from 'components/common/table';
import AddEditAttributePanel, {
    AttributeModalType,
} from 'components/core/attributes/add-edit-attribute';
import AttributeFilterPanel from 'components/core/attributes/attribute-filter-panel';
import { AttributeActionModal } from 'components/core/attributes/attribute-action-modal';
import { RemoveAttributeModal } from 'components/core/attributes/remove-attribute-modal';
import { ViewAttributeRolesPanel } from 'components/core/attributes/view-attribute-roles-panel';
import {
    FilterableTable,
    IFilter,
    IFilterableColumn,
} from 'components/core/common/filterableTable';
import { LastModifiedBy, TimeUnit } from 'components/core/common/last-modified-by';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import {
    AttributeCsaSyncStatus,
    AttributeDataType,
    AttributeStatus,
    GetAttributeSetByIdResult,
    GetAttributesByAttributeSetResult,
    GetDataSourceFieldsResult,
} from 'personnel-core-clients';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getAttributeReportData } from 'utils/cor-reports';
import { excelFormatHeader } from 'utils/reporting-utils';
import { ActionNotAllowedAttributeModal } from 'components/core/attributes/action-not-allowed-attribute-modal';

export function AttributesList(): JSX.Element {
    const { attributeSetId } = useParams<{ attributeSetId: string }>();
    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);
    const principalContext = useContext(PrincipalUserContext);

    const [openModalType, setOpenModalType] = useState<AttributeModalType | undefined>();
    const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
    const [message, setMessage] = useState<string>();
    const [messageType, setMessageType] = useState<MessageBarType>();
    const [attributeSet, setAttributeSet] = useState<GetAttributeSetByIdResult>();
    const [attributes, setAttributes] = useState<GetAttributesByAttributeSetResult[]>();
    const [selectedAttribute, setSelectedAttribute] = useState<GetAttributesByAttributeSetResult>();
    const [displayedAttributes, setDisplayedAttributes] = useState<
        GetAttributesByAttributeSetResult[]
    >([]);
    const [filteredAttributes, setFilteredAttributes] = useState<
        GetAttributesByAttributeSetResult[]
    >([]);
    const [isViewRoleModalOpen, setIsViewRolesModalOpen] = useState<boolean>(false);
    const [selectedAttributeViewRoles, setSelectedAttributeViewRoles] = useState<
        GetAttributesByAttributeSetResult
    >();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<Map<string, IFilter>>(new Map<string, IFilter>());
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(false);
    const [isReactivateDialogOpen, setIsReactivateDialogOpen] = useState<boolean>(false);
    const [shouldShowReactivate, setShouldShowReactivate] = useState<boolean>(false);
    const [showDeactivatedAttributes, { toggle: toggleShowDeactivatedAttributes }] = useBoolean(
        false,
    );
    const [autoAssignedDataSourceFields, setAutoAssignedDataSourceFields] = useState<
        GetDataSourceFieldsResult | undefined
    >();

    const history = useHistory();

    const isAdmin = useMemo(() => {
        // This is temporary until we get a role for Core Admin in the portal app or figure out another way to do this
        return (
            authContext.isInRole(Role.PortalAdmin) || authContext.isInRole(Role.CoreAttributeAdmin)
        );
    }, [authContext]);

    useEffect(() => {
        if (attributes) {
            setDisplayedAttributes(
                attributes.filter((attribute) =>
                    showDeactivatedAttributes
                        ? attribute.status === AttributeStatus.Deprecated
                        : attribute.status === AttributeStatus.Active,
                ),
            );
        }
    }, [attributes, showDeactivatedAttributes]);

    const columnsFilterable: IFilterableColumn[] = useMemo(() => {
        return [
            {
                key: AttributesListColumnKeys.Attribute,
                name: columnNames.Attribute,
                fieldName: columnFieldNames.Attribute,
                ariaLabel: columnNames.Attribute,
                minWidth: columnWidths.attribute,
                maxWidth: columnWidths.attribute * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    return <TableCell className={styles.detailsListField}>{item.name}</TableCell>;
                },
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) =>
                            item.name.toLocaleLowerCase().includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: AttributesListColumnKeys.Description,
                name: columnNames.Description,
                fieldName: columnFieldNames.Description,
                ariaLabel: columnNames.Description,
                minWidth: columnWidths.description,
                maxWidth: columnWidths.description * xLargeMaxWidthCoeff,
                isResizable: true,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            {item.description}
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) =>
                            item.description.toLocaleLowerCase().includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: AttributesListColumnKeys.DataType,
                name: columnNames.DataType,
                fieldName: columnFieldNames.DataType,
                ariaLabel: columnNames.DataType,
                minWidth: columnWidths.dataType,
                maxWidth: columnWidths.dataType * xLargeMaxWidthCoeff,
                isResizable: true,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    let dataTypeLabel;

                    switch (item.dataType) {
                        case AttributeDataType.Boolean:
                            dataTypeLabel = 'True/False';
                            break;
                        case AttributeDataType.Integer:
                            dataTypeLabel = 'Numeric';
                            break;
                        case AttributeDataType.String:
                            dataTypeLabel = 'Text';
                            break;
                        case AttributeDataType.Person:
                            dataTypeLabel = 'Person';
                            break;
                        default:
                            dataTypeLabel = '';
                    }

                    return (
                        <TableCell className={styles.detailsListField}>{dataTypeLabel}</TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.dataType.toString().includes(f));
                    }
                    return true;
                },
            },
            {
                key: AttributesListColumnKeys.Owners,
                name: columnNames.Owners,
                ariaLabel: columnNames.Owners,
                minWidth: -1,
                isRowHeader: true,
                isResizable: true,
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.owners.includes(f));
                    }
                    return true;
                },
                isHidden: true,
            },
            {
                key: AttributesListColumnKeys.Managers,
                name: columnNames.Managers,
                ariaLabel: columnNames.Managers,
                minWidth: -1,
                isRowHeader: true,
                isResizable: true,
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.managers.includes(f));
                    }
                    return true;
                },
                isHidden: true,
            },
            {
                key: AttributesListColumnKeys.Readers,
                name: columnNames.Readers,
                ariaLabel: columnNames.Readers,
                minWidth: -1,
                isRowHeader: true,
                isResizable: true,
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.readers.includes(f));
                    }
                    return true;
                },
                isHidden: true,
            },
            {
                key: AttributesListColumnKeys.Values,
                name: columnNames.Values,
                ariaLabel: columnNames.Values,
                minWidth: columnWidths.values,
                maxWidth: columnWidths.values * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <Link to={`/profile/attributes/${item.id}`}>
                                <ActionButton
                                    iconProps={{ iconName: IconNames.View }}
                                    text='View'
                                />
                            </Link>
                        </TableCell>
                    );
                },
            },
            {
                key: AttributesListColumnKeys.Roles,
                name: columnNames.Roles,
                ariaLabel: columnNames.Roles,
                minWidth: columnWidths.roles,
                maxWidth: columnWidths.roles * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <ActionButton
                                onClick={(): void => {
                                    setIsViewRolesModalOpen(true);
                                    setSelectedAttributeViewRoles(item);
                                }}
                                iconProps={{ iconName: IconNames.View }}
                                text='View'
                            />
                        </TableCell>
                    );
                },
            },
            {
                key: AttributesListColumnKeys.LastModifiedBy,
                name: columnNames.LastModifiedBy,
                ariaLabel: columnNames.LastModifiedBy,
                minWidth: columnWidths.lastModifiedBy,
                maxWidth: columnWidths.lastModifiedBy * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    if (item.lastModifiedBy) {
                        return (
                            <TableCell className={styles.detailsListField}>
                                <LastModifiedBy lastModified={item} timeUnit={TimeUnit.Seconds} />
                            </TableCell>
                        );
                    } else {
                        return <></>;
                    }
                },
                isFilterable: true,
                filterFunction(item: GetAttributesByAttributeSetResult, filter: IFilter): boolean {
                    let startDate = 0;
                    let endDate = 0;
                    if (filter.values.length > 1) {
                        startDate = filter.values[0] !== '0' ? parseInt(filter.values[0]) : 0;
                        endDate =
                            filter.values[1] !== '0'
                                ? parseInt(filter.values[1])
                                : Number.MAX_SAFE_INTEGER;
                        return (
                            item.lastModifiedDate >= startDate && item.lastModifiedDate <= endDate
                        );
                    }
                    return true;
                },
            },
            {
                key: AttributesListColumnKeys.Actions,
                name: columnNames.Actions,
                ariaLabel: columnNames.Actions,
                minWidth: columnWidths.actions,
                maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAttributesByAttributeSetResult): JSX.Element => {
                    const contextualMenuItems: IContextualMenuProps = {
                        items: [
                            {
                                key: `editAttribute_${item.id}`,
                                ariaLabel: 'Edit attribute',
                                text: 'Edit attribute',
                                iconProps: { iconName: IconNames.Edit },
                                onClick: (): void => {
                                    setSelectedAttribute(item);
                                    setOpenModalType(AttributeModalType.Edit);
                                },
                                disabled: shouldDisableAction(
                                    isAdmin,
                                    attributeSet,
                                    item,
                                    principalContext.principalRecord.oid ?? '',
                                ),
                            },
                            ...(item.status === AttributeStatus.Active
                                ? [
                                      {
                                          key: `deactivateAttribute_${item.id}`,
                                          ariaLabel: 'Deactivate attribute',
                                          text: 'Deactivate attribute',
                                          iconProps: { iconName: IconNames.FabricUnsyncFolder },
                                          onClick: (): void => {
                                              setSelectedAttribute(item);
                                              setIsDeactivateDialogOpen(true);
                                          },
                                          disabled: shouldDisableAction(
                                              isAdmin,
                                              attributeSet,
                                              item,
                                              principalContext.principalRecord.oid ?? '',
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          key: `reactivateAttribute_${item.id}`,
                                          ariaLabel: 'Reactivate attribute',
                                          text: 'Reactivate attribute',
                                          iconProps: { iconName: IconNames.FabricSyncFolder },
                                          onClick: (): void => {
                                              setSelectedAttribute(item);
                                              setIsReactivateDialogOpen(true);
                                              setShouldShowReactivate(
                                                  attributeSet?.isActive || false,
                                              );
                                          },
                                          disabled: shouldDisableAction(
                                              isAdmin,
                                              attributeSet,
                                              item,
                                              principalContext.principalRecord.oid ?? '',
                                          ),
                                      },
                                  ]),
                            {
                                key: `deleteAttribute_${item.id}`,
                                ariaLabel: 'Delete attribute',
                                text: 'Delete attribute',
                                iconProps: { iconName: IconNames.Trash },
                                onClick: (): void => {
                                    setSelectedAttribute(item);
                                    setIsDeleteDialogOpen(true);
                                },
                                disabled:
                                    shouldDisableAction(
                                        isAdmin,
                                        attributeSet,
                                        item,
                                        principalContext.principalRecord.oid ?? '',
                                    ) ||
                                    !(
                                        item.csaSyncStatus.status === AttributeCsaSyncStatus.NONE ||
                                        item.csaSyncStatus.status ===
                                            AttributeCsaSyncStatus.DISABLED
                                    ), // Only allow delete if attribute is NONE or DISABLED status for CSA
                            },
                        ],
                        shouldFocusOnMount: true,
                    };
                    return (
                        <TableCell className={styles.detailsListField}>
                            <ActionButton
                                iconProps={{ iconName: 'More' }}
                                menuProps={contextualMenuItems}
                                styles={{
                                    menuIcon: {
                                        display: 'none', // Hide the menu icon
                                    },
                                }}
                            />
                        </TableCell>
                    );
                },
            },
        ];
    }, [isAdmin, attributeSet, principalContext]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Home', link: '/home' },
            { title: 'Attributes', link: '' },
            { title: 'Attribute sets', link: '/profile/attributesets' },
            { title: 'Attributes', link: '' },
        ]);

        setOpenModalType(undefined);
    }, [authContext]);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setShouldFetchData(false);

            const attributeSetClient = new CoreAttributeSetsClient(authContext);
            const principalSourceDataFieldsClient = new CorePrincipalSourceDataFieldsClient(
                authContext,
            );

            try {
                const attributeSet = await attributeSetClient.getById(attributeSetId);
                const attributes = await attributeSetClient.getAttributes(attributeSetId);
                const dataSourceFields = isAdmin
                    ? await principalSourceDataFieldsClient.getDataSourceFields(attributeSetId)
                    : undefined;

                if (attributes.length <= 0) {
                    setMessage('There are no attributes in this attribute set');
                    setMessageType(MessageBarType.info);
                }

                setAttributeSet(attributeSet);
                setAttributes(attributes);
                setAutoAssignedDataSourceFields(dataSourceFields);
            } catch (e) {
                setMessage('Error occurred loading attributes.');
                setMessageType(MessageBarType.error);
            }
        };
        if (shouldFetchData) {
            fetchData();
        }
    }, [attributeSetId, authContext, isAdmin, shouldFetchData]);

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'add',
                text: 'Add attribute',
                iconProps: { iconName: IconNames.Add },
                onClick: () => setOpenModalType(AttributeModalType.Add),
                disabled:
                    !isAdmin &&
                    (attributeSet === undefined ||
                        !attributeSet.owners.includes(principalContext.principalRecord.oid ?? '')), // Only allow add if user is an admin or attribute set owner
                leftCommandBarButtonStyle,
            },
            {
                key: 'edit',
                text: 'Edit attribute',
                onClick: () => setOpenModalType(AttributeModalType.Edit),
                iconProps: { iconName: IconNames.EditNoFill },
                disabled:
                    selectedAttribute === undefined || // Disable edit if no attribute is selected
                    (!isAdmin &&
                        (attributeSet === undefined ||
                            !attributeSet.owners.includes(
                                principalContext.principalRecord.oid ?? '',
                            )) &&
                        !selectedAttribute.owners.includes(
                            principalContext.principalRecord.oid ?? '',
                        )), // Only allow edit if user is an admin or attribute/set owner
                leftCommandBarButtonStyle,
            },
            {
                key: 'deactivate',
                text: 'Deactivate attribute',
                iconProps: { iconName: IconNames.FabricUnsyncFolder },
                onClick: (): void => {
                    setIsDeactivateDialogOpen(true);
                },
                disabled:
                    selectedAttribute === undefined || // Disable edit if no attribute is selected
                    (!isAdmin &&
                        (attributeSet === undefined ||
                            !attributeSet.owners.includes(
                                principalContext.principalRecord.oid ?? '',
                            )) &&
                        !selectedAttribute.owners.includes(
                            principalContext.principalRecord.oid ?? '',
                        )), // Only allow edit if user is an admin or attribute/set owner
                leftCommandBarButtonStyle,
            },
            {
                key: 'delete',
                text: 'Delete attribute',
                iconProps: { iconName: IconNames.Trash },
                onClick: (): void => {
                    setIsDeleteDialogOpen(true);
                },
                disabled:
                    selectedAttribute === undefined || // Disable edit if no attribute is selected
                    (!isAdmin &&
                        (attributeSet === undefined ||
                            !attributeSet.owners.includes(
                                principalContext.principalRecord.oid ?? '',
                            )) &&
                        !selectedAttribute.owners.includes(
                            principalContext.principalRecord.oid ?? '',
                        )) || // Only allow edit if user is an admin or attribute/set owner
                    !(
                        selectedAttribute.csaSyncStatus.status === AttributeCsaSyncStatus.NONE ||
                        selectedAttribute.csaSyncStatus.status === AttributeCsaSyncStatus.DISABLED
                    ), // Only allow delete if attribute is NONE or DISABLED status for CSA
            },
        ];
    }, [attributeSet, isAdmin, selectedAttribute]);

    const refreshAttributePage = (): void => {
        setMessage(undefined);
        setMessageType(undefined);
        setOpenModalType(undefined);
        setShouldFetchData(true);
    };

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        const commandBarRightItems: ICommandBarItemProps[] = [];

        const hasInactiveAndAuthorized =
            attributes !== undefined &&
            attributes.some(
                (item) =>
                    item.status === AttributeStatus.Deprecated &&
                    (isAdmin ||
                        item.owners.includes(principalContext.principalRecord.oid ?? '') ||
                        item.managers.includes(principalContext.principalRecord.oid ?? '') ||
                        item.readers.includes(principalContext.principalRecord.oid ?? '')),
            );

        if (hasInactiveAndAuthorized) {
            commandBarRightItems.push({
                key: 'deactivatedAttributes',
                commandBarButtonAs: () => (
                    <div style={{ marginTop: '6px' }}>
                        <Toggle
                            label='View deactivated attributes'
                            inlineLabel
                            onText='Yes'
                            offText='No'
                            checked={showDeactivatedAttributes}
                            onChange={(): void => toggleShowDeactivatedAttributes()}
                        />
                    </div>
                ),
            });
        }

        commandBarRightItems.push({
            key: 'filter',
            text: 'Filter',
            iconProps: { iconName: IconNames.Filter },
            onClick: () => setIsFilterOpen(true),
        });

        commandBarRightItems.push({
            key: 'export',
            commandBarButtonAs: () => (
                <div style={{ marginTop: '2px' }}>
                    <ExportToExcelButton
                        disabled={filteredAttributes.length <= 0 || attributeSet === undefined}
                        buttonTitle='Download report'
                        buttonType='action'
                        getData={async () => {
                            return await getAttributeReportData(
                                authContext,
                                attributeSet!,
                                filteredAttributes,
                            );
                        }}
                        fileNamePrefix='attributes'
                        formatHeader={true}
                        formatType={excelFormatHeader.microsoftBrandCase}
                    />
                </div>
            ),
        });

        return commandBarRightItems;
    }, [attributes, filteredAttributes, isAdmin, showDeactivatedAttributes]);

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />

            <Stack
                horizontal
                tokens={{
                    childrenGap: 8,
                }}
                styles={{ root: { marginTop: '25px', marginBottom: '15px' } }}>
                <ActionButton
                    iconProps={{ iconName: IconNames.Back }}
                    styles={{ root: { height: 'auto' } }}
                    onClick={(): void => history.push(`/profile/attributesets`)}
                    ariaLabel='Back to Attribute Sets'
                />
                <h1 // Needs to he a header label for a11y
                    style={{
                        fontSize: '28px',
                        fontWeight: '600',
                        marginTop: '0px',
                        marginBottom: '0px',
                    }}>
                    {attributeSet?.name ?? 'Loading...'}
                </h1>
            </Stack>
            {message !== undefined && messageType !== undefined && (
                <MessageBar messageBarType={messageType}>{message}</MessageBar>
            )}
            <CommandBar
                styles={{
                    root: {
                        paddingLeft: 55,
                        background: globalStyles.backgroundColor,
                    },
                }}
                items={commandBarLeftItems}
                farItems={commandBarRightItems}
            />
            {attributeSet && (
                <FilterableTable
                    filters={filters}
                    isLoading={attributes === undefined}
                    columns={columnsFilterable}
                    items={displayedAttributes}
                    onSelectionChange={(selected): void =>
                        setSelectedAttribute(selected.length > 0 ? selected[0] : undefined)
                    }
                    onFilteredItemsChange={setFilteredAttributes}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick={true}
                    className={styles.detailsList}
                />
            )}
            <AddEditAttributePanel
                isOpen={openModalType !== undefined}
                modalType={openModalType ?? AttributeModalType.Add}
                onDismiss={(): void => setOpenModalType(undefined)}
                onFinish={refreshAttributePage}
                attributeSet={attributeSet}
                initAttribute={
                    openModalType === AttributeModalType.Edit ? selectedAttribute : undefined
                }
                initAttributes={attributes}
                autoAssignedDataSourceFields={autoAssignedDataSourceFields}
                isAdmin={isAdmin}
            />
            <ViewAttributeRolesPanel
                isOpen={isViewRoleModalOpen}
                initAttribute={selectedAttributeViewRoles}
                initAttributeSet={attributeSet}
                onDismiss={(): void => setIsViewRolesModalOpen(false)}
                userOid={principalContext.principalRecord.oid ?? ''}
            />
            {isDeleteDialogOpen && (
                <RemoveAttributeModal
                    attribute={selectedAttribute}
                    onDismissed={(): void => {
                        setIsDeleteDialogOpen(false);
                    }}
                    onSuccess={(): void => refreshAttributePage()}
                />
            )}
            <AttributeFilterPanel
                isOpen={isFilterOpen}
                onDismiss={(): void => {
                    setIsFilterOpen(false);
                }}
                onSuccess={(filters): void => {
                    setFilters(filters);
                    setIsFilterOpen(false);
                }}
                onClearFilters={(): void => {
                    setFilters(new Map<string, IFilter>());
                    refreshAttributePage();
                }}
                onMinimizePanel={(): void => {
                    setIsFilterOpen(false);
                }}
            />
            {isReactivateDialogOpen &&
                selectedAttribute &&
                (shouldShowReactivate ? (
                    <AttributeActionModal
                        attribute={selectedAttribute}
                        onDismissed={() => setIsReactivateDialogOpen(false)}
                        onSuccess={refreshAttributePage}
                        actionType={'reactivate'}
                    />
                ) : (
                    <ActionNotAllowedAttributeModal
                        title='Reactivate Attribute'
                        message='In order to re-activate attributes under a deactivated set a user must reactivate the set first'
                        onDismissed={() => setIsReactivateDialogOpen(false)}
                    />
                ))}
            {isDeactivateDialogOpen && (
                <AttributeActionModal
                    attribute={selectedAttribute}
                    actionType={'deactivate'}
                    onDismissed={(): void => {
                        setIsDeactivateDialogOpen(false);
                    }}
                    onSuccess={(): void => refreshAttributePage()}
                />
            )}
        </div>
    );
}

function shouldDisableAction(
    isAdmin: boolean,
    attributeSet: GetAttributeSetByIdResult | undefined,
    attribute: GetAttributesByAttributeSetResult,
    oid: string,
): boolean {
    return (
        !isAdmin &&
        (attributeSet === undefined || !attributeSet.owners.includes(oid)) &&
        !attribute.owners.includes(oid) // user is not an admin or owner of the attribute set and attribute
    );
}

const styles = mergeStyleSets({
    detailsList: {
        '& .ms-Check-checkHost': {
            height: '100%',
        },
        '& .ms-HoverCard-host': {
            ':focus-visible': {
                outline: '2px solid black',
            },
        },
    },
    detailsListField: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: '100%',
        lineHeight: '1.0',
    },
    coreEmployeeHoverCardDiv: {
        display: 'flex',
    },
});

const columnWidths = {
    attribute: 100,
    description: 300,
    dataType: 75,
    values: 75,
    roles: 75,
    lastModifiedBy: 200,
    actions: 75,
};

// filterableTable uses "key" to filter
export enum AttributesListColumnKeys {
    Attribute = 'attribute',
    Description = 'description',
    DataType = 'dataType',
    Owners = 'owners',
    Managers = 'managers',
    Readers = 'readers',
    Values = 'values',
    Roles = 'roles',
    LastModifiedBy = 'lastmodified',
    Actions = 'actions',
}

// filterableTable uses "name" as the table header text
enum columnNames {
    Attribute = 'Attribute',
    Description = 'Description',
    DataType = 'Data type',
    Owners = 'Owners',
    Managers = 'Managers',
    Readers = 'Readers',
    Values = 'Values',
    Roles = 'Roles',
    LastModifiedBy = 'Last modified by',
    Actions = 'Actions',
}

// filterableTable uses "fieldName" to sort
enum columnFieldNames {
    Attribute = 'name',
    Description = 'description',
    DataType = 'dataType',
}

const leftCommandBarButtonStyle: IButtonStyles = {
    root: { marginRight: '15px' },
};
