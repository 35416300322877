import {
    ActionButton,
    Checkbox,
    DatePicker,
    mergeStyleSets,
    Separator,
    Stack,
} from '@fluentui/react';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import {
    globalCheckboxStyles,
    globalFilterSeparatorStyles,
    globalStyles,
} from 'assets/styles/global-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, {
    CompliantStatus,
    GroupMemberReport,
    GroupMemberReportExternal,
    GroupMemberStatus,
    GroupRole,
    IGroup,
    IGroupMembership,
} from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { CoreSinglePrincipalIdPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { ProblemLoadingData } from 'components/common/problem-loading/problem-loading-data';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import Spacer from 'components/common/spacer';
import StatTile from 'components/common/stat-tiles/stat-tile';
import { Table } from 'components/common/table';
import { staticStatTileWrapperGroups } from 'components/groups/common/common-styles-groups';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { displayWarningIfFewOwners } from 'components/groups/manage-group/manage-group-utils';
import ManageGroupMembersAddNewMemberModalActionButton from 'components/groups/manage-group/members/buttons/manage-group-members-add-new-member-modal-action-button';
import manageGroupsTableColumns from 'components/groups/manage-group/members/manage-groups-table-columns';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFetchSimple, useToggle } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { useSortColumnHandler } from 'utils/sort-utils';
import { toTitleCase } from 'utils/string-utils';
import { TimeFormats } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IGroupMemberProps {
    group: IGroup;
    groupId: string;
    requireSponsor: boolean;
    onGroupMemberDeleted: (personnelId: string) => void;
}

const initialFilterState: GroupMemberStatus[] = [
    GroupMemberStatus.APPROVED,
    GroupMemberStatus.QUARANTINED,
];

enum ConditionalFilters {
    GracePeriod = 'Grace Period',
    AllowList = 'Allow List',
}

export enum EmployeeOrSponsor {
    Sponsor = 'sponsor',
    Employee = 'employee',
}

const roleFiltersCategories = [
    { 'name': 'Member', 'category': GroupRole.MEMBER },
    {
        'name': 'Owner',
        'category': GroupRole.OWNER,
    },
    {
        'name': 'Auditor',
        'category': GroupRole.AUDITOR,
    },
    {
        'name': 'Manager',
        'category': GroupRole.MANAGER,
    },
];

export enum GroupTableColumnKeys {
    Name = 'Name',
    Role = 'Role',
    Status = 'Status',
    AddedBy = 'Added by',
    DateAdded = 'Date Added',
    Actions = 'Actions',
}

function ManageGroupMembers(props: IGroupMemberProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const isAuditor = groupContext.isAuditor();

    const [continuationToken, setContinuationToken] = useState<string | undefined>();
    const [errorFetchingMembers, setErrorFetchingMembers] = useState<string>('');
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isToggleFetch, toggleFetch] = useToggle(false);
    const [isInGracePeriod, setIsInGracePeriod] = useState<boolean | null>(null);
    const [isInAllowList, setIsInAllowList] = useState<boolean | null>(null);
    const [isToggleCompliantChecked, toggleCompliantChecked] = useToggle(false);
    const [isToggleQuarantinedChecked, toggleQuarantinedChecked] = useToggle(false);
    const [members, setMembers] = useState<IGroupMembership[]>([]);
    const [tempMembers, setTempMembers] = useState<IGroupMembership[] | null>(null);
    const [roleFilters, setRoleFilters] = useState<GroupRole[]>([]);
    const [conditionalFilterState, setConditionalFilterState] = useState(initialFilterState);
    const [fromDateAdded, setFromDateAdded] = useState<Date | undefined>(undefined);

    // The source of truth for members in this component is fetchedMembers
    // all filtering is done via setMembers as to not mutate the fetchedMembers data
    const [fetchedMembers, setFetchedMembers] = useState<IGroupMembership[]>([]);
    const [principal, setPrincipal] = useState<IPrincipalRecord>();
    const [addedBy, setAddedBy] = useState<IPrincipalRecord>();

    const isMin2FTEOwnersFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;
    const isInternal = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents).enabled;

    const statusFilters = [
        {
            name: CompliantStatus.COMPLIANT,
            category: GroupMemberStatus.APPROVED,
            toggled: isToggleCompliantChecked,
        },
        {
            name: CompliantStatus.NOT_COMPLIANT,
            category: GroupMemberStatus.QUARANTINED,
            toggled: isToggleQuarantinedChecked,
        },
    ];

    const conditionalFilters = [
        {
            name: ConditionalFilters.GracePeriod,
            toggled: isInGracePeriod,
        },
        {
            name: ConditionalFilters.AllowList,
            toggled: isInAllowList,
        },
    ];

    const onPrincipalSelect = (info?: IPrincipalRecord): void => {
        setPrincipal(info);
        if (info) {
            searchGroupMembers(info, EmployeeOrSponsor.Employee);
        }
    };

    const onAddedBySelect = (info?: IPrincipalRecord): void => {
        setAddedBy(info);
        if (info) {
            searchGroupMembers(info, 'addedBy');
        } else {
            setMembers(fetchedMembers);
        }
    };

    const searchGroupMembers = async (
        principal: IPrincipalRecord,
        memberNameOrAddedBy: string,
    ): Promise<void> => {
        try {
            const statuses: GroupMemberStatus[] = [
                GroupMemberStatus.APPROVED,
                GroupMemberStatus.QUARANTINED,
            ];

            const foundMember = members.filter((member) => {
                return memberNameOrAddedBy === EmployeeOrSponsor.Employee
                    ? member.personnelId === principal.id
                    : member.addedBy === principal.id;
            });
            setMembers(foundMember);
        } catch (error) {
            setMembers([]);
            console.error(error);
        }
    };

    const { isFetching: isFetchingMembers } = useFetchSimple<IPagedResults<IGroupMembership>>({
        dependencies: [isToggleFetch, conditionalFilterState, isInAllowList],
        canPerformFetch: true,
        fetchFunc: async () =>
            GroupClient.getGroupMembers(
                authContext,
                props.groupId,
                continuationToken,
                conditionalFilterState,
                isInGracePeriod,
                isInAllowList,
            ),
        onSuccess: (result) => {
            setIsInitialLoad(false);
            if (!continuationToken) {
                setFetchedMembers(result.results);
            } else {
                setFetchedMembers((currentValue) => [...currentValue].concat(result.results));
            }
            setContinuationToken(result.continuationToken);
        },
        onError: () => {
            setErrorFetchingMembers('Error loading members');
        },
        onFinally: doNothing,
    });

    const onGroupMemberDeleted = (personnelId: string): void => {
        setMembers((currentValue) =>
            currentValue.filter((member) => member.personnelId !== personnelId),
        );
        props.onGroupMemberDeleted(personnelId);
    };

    const onUpdateMemberDetails = async (personnelId: string): Promise<void> => {
        try {
            const memberResponse = await GroupClient.searchGroupMembers(
                authContext,
                groupId,
                personnelId,
            );
            const membersCopy = [...members];
            const staleMemberDataIndex = membersCopy.findIndex(
                (m) => m.personnelId === personnelId,
            );
            membersCopy.splice(staleMemberDataIndex, 1, memberResponse);
            setTempMembers(membersCopy);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (tempMembers !== null) {
            setMembers(tempMembers);
        }
        return (): void => {
            setTempMembers(null);
        };
    }, [tempMembers]);

    const [{ sortColumn }, sortColumnHandler] = useSortColumnHandler(GroupTableColumnKeys.Name, 1);
    const groupId = props.groupId;

    // This func checks member values and simply passes that value
    // to the table to accomodate for number of badges shown to user
    // it does not mutate data
    const foundStatusNumber = (): number => {
        let number = 1;
        const foundInAllowList = members.find((m) => m.inAllowList);
        const foundOnGracePeriod = members.find((m) => m.hasViolationOnGracePeriod);
        if (members.length) {
            if (foundInAllowList) {
                number++;
            }
            if (foundOnGracePeriod) {
                number++;
            }
        }
        return number;
    };

    const tableColumns = manageGroupsTableColumns({
        enableOwnerDemote: groupContext.isEnableOwnerDemote,
        sortColumnHandler,
        sortColumn,
        onGroupMemberDeleted,
        onUpdateMemberDetails,
        foundStatusNumber,
        isAuditor: isAuditor && !authContext.isInRole(Role.GroupAdmin),
        isDynamicGroup: groupContext.group?.enableDynamic ?? false,
    });

    const handleFilterStatus = (filterName: GroupMemberStatus | ConditionalFilters): void => {
        switch (filterName) {
            case GroupMemberStatus.APPROVED:
                toggleCompliantChecked();
                break;
            case GroupMemberStatus.QUARANTINED:
                toggleQuarantinedChecked();
                break;
            case ConditionalFilters.GracePeriod:
                isInGracePeriod ? setIsInGracePeriod(null) : setIsInGracePeriod(true);
                break;
            case ConditionalFilters.AllowList:
                isInAllowList ? setIsInAllowList(null) : setIsInAllowList(true);
                break;
            default:
                break;
        }
    };

    const handleFilterRole = (role: GroupRole, checked: boolean | undefined): void => {
        if (checked) {
            setRoleFilters([...roleFilters, role]);
        } else {
            const updatedRoles = roleFilters.filter((items) => role !== items);
            setRoleFilters(updatedRoles);
        }
    };

    const returnDateString = (date: IGroupMembership): number => {
        const mytimeStamp = date.addedTimestampUTC * 1000;
        const dateObj = new Date(mytimeStamp);
        const zeroedOut = dateObj.setHours(0, 0, 0, 0);

        return zeroedOut;
    };

    const handleDateAdded = (date: Date | null | undefined): void => {
        if (date) {
            setFromDateAdded(date);
            const memberDates = members.filter((m) => {
                return returnDateString(m) === date.getTime();
            });
            setMembers(memberDates);
        }
    };

    const getReviewReport = async (): Promise<GroupMemberReport[] | []> => {
        try {
            const report = await GroupClient.downloadGroupMembersReport(authContext, props.groupId);
            const csv = reportToCsv(report);
            return csv;
        } catch (e) {
            console.error('Error generating report ', e);
            return [];
        }
    };

    const getReviewReportExternal = async (): Promise<GroupMemberReportExternal[] | []> => {
        try {
            const report = await GroupClient.downloadGroupMembersReport(authContext, props.groupId);
            const csv = reportToCsvExternal(report);
            return csv;
        } catch (e) {
            console.error('Error generating report ', e);
            return [];
        }
    };

    const reportToCsv = (rows: GroupMemberReport[]): GroupMemberReport[] => {
        const returnResult = rows.map((row) => ({
            alias: row.alias,
            personnelId: row.personnelId,
            role: row.role,
            status: row.status,
            compliant: row.compliant,
            inGracePeriod: row.inGracePeriod,
            inAllowList: row.inAllowList,
            firstName: row.firstName,
            middleName: row.middleName,
            lastName: row.lastName,
            fullName: row.fullName,
            hierarchyLvl2: row.hierarchyLvl2,
            hierarchyLvl3: row.hierarchyLvl3,
            hierarchyLvl4: row.hierarchyLvl4,
            hierarchyLvl5: row.hierarchyLvl5,
            justification: row.justification,
            sponsorId: row.sponsorId,
            sponsorAlias: row.sponsorAlias,
            addedBy: row.addedBy,
            addedByType: row.addedByType,
            costCenterCode: row.costCenterCode,
            addedTimestampPST: row.addedTimestampPST
                ? moment(row.addedTimestampPST).format(TimeFormats.MDYYYY_24hour)
                : '',
            requestedType: row.requestedType,
            requestedBy: row.requestedBy,
            requestedTimestampPST: row.requestedTimestampPST,
        }));
        return returnResult;
    };

    const reportToCsvExternal = (
        rows: GroupMemberReportExternal[],
    ): GroupMemberReportExternal[] => {
        const returnResult = rows.map((row) => ({
            alias: row.alias,
            personnelId: row.personnelId,
            role: row.role,
            status: row.status,
            compliant: row.compliant,
            inGracePeriod: row.inGracePeriod,
            inAllowList: row.inAllowList,
            firstName: row.firstName,
            middleName: row.middleName,
            lastName: row.lastName,
            fullName: row.fullName,
            justification: row.justification,
            sponsorId: row.sponsorId,
            sponsorAlias: row.sponsorAlias,
            addedBy: row.addedBy,
            addedByType: row.addedByType,
            addedTimestampPST: row.addedTimestampPST
                ? moment(row.addedTimestampPST).format(TimeFormats.MDYYYY_24hour)
                : '',
            requestedType: row.requestedType,
            requestedBy: row.requestedBy,
            requestedTimestampPST: row.requestedTimestampPST,
        }));
        return returnResult;
    };

    const handleClearFilters = (): void => {
        if (isToggleCompliantChecked) {
            toggleCompliantChecked();
        }
        if (isToggleQuarantinedChecked) {
            toggleQuarantinedChecked();
        }
        if (isInGracePeriod) {
            setIsInGracePeriod(null);
        }
        if (isInAllowList) {
            setIsInAllowList(null);
        }
        if (fromDateAdded) {
            setFromDateAdded(undefined);
        }
        setRoleFilters([]);
    };

    useEffect(() => {
        if (!!continuationToken && !isFetchingMembers) {
            toggleFetch();
        }
    }, [continuationToken, isFetchingMembers]);

    useEffect(() => {
        if (roleFilters.length > 0) {
            let filteredMembers: IGroupMembership[] = [];
            const filterList = roleFiltersCategories.map((i) => i.category);

            filterList.forEach((filter) => {
                if (roleFilters.includes(filter)) {
                    const memberEmployees = fetchedMembers.filter((i) => i.role === filter);
                    filteredMembers = [...filteredMembers, ...memberEmployees];
                }
            });

            setMembers(filteredMembers);
        } else {
            setMembers(fetchedMembers);
        }
    }, [roleFilters, fetchedMembers]);

    useEffect(() => {
        if ((isToggleCompliantChecked && !isToggleQuarantinedChecked) || isInGracePeriod) {
            setConditionalFilterState([GroupMemberStatus.APPROVED]);
        } else if (!isToggleCompliantChecked && isToggleQuarantinedChecked) {
            setConditionalFilterState([GroupMemberStatus.QUARANTINED]);
        } else if (isInGracePeriod && isToggleCompliantChecked) {
            setConditionalFilterState(initialFilterState);
        } else {
            setConditionalFilterState(initialFilterState);
        }
    }, [isToggleQuarantinedChecked, isToggleCompliantChecked, isInGracePeriod]);

    useEffect(() => {
        if (principal === undefined) {
            setMembers(fetchedMembers);
        }
    }, [fetchedMembers, principal]);

    return (
        <>
            <ProblemLoadingData isProblemLoadingData={!!errorFetchingMembers}>
                <div className={staticStatTileWrapperGroups}>
                    <StatTile
                        title={props.group.metrics?.memberCount.toString()}
                        text='Members'
                        background={BadgeColorHex.GRAY}
                        ariaLabel={`${props.group.metrics?.memberCount.toString()} Total Members`}
                        role='img'
                        disable={true}
                    />
                    <StatTile
                        title={(
                            (props.group.metrics?.memberCount ?? 0) -
                            (props.group.metrics?.violationCount ?? 0) -
                            (props.group.metrics?.onGracePeriodCount ?? 0)
                        ).toString()}
                        text='Compliant'
                        background={BadgeColorHex.GREEN}
                        ariaLabel={`${(
                            (props.group.metrics?.memberCount ?? 0) -
                            (props.group.metrics?.violationCount ?? 0) -
                            (props.group.metrics?.onGracePeriodCount ?? 0)
                        ).toString()} Compliant Members`}
                        role='img'
                        disable={true}
                    />
                    <StatTile
                        title={props.group.metrics?.violationCount.toString()}
                        text='Not Compliant'
                        background={BadgeColorHex.RED}
                        ariaLabel={`${props.group.metrics?.violationCount.toString()} Not Compliant Members`}
                        role='img'
                        disable={true}
                    />
                    <StatTile
                        title={props.group.metrics?.onGracePeriodCount.toString()}
                        text={ConditionalFilters.GracePeriod}
                        background={BadgeColorHex.YELLOW}
                        ariaLabel={`${props.group.metrics?.onGracePeriodCount.toString()} Grace Period Members`}
                        role='img'
                        disable={true}
                    />
                </div>

                <SidebarAndContents>
                    <SidebarPane>
                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Employee
                        </Separator>
                        <CoreSinglePrincipalIdPickerTypeaheadSearch
                            placeHolder='Employee Name or Alias'
                            onChange={(item, itemAlt): void => {
                                onPrincipalSelect(itemAlt);
                            }}
                            selectedItem={principal?.id}
                            ariaLabel='Employee'
                        />
                        <Spacer marginTop={6} />
                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Added By
                        </Separator>
                        <CoreSinglePrincipalIdPickerTypeaheadSearch
                            placeHolder='Employee Name or Alias'
                            onChange={(item, itemAlt): void => {
                                onAddedBySelect(itemAlt);
                            }}
                            selectedItem={addedBy?.id}
                            ariaLabel='Added By'
                        />
                        <Spacer marginTop={6} />
                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Status
                        </Separator>
                        <div role='group' aria-label='Status & Conditional Group'>
                            {statusFilters.map((filter) => (
                                <Checkbox
                                    styles={globalCheckboxStyles}
                                    key={filter.name}
                                    disabled={isFetchingMembers}
                                    label={toTitleCase(filter.name)}
                                    checked={filter.toggled}
                                    onChange={(): void => {
                                        handleFilterStatus(filter.category);
                                    }}
                                />
                            ))}

                            {conditionalFilters.map((filter) => (
                                <Checkbox
                                    key={filter.name}
                                    styles={globalCheckboxStyles}
                                    disabled={isFetchingMembers}
                                    label={filter.name}
                                    checked={filter.toggled === true}
                                    onChange={(): void => {
                                        handleFilterStatus(filter.name);
                                    }}
                                />
                            ))}
                        </div>

                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Role
                        </Separator>
                        <div role='group' aria-label='Role Group'>
                            {roleFiltersCategories.map((role) => (
                                <Checkbox
                                    key={role.category.toString()}
                                    styles={globalCheckboxStyles}
                                    checked={roleFilters.includes(role.category)}
                                    disabled={isFetchingMembers}
                                    label={role.name}
                                    onChange={(ev, checked): void => {
                                        handleFilterRole(role.category, checked);
                                    }}
                                />
                            ))}
                        </div>
                        <Spacer marginTop={6} />
                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Date Added
                        </Separator>
                        <DatePicker
                            placeholder='Select a date'
                            value={fromDateAdded}
                            onSelectDate={handleDateAdded}
                            ariaLabel='Date Added'
                        />

                        <div className={styles.clearFilters}>
                            <ActionButton
                                iconProps={{ iconName: IconNames.ClearFilter }}
                                onClick={handleClearFilters}
                                ariaLabel='Clear filters'>
                                <span>Clear filters</span>
                            </ActionButton>
                        </div>
                    </SidebarPane>
                    <ContentPane>
                        {displayWarningIfFewOwners(
                            groupContext.groupOwners,
                            groupContext.ownerIsFTE,
                            groupContext.isGroupOwnersObtained,
                            isMin2FTEOwnersFeatureFlagEnabled,
                        )}
                        <HorizontalBar>
                            <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                                <h1
                                    className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                                    Members
                                </h1>
                            </Stack.Item>
                            <Stack.Item>
                                <ManageGroupMembersAddNewMemberModalActionButton
                                    requireSponsor={props.requireSponsor}
                                    requireJustification={props.group.requireJustification}
                                    onAddMemberToTable={toggleFetch}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                {isInternal ? (
                                    <ExportToExcelButton<GroupMemberReport>
                                        getData={getReviewReport}
                                        fileNamePrefix={'group-members-' + props.group.id}
                                        buttonTitle='Download report'
                                    />
                                ) : (
                                    <ExportToExcelButton<GroupMemberReportExternal>
                                        getData={getReviewReportExternal}
                                        fileNamePrefix={'group-members-' + props.group.id}
                                        buttonTitle='Download report'
                                    />
                                )}
                            </Stack.Item>
                        </HorizontalBar>
                        <IsLoadingIndicator
                            msg='Loading Members'
                            before={<Spacer marginTop={10} />}
                            after={<Spacer marginTop={10} />}
                            isLoading={isFetchingMembers}
                        />
                        {!isFetchingMembers && (
                            <Table
                                tableColumns={tableColumns}
                                rows={members ?? []}
                                isFetchingData={false}
                                tableName='Members'
                                shimmerLabel={isInitialLoad ? 'Loading members...' : ''}
                                shimmerLines={5}
                                noDataText={
                                    isFetchingMembers || fetchedMembers.length > 0
                                        ? ''
                                        : 'There are currently no members assigned to this group.'
                                }
                                rootStyle={styles.detailsList}
                            />
                        )}
                    </ContentPane>
                </SidebarAndContents>
            </ProblemLoadingData>
        </>
    );
}

export default ManageGroupMembers;

const styles = mergeStyleSets({
    clearFilters: {
        marginTop: '10px',
        textAlign: 'right',
    },
    noResultsFound: {
        paddingLeft: 12,
    },
    detailsList: {
        '& .ms-Check-checkHost': {
            height: '100%',
        },
        '& .ms-HoverCard-host': {
            ':focus-visible': {
                outline: '2px solid black',
            },
        },
    },
});
