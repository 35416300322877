import {
    ActionButton,
    ColumnActionsMode,
    CommandBar,
    FontSizes,
    IColumn,
    ICommandBarItemProps,
    IContextualMenuProps,
    IIconProps,
    IconButton,
    Label,
    SelectionMode,
    Stack,
    Toggle,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import {
    CoreAttributeSetsClient,
    CoreAttributesClient,
    CorePrincipalsClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { TableCell } from 'components/common/table';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { AssignmentValueTableCell } from 'components/core/attributes/assignment-value-table-cell';
import AddEditCoreAssignmentPanelActionButton, {
    AssignmentModalState,
    AssignmentModalType,
} from 'components/core/common/add-edit-assignment';
import CoreEmployeeCardFromPrincipalRecord from 'components/core/common/employee-card/core-employee-card-from-principal-record';
import {
    FilterableTable,
    IFilter,
    IFilterableColumn,
} from 'components/core/common/filterableTable';
import { LastModifiedBy, TimeUnit } from 'components/core/common/last-modified-by';
import { RemoveAssignmentModal } from 'components/core/users/remove-assignment-modal';
import { UpdateCsaAadStatusModal } from 'components/core/users/update-csa-sync-modal';
import UserAssignmentsFilterPanel from 'components/core/users/user-assignments-filter-panel';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import {
    AttributeStatus,
    CsaAssignmentSyncStatusType,
    GetAllAttributeSetsResult,
    GetAttributeResult,
    GetPrincipalAssignmentsResult,
    GetPrincipalByIdResult,
} from 'personnel-core-clients';
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getUserAssignmentsReportData } from 'utils/cor-reports';
import { excelFormatHeader } from 'utils/reporting-utils';
import {
    MAX_DATE_IN_UNIX_SECONDS,
    TimeFormats,
    getLocalTimeZone,
    timeToString,
    timeZoneAbbr,
} from 'utils/time-utils';

export function expiresOnTableCell(
    expirationValue: number | undefined,
    style: CSSProperties | undefined,
): JSX.Element {
    let formattedExpirationDate = 'N/A';
    if (expirationValue && expirationValue > 0 && expirationValue < MAX_DATE_IN_UNIX_SECONDS) {
        const expirationDate = new Date(expirationValue * 1000);
        formattedExpirationDate = timeToString(expirationDate, TimeFormats.MMMDDYYYY_hmmA);
    }
    return (
        <>
            <TableCell style={style}>
                <span>{formattedExpirationDate}</span>
            </TableCell>
        </>
    );
}

export function UserAssignmentsPage(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const routerHistory = useHistory();
    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);

    const [isUpdateCsaAadDialogOpen, setIsUpdateCsaAadDialogOpen] = useState<boolean>(false);
    const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
    const [assignments, setAssignments] = useState<GetPrincipalAssignmentsResult[]>([]);
    const [filteredAssignments, setFilteredAssignments] = useState<GetPrincipalAssignmentsResult[]>(
        [],
    );
    const [isRemoveAssignmentDialogueOpen, setIsRemoveAssignmentDialogueOpen] = useState<boolean>(
        false,
    );
    const [selectedItem, setSelectedItem] = useState<GetPrincipalAssignmentsResult>();
    const [principalRecord, setPrincipalRecord] = useState<GetPrincipalByIdResult>();
    const [principalCsaStatuses, setPrincipalCsaStatuses] = useState<{
        [index: string]: string;
    }>({});
    const [filters, setFilters] = useState<Map<string, IFilter>>(new Map<string, IFilter>());
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [openModalState, setOpenModalState] = useState<AssignmentModalState | undefined>();

    const [editableAssignment, setEditableAssignment] = useState<
        GetPrincipalAssignmentsResult | undefined
    >();
    const [attributes, setAttributes] = useState<GetAttributeResult[]>([]);
    const [attributeSets, setAttributeSets] = useState<GetAllAttributeSetsResult[]>([]);

    const isAdmin = useMemo(() => {
        // This is temporary until we get a role for Core Admin in the portal app or figure out another way to do this
        return (
            authContext.isInRole(Role.PortalAdmin) || authContext.isInRole(Role.CoreAttributeAdmin)
        );
    }, [authContext]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Home', link: '/home' },
            { title: 'Attributes', link: '' },
            { title: 'User Assignments', link: '/profile/attributes/user/search' },
            { title: 'Details', link: '' },
        ]);
    }, []);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setShouldFetchData(false);
            setIsLoading(true);
            const principalClient = new CorePrincipalsClient(authContext);

            const assignmentsResponse = await principalClient.getAssignments(id);
            const principalResponse = await principalClient.getById(id);

            // Map assignments to new array with values all set as upper case strings.
            // Set description to upper case if it exists.
            // Default Sort by attribute set name
            const assignmentsWithStringValues = assignmentsResponse.map((assignment) => {
                return {
                    ...assignment,
                    value: assignment.value ? assignment.value.toString().toUpperCase() : '',
                    attributeDescription: assignment.attributeDescription?.toUpperCase() ?? '',
                };
            });
            setAssignments(assignmentsWithStringValues as GetPrincipalAssignmentsResult[]);
            setPrincipalRecord(principalResponse);

            const principalCsaStatuses = await principalClient.getCsaStatus(id);
            const updatedCsaStatuses: Record<string, string> = {};
            principalCsaStatuses.forEach((response, index) => {
                const csaStatus = principalCsaStatuses[index];
                if (csaStatus !== undefined) {
                    updatedCsaStatuses[csaStatus.attributeId] =
                        csaStatus.details && csaStatus.details !== undefined
                            ? csaStatus.details
                            : csaStatus.status;
                }
            });

            setPrincipalCsaStatuses((previousStatuses) => ({
                ...previousStatuses,
                ...updatedCsaStatuses,
            }));

            const attributeSetsClient = new CoreAttributeSetsClient(authContext);
            const attributeSets = await attributeSetsClient.getAll();
            setAttributeSets(attributeSets.filter((a) => a.isActive === true));

            const attributesClient = new CoreAttributesClient(authContext);
            let continuationToken = '';
            let attributes: GetAttributeResult[] = [];

            do {
                const attributesResponse = await attributesClient.getAll(100, continuationToken);
                if (attributesResponse.results && attributesResponse.results.length > 0) {
                    attributes = attributes.concat(
                        attributesResponse.results.filter(
                            (a) => a.status === AttributeStatus.Active,
                        ),
                    );
                }
                continuationToken = attributesResponse.continuationToken ?? '';
            } while (continuationToken);

            setAttributes(attributes);

            setIsLoading(false);
        };
        if (shouldFetchData) {
            fetchData();
        }
    }, [shouldFetchData]);

    const columnsFilterable: IFilterableColumn[] = useMemo(() => {
        return [
            {
                key: UserAssignmentsPageColumnKeys.AttributeSet,
                name: columnNames.AttributeSet,
                ariaLabel: columnNames.AttributeSet,
                fieldName: columnFieldNames.AttributeSet,
                minWidth: columnWidths.attributeSet,
                maxWidth: columnWidths.attributeSet * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                isFilterable: true,
                isSorted: true,
                onRender: (
                    item: GetPrincipalAssignmentsResult,
                    index?: number,
                    column?: IColumn,
                ): JSX.Element => {
                    if (item.attributeSetName != null) {
                        return (
                            <TableCell style={detailsListFieldStyle}>
                                <EllipsisTextCss
                                    width={column?.currentWidth}
                                    text={item.attributeSetName}
                                />
                            </TableCell>
                        );
                    } else {
                        return <span></span>;
                    }
                },
                filterFunction(item: GetPrincipalAssignmentsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0 && item.attributeSetId != null) {
                        return filter.values.some((f) =>
                            item.attributeSetId
                                ?.toLocaleLowerCase()
                                .includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.Attribute,
                name: columnNames.Attribute,
                fieldName: columnFieldNames.Attribute,
                ariaLabel: columnNames.Attribute,
                minWidth: columnWidths.attribute,
                maxWidth: columnWidths.attribute * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (
                    item: GetPrincipalAssignmentsResult,
                    index?: number,
                    column?: IColumn,
                ): JSX.Element => {
                    if (item.attributeName != null) {
                        return (
                            <TableCell style={detailsListFieldStyle}>
                                <EllipsisTextCss
                                    width={column?.currentWidth}
                                    text={item.attributeName}
                                />
                            </TableCell>
                        );
                    } else {
                        return <span>{item.attributeId}</span>;
                    }
                },
                isFilterable: true,
                filterFunction(item: GetPrincipalAssignmentsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0 && item.attributeName != null) {
                        return filter.values.some((f) =>
                            item.attributeId.toLocaleLowerCase().includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.Description,
                name: columnNames.Description,
                fieldName: columnFieldNames.Description,
                ariaLabel: columnNames.Description,
                minWidth: columnWidths.description,
                maxWidth: columnWidths.description * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (
                    item: GetPrincipalAssignmentsResult,
                    index?: number,
                    column?: IColumn,
                ): JSX.Element => {
                    if (item.attributeDescription != null) {
                        return (
                            <TableCell style={detailsListFieldStyle}>
                                <EllipsisTextCss
                                    width={column?.currentWidth}
                                    text={item.attributeDescription}
                                />
                            </TableCell>
                        );
                    } else {
                        return <span></span>;
                    }
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.Value,
                name: columnNames.Value,
                ariaLabel: columnNames.Value,
                fieldName: columnFieldNames.Value,
                minWidth: columnWidths.value,
                maxWidth: columnWidths.value * xLargeMaxWidthCoeff,
                isResizable: true,
                isRowHeader: true,
                onRender: (
                    item: GetPrincipalAssignmentsResult,
                    index?: number,
                    column?: IColumn,
                ): JSX.Element => {
                    return (
                        <div style={detailsListFieldStyle}>
                            <AssignmentValueTableCell
                                value={item.value}
                                attributeType={item.attributeDataType}
                                column={column}
                            />
                        </div>
                    );
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.ExpiresOn,
                name: `${columnNames.ExpiresOn} (${timeZoneAbbr(getLocalTimeZone())})`,
                fieldName: columnFieldNames.ExpiresOn,
                ariaLabel: columnNames.ExpiresOn,
                minWidth: columnWidths.expiresOn,
                maxWidth: columnWidths.expiresOn * xLargeMaxWidthCoeff,
                isResizable: true,
                onRender: (item: GetPrincipalAssignmentsResult): JSX.Element => {
                    return expiresOnTableCell(item.expirationDate, detailsListFieldStyle);
                },
                isFilterable: true,
            },
            {
                key: UserAssignmentsPageColumnKeys.LastModifiedBy,
                name: columnNames.LastModifiedBy,
                fieldName: columnFieldNames.LastModifiedBy,
                ariaLabel: columnNames.LastModifiedBy,
                minWidth: columnWidths.lastModifiedBy,
                maxWidth: columnWidths.lastModifiedBy * xLargeMaxWidthCoeff,
                isResizable: true,
                onRender: (item: GetPrincipalAssignmentsResult): JSX.Element => {
                    return (
                        <TableCell style={detailsListFieldStyle}>
                            <LastModifiedBy lastModified={item} timeUnit={TimeUnit.Seconds} />
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetPrincipalAssignmentsResult, filter: IFilter): boolean {
                    let startDate = 0;
                    let endDate = 0;
                    if (filter.values.length > 0 && item.lastModifiedDate != null) {
                        startDate = filter.values[0] !== '0' ? parseInt(filter.values[0]) : 0;
                        endDate =
                            filter.values[1] !== '0'
                                ? parseInt(filter.values[1])
                                : Number.MAX_SAFE_INTEGER;
                        return (
                            item.lastModifiedDate >= startDate && item.lastModifiedDate <= endDate
                        );
                    }
                    return true;
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.ModifiedBy,
                name: columnNames.ModifiedBy,
                ariaLabel: columnNames.ModifiedBy,
                minWidth: 0,
                maxWidth: 0,
                isFilterable: true,
                filterFunction(item: GetPrincipalAssignmentsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.lastModifiedBy.includes(f));
                    }
                    return true;
                },
                isHidden: true,
            },
            {
                key: UserAssignmentsPageColumnKeys.CsaStatus,
                name: columnNames.CsaStatus,
                ariaLabel: columnNames.CsaStatus,
                minWidth: columnWidths.csaStatus,
                maxWidth: columnWidths.csaStatus * xLargeMaxWidthCoeff,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetPrincipalAssignmentsResult): JSX.Element => {
                    if (principalCsaStatuses[item.attributeId] !== undefined) {
                        if (principalCsaStatuses[item.attributeId] in CsaAssignmentSyncStatusType) {
                            return (
                                <TableCell
                                    ariaLabel={
                                        principalCsaStatuses[item.attributeId] ===
                                        CsaAssignmentSyncStatusType.SUCCESS
                                            ? 'Attribute assignment synced in AAD CSAs'
                                            : 'Attribute not AAD CSA sync enabled'
                                    }>
                                    <span>
                                        <Stack
                                            horizontal
                                            className={
                                                principalCsaStatuses[item.attributeId] ===
                                                CsaAssignmentSyncStatusType.SUCCESS
                                                    ? classNames.completed
                                                    : classNames.default
                                            }>
                                            <LabelInfoIcon
                                                iconHoverContent={
                                                    <span>
                                                        {principalCsaStatuses[item.attributeId] ===
                                                        CsaAssignmentSyncStatusType.SUCCESS
                                                            ? 'Attribute assignment synced in AAD CSAs'
                                                            : 'Attribute not AAD CSA sync enabled'}
                                                    </span>
                                                }
                                                iconName={
                                                    principalCsaStatuses[item.attributeId] ===
                                                    CsaAssignmentSyncStatusType.SUCCESS
                                                        ? IconNames.Completed
                                                        : IconNames.Blocked2
                                                }>
                                                &nbsp;
                                            </LabelInfoIcon>
                                        </Stack>
                                    </span>
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell
                                    ariaLabel={`Attribute not able to sync to AAD CSAs: ${
                                        principalCsaStatuses[item.attributeId]
                                    }`}>
                                    <span>
                                        <Stack horizontal className={classNames.red}>
                                            <LabelInfoIcon
                                                iconHoverContent={
                                                    <span>
                                                        {`Attribute not able to sync to AAD CSAs: ${
                                                            principalCsaStatuses[item.attributeId]
                                                        }`}
                                                    </span>
                                                }
                                                iconName={IconNames.Error}>
                                                &nbsp;
                                            </LabelInfoIcon>
                                        </Stack>
                                    </span>
                                </TableCell>
                            );
                        }
                    } else {
                        return (
                            <TableCell
                                ariaLabel={
                                    principalCsaStatuses[item.attributeId] ??
                                    'Attribute not AAD CSA sync enabled'
                                }>
                                <span>
                                    <Stack horizontal className={classNames.default}>
                                        <LabelInfoIcon
                                            iconHoverContent={
                                                <span>
                                                    {principalCsaStatuses[item.attributeId] ??
                                                        'Attribute not AAD CSA sync enabled'}
                                                </span>
                                            }
                                            iconName={IconNames.Blocked2}>
                                            &nbsp;
                                        </LabelInfoIcon>
                                    </Stack>
                                </span>
                            </TableCell>
                        );
                    }
                },
            },
            {
                key: UserAssignmentsPageColumnKeys.Actions,
                name: columnNames.Actions,
                ariaLabel: columnNames.Actions,
                minWidth: columnWidths.actions,
                maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetPrincipalAssignmentsResult): JSX.Element => {
                    let shouldDisableEditRemove = true;

                    // If the user is an admin, they can edit and remove any assignment
                    // If the user is not an admin, they can only edit and remove an assignment if they are an owner or manager of the attribute
                    if (isAdmin) {
                        shouldDisableEditRemove = false;
                    } else {
                        const attributeMetadata = attributes.find((a) => a.id === item.attributeId);
                        if (attributeMetadata) {
                            shouldDisableEditRemove = !(
                                attributeMetadata.owners.includes(
                                    principalUserContext.principalRecord.oid ?? '',
                                ) ||
                                attributeMetadata.managers.includes(
                                    principalUserContext.principalRecord.oid ?? '',
                                )
                            );
                        }
                    }

                    const contextualMenuItems: IContextualMenuProps = {
                        items: [
                            {
                                key: 'edit',
                                ariaLabel: 'Edit button - Click to edit the assignment',
                                text: 'Edit',
                                iconProps: { iconName: IconNames.Edit },
                                onClick: (): void => {
                                    setOpenModalState(AssignmentModalState.Edit);
                                    setEditableAssignment(item);
                                },
                                disabled: shouldDisableEditRemove,
                            },
                            {
                                key: 'remove',
                                ariaLabel: 'Remove button - Click to remove the assignment',
                                text: 'Remove',
                                iconProps: { iconName: IconNames.Remove },
                                onClick: (): void => {
                                    setSelectedItem(item);
                                    setIsRemoveAssignmentDialogueOpen(true);
                                },
                                disabled: shouldDisableEditRemove,
                            },
                        ],
                        shouldFocusOnMount: true,
                    };
                    return (
                        <div style={{ ...detailsListFieldStyle }}>
                            <ActionButton
                                iconProps={{ iconName: 'More' }}
                                menuProps={contextualMenuItems}
                                styles={{
                                    menuIcon: {
                                        display: 'none', // Hide the menu icon
                                    },
                                }}
                                aria-label='Attribute Set Actions Button - Click to view actions for the attribute set'
                            />
                        </div>
                    );
                },
            },
        ];
    }, [principalCsaStatuses, attributes, principalUserContext, isAdmin]);

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        const commandItems: ICommandBarItemProps[] = [
            {
                key: 'add',
                text: 'Add Assignment',
                onClick: () => setOpenModalState(AssignmentModalState.Add),
                iconProps: { iconName: IconNames.Add },
                disabled:
                    !isAdmin &&
                    !attributeSets.some(
                        (a) =>
                            a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                            a.managers.includes(principalUserContext.principalRecord.oid ?? ''),
                    ) &&
                    !attributes.some(
                        (a) =>
                            a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                            a.managers.includes(principalUserContext.principalRecord.oid ?? ''),
                    ),
                ariaLabel: 'Add Assignment Button - Click to add a new assignment',
            },
        ];

        if (isAdmin && principalRecord) {
            commandItems.push({
                key: 'csatoggle',
                commandBarButtonAs: () => (
                    <div
                        style={{ marginTop: '6px', marginLeft: '10px' }}
                        aria-label='Toggle to enable or disable CSA/AAD sync for the user'>
                        <Toggle
                            label='CSA/AAD enabled?'
                            inlineLabel
                            onText='Yes'
                            offText='No'
                            defaultChecked
                            checked={principalRecord.isCsaSyncEnabled}
                            onClick={(): void => {
                                setIsUpdateCsaAadDialogOpen(true);
                            }}
                        />
                    </div>
                ),
            });
        }
        return commandItems;
    }, [
        attributeSets,
        attributes,
        isAdmin,
        principalRecord,
        principalUserContext.principalRecord.oid,
    ]);

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'filter',
                text: 'Filter',
                onClick: () => setIsFilterOpen(true),
                iconProps: { iconName: IconNames.Filter },
                ariaLabel: 'Filter Button - Click to filter the assignments',
            },
            {
                key: 'export',
                commandBarButtonAs: () => (
                    <div
                        style={{ marginTop: '2px' }}
                        aria-label='Download report button. Click to download the user assignments report.'>
                        <ExportToExcelButton
                            buttonTitle='Download report'
                            buttonType='action'
                            getData={async () => {
                                return await getUserAssignmentsReportData(
                                    authContext,
                                    filteredAssignments,
                                );
                            }}
                            fileNamePrefix='user-assignments'
                            formatHeader={true}
                            formatType={excelFormatHeader.microsoftBrandCase}
                        />
                    </div>
                ),
            },
        ];
    }, [filteredAssignments]);

    const createOrUpdateAssignments = (): void => {
        setAssignments([]);
        setEditableAssignment(undefined);
        setShouldFetchData(true);
    };

    return (
        <div>
            <div style={{ marginBottom: '43px' }}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ paddingRight: '10px' }}>
                    <IconButton
                        iconProps={backIcon}
                        onClick={(): void => routerHistory.push('/profile/attributes/user/search')}
                        aria-label='Back button. Click to navigate back to the Attributes User Search page.'
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '12px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '396px',
                        height: 'fit-content',
                        borderRadius: '8px',
                    }}>
                    <CoreEmployeeCardFromPrincipalRecord
                        principalRecord={principalRecord}
                        showActions={false}
                        showEmail={false}
                        showManager={false}
                        showMiniCard={true}
                        style={{
                            title: {
                                fontSize: FontSizes.small,
                                textTransform: 'capitalize',
                                marginBottom: 0,
                                marginTop: 0,
                            },
                            card: { width: 'fit-content', minHeight: 0 },
                            contact: { justifyContent: 'flex-start' },
                            details: { padding: 0 },
                        }}
                    />
                </div>
            </div>
            <CommandBar
                styles={{
                    root: {
                        paddingTop: 35,
                        paddingLeft: 0,
                        background: globalStyles.backgroundColor,
                    },
                }}
                items={commandBarLeftItems}
                farItems={commandBarRightItems}
            />
            <FilterableTable
                filters={filters}
                isLoading={isLoading}
                columns={columnsFilterable}
                items={assignments}
                onFilteredItemsChange={setFilteredAssignments}
                selectionMode={SelectionMode.none}
            />
            <RemoveAssignmentModal
                principalId={id}
                onDismissed={(): void => {
                    setSelectedItem(undefined);
                    setIsRemoveAssignmentDialogueOpen(false);
                }}
                onSuccess={(): void => {
                    setShouldFetchData(true);
                }}
                isDialogueOpen={isRemoveAssignmentDialogueOpen}
                attributeId={selectedItem?.attributeId ?? ''}
            />
            {principalRecord && (
                <UpdateCsaAadStatusModal
                    principalId={id}
                    CurrentSyncStatus={principalRecord.isCsaSyncEnabled}
                    onDismissed={(): void => {
                        setIsUpdateCsaAadDialogOpen(false);
                    }}
                    onSuccess={(): void => {
                        setShouldFetchData(true);
                    }}
                    isDialogOpen={isUpdateCsaAadDialogOpen}
                />
            )}
            <AddEditCoreAssignmentPanelActionButton
                isOpen={openModalState !== undefined}
                modalState={openModalState ?? AssignmentModalState.Add}
                modalType={AssignmentModalType.User}
                onDismiss={(): void => {
                    setOpenModalState(undefined);
                    setEditableAssignment(undefined);
                }}
                onCreateOrUpdate={createOrUpdateAssignments}
                initPrincipal={principalRecord}
                initAssignment={editableAssignment}
                initPrincipalAssignments={assignments}
            />
            <UserAssignmentsFilterPanel
                isOpen={isFilterOpen}
                onDismiss={(): void => {
                    setIsFilterOpen(false);
                }}
                onSuccess={(selectedfilters): void => {
                    setFilters(selectedfilters);
                    setIsFilterOpen(false);
                }}
                onClearFilters={(): void => {
                    setFilters(new Map<string, IFilter>());
                    createOrUpdateAssignments();
                }}
                onMinimizePanel={(): void => {
                    setIsFilterOpen(false);
                }}
            />
        </div>
    );
}

const detailsListFieldStyle: CSSProperties = {
    height: 48,
    display: 'flex',
    alignItems: 'center',
};

const backIcon: IIconProps = { iconName: 'Back' };

const columnWidths = {
    attributeSet: 100,
    attribute: 150,
    description: 175,
    value: 100,
    expiresOn: 133,
    csaStatus: 150,
    lastModifiedBy: 225,
    actions: 75,
};

// filterableTable uses "key" to filter
export enum UserAssignmentsPageColumnKeys {
    AttributeSet = 'attributeset',
    Attribute = 'attribute',
    Description = 'description',
    Value = 'value',
    ExpiresOn = 'expireson',
    CsaStatus = 'status',
    LastModifiedBy = 'lastmodified',
    ModifiedBy = 'modifiedby',
    Actions = 'actions',
}

// filterableTable uses "name" as the table header text
enum columnNames {
    AttributeSet = 'Attribute Set',
    Attribute = 'Attribute',
    Description = 'Description',
    Value = 'Value',
    ExpiresOn = 'Expires on',
    CsaStatus = 'AAD/CSA Status',
    LastModifiedBy = 'Last modified by',
    ModifiedBy = 'Modified by',
    Actions = 'Actions',
}

// filterableTable uses "fieldName" to sort
enum columnFieldNames {
    Attribute = 'attributeName',
    AttributeSet = 'attributeSetName',
    Description = 'attributeDescription',
    Value = 'value',
    ExpiresOn = 'expirationDate',
    LastModifiedBy = 'lastModifiedDate', // use LastModifiedDate to sort the LastModifiedBy column
}

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '7px 30px',
});
const classNames = mergeStyleSets({
    red: [{ color: 'red' }, iconClass],
    default: [{ color: 'grey' }, iconClass],
    completed: [{ color: 'green' }, iconClass],
});
