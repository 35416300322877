import {
    DatePicker,
    ActionButton,
    Dropdown,
    IDropdownOption,
    ILabelStyles,
    IStackItemStyles,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IClearanceRecord, ISpecialAccessRecord } from 'clients/clearance-client';
import { displayRequiredFieldsErrors } from 'utils/error-display-utils';
import { toTitleCase } from 'utils/string-utils';
import { dateToLocalDate } from 'utils/time-utils';
import BasicDetailsModal, {
    ButtonParams,
    EditParams,
    SaveParams,
} from 'components/personnel-profile/common/basic-details-modal';
import { labelStackItem } from 'components/personnel-profile/common/common-types';
import {
    createClearanceDropdownOptions,
    getGroupedSpecialAcccesesSubTitle,
    getGroupedSpecialAccessesTitle,
    getModalPixelWidthValueBasedOnTextLength,
    specialAccessBilletTypeDropdownOptions,
    specialAccessStatusDropdownOptions,
    specialAccessStatusSpan,
} from 'components/personnel-profile/clearance/profile-clearance-constants';

type matchingField = { key: string; name: string };

const matchingFields: matchingField[] = [
    {
        key: 'status',
        name: 'status',
    },
    {
        key: 'billetType',
        name: 'billet type',
    },
    {
        key: 'fileNumber',
        name: 'file number',
    },
    {
        key: 'position',
        name: 'position',
    },
    {
        key: 'submitDateUTCMilliseconds',
        name: 'submitted date',
    },
    {
        key: 'grantDateUTCMilliseconds',
        name: 'granted date',
    },
    {
        key: 'briefDateUTCMilliseconds',
        name: 'briefed date',
    },
    {
        key: 'debriefDateUTCMilliseconds',
        name: 'debriefed date',
    },
    {
        key: 'rebriefDateUTCMilliseconds',
        name: 'rebriefed date',
    },
    {
        key: 'denyDateUTCMilliseconds',
        name: 'denied date',
    },
];

const validFields: string[] = ['clearanceId', 'status'];

enum Mode {
    Edit,
    View,
}

export interface GroupSpecialAccessesDetailsProps {
    specialAccesses: ISpecialAccessRecord[]; // all have the same clearance Id
    upsertRecordsFunction: (records: ISpecialAccessRecord[]) => Promise<boolean>;
    deleteRecordsFunction: (ids: string[]) => Promise<boolean>;
    clearances: IClearanceRecord[];
}

export default function GroupSpecialAccessesDetails(
    props: GroupSpecialAccessesDetailsProps,
): JSX.Element {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentMode, setMode] = useState<Mode>(Mode.View);
    const [basicDetailsModalEditButton, setBasicDetailsModalEditButton] = useState<ButtonParams>(
        EditParams,
    );
    const [basicDetailsModalCloseButtonTitle, setBasicDetailsModalCloseButtonTitle] = useState<
        'Close' | 'Cancel'
    >('Close');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [saveObject, setSaveObject] = useState<ISpecialAccessRecord | undefined>();
    const [mismatchingFields, setMismatchingFields] = useState<Set<string> | undefined>();
    const [errorMsg, setErrorMsg] = useState<JSX.Element | undefined>();
    const [isDeleteDialogAutoClosed, setDeleteDialogAutoClosed] = useState<boolean>(false);

    const minModalValueWidth = 32;

    const labelStyles: ILabelStyles = {
        root: {
            padding: 5,
            width: '130px',
        },
    };

    const valueStyle = {
        padding: 5,
    };

    const valueStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            width: getModalPixelWidthValueBasedOnTextLength(minModalValueWidth),
        },
    };

    const matchStyle = {
        borderStyle: 'solid',
        borderWidth: '0px',
        borderColor: 'initial',
    };

    const mismatchStyle = {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'red',
    };

    useEffect(() => {
        setStatesForSpecialAccessMatchingFields();
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            setStatesForSpecialAccessMatchingFields();
            if (currentMode === Mode.Edit) {
                setBasicDetailsModalEditButton(SaveParams);
                setBasicDetailsModalCloseButtonTitle('Cancel');
            } else {
                setBasicDetailsModalEditButton(EditParams);
                setBasicDetailsModalCloseButtonTitle('Close');
            }
        }
    }, [isModalOpen, currentMode]);

    function setStatesForSpecialAccessMatchingFields(): void {
        setSaveObject(generateSaveObject());
        setMismatchingFields(generateMismatchingFields());
        // error message requires mismatching fields to be populated first
        setErrorMsg(generateErrorMessage());
    }

    function generateSaveObject(): ISpecialAccessRecord | undefined {
        if (!props.specialAccesses || props.specialAccesses.length === 0) {
            return undefined;
        }

        const newSaveObject: ISpecialAccessRecord = {
            id: '',
            personnelId: '',
            clearanceId: props.specialAccesses[0].clearanceId,
            specialAccess: '',
            status: '',
        };
        // only copy the values of fields to be matched
        copyMatchingSpecialAccessFields(newSaveObject, props.specialAccesses[0]);

        // clear out any fields that don't have matching values for all special access records
        for (let i = 1; i < props.specialAccesses.length; i++) {
            clearMismatchingFields(newSaveObject, props.specialAccesses[i]);
        }

        return newSaveObject;
    }

    function clearMismatchingFields(saveObject: any, specialAccess: any): void {
        matchingFields.forEach((x) => {
            const value = saveObject[`${x.key}`];
            if (value !== specialAccess[`${x.key}`]) {
                saveObject[`${x.key}`] = value.type === 'undefined' ? undefined : '';
            }
        });
    }

    function generateMismatchingFields(): Set<string> {
        if (!props.specialAccesses || props.specialAccesses.length < 2) {
            return new Set<string>();
        }

        const newMismatchingFields = new Set<string>();

        const firstSpecialAccess = props.specialAccesses[0];
        for (let i = 1; i < props.specialAccesses.length; i++) {
            checkIfMismatchingFields(
                firstSpecialAccess,
                props.specialAccesses[i],
                newMismatchingFields,
            );
        }

        return newMismatchingFields;
    }

    function checkIfMismatchingFields(
        firstSpecialAccess: any,
        secondSpecialAccess: any,
        mismatchingFieldSet: Set<string>,
    ): void {
        matchingFields.forEach((x) => {
            const value = firstSpecialAccess[`${x.key}`];
            if (value !== secondSpecialAccess[`${x.key}`]) {
                mismatchingFieldSet.add(x.key);
            }
        });
    }

    function generateErrorMessage(): JSX.Element | undefined {
        return mismatchingFields && mismatchingFields.size > 0 ? (
            <>
                <div>Please correct the following error(s): </div>
                <ul>
                    {matchingFields
                        .filter((x) => mismatchingFields.has(x.key))
                        .map((field, index) => {
                            return (
                                <li key={index}>
                                    <span>The values for {field.name} are mismatched.</span>
                                </li>
                            );
                        })}
                </ul>
            </>
        ) : undefined;
    }

    function onEditButtonPress(): void {
        if (currentMode === Mode.View) {
            setMode(Mode.Edit);
        } else {
            if (
                props.specialAccesses &&
                props.specialAccesses.length > 0 &&
                validateSpecialAccess(saveObject)
            ) {
                setIsDisabled(true);
                props
                    .upsertRecordsFunction(getSpecialAccessRecordsWithMatchingValues())
                    .then((result: boolean) => {
                        setIsDisabled(false);
                        if (result) {
                            setModalOpen(false);
                        } else {
                            setErrorMsg(<span>There was an error saving.</span>);
                        }
                    });
            }
        }
    }

    function validateSpecialAccess(specialAccessRecord: ISpecialAccessRecord | undefined): boolean {
        if (specialAccessRecord) {
            const foundValid: string[] = [];
            for (const [key, value] of Object.entries(specialAccessRecord)) {
                const foundField = validFields.find((x) => x === key);
                if (foundField && value) {
                    foundValid.push(foundField);
                }
            }
            if (foundValid.length === validFields.length) {
                return true;
            }

            const invalidFields = validFields
                .filter((x) => foundValid.findIndex((y) => y === x) === -1)
                .map((x) => {
                    if (x === 'clearanceId') {
                        return 'Clearance';
                    }
                    return toTitleCase(x);
                });

            setErrorMsg(displayRequiredFieldsErrors(invalidFields));
        }
        return false;
    }

    function getSpecialAccessRecordsWithMatchingValues(): ISpecialAccessRecord[] {
        const specialAccessRecords: ISpecialAccessRecord[] = [];

        if (props.specialAccesses && props.specialAccesses.length > 0 && saveObject) {
            props.specialAccesses.forEach((x) => {
                // copy clearance Id incase has changed
                const specialAccessRecord = { ...x, clearanceId: saveObject.clearanceId };
                // only copy the values of fields to be matched
                copyMatchingSpecialAccessFields(specialAccessRecord, saveObject);

                specialAccessRecords.push(specialAccessRecord);
            });
        }

        return specialAccessRecords;
    }

    function onDeleteButtonPress(): void {
        if (props.specialAccesses && props.specialAccesses.length > 0) {
            setIsDisabled(true);
            const specialAccessIds = props.specialAccesses.map((x) => x.id);
            props.deleteRecordsFunction(specialAccessIds).then((result: boolean) => {
                setIsDisabled(false);
                if (result) {
                    setModalOpen(false);
                } else {
                    setDeleteDialogAutoClosed(!isDeleteDialogAutoClosed);
                    setErrorMsg(<span>There was an error deleting.</span>);
                }
            });
        }
    }

    function onCloseButtonPress(): void {
        if (currentMode === Mode.View) {
            setModalOpen(false);
        } else {
            setMode(Mode.View);
        }
    }

    function copyMatchingSpecialAccessFields(destSpecialAccess: any, srcSpecialAccess: any): void {
        matchingFields.forEach((x) => {
            destSpecialAccess[`${x.key}`] = srcSpecialAccess[`${x.key}`];
        });
    }

    function canEdit(): boolean {
        return currentMode === Mode.Edit;
    }

    function canDelete(): boolean {
        return currentMode === Mode.View;
    }

    function changeValue(property: string, value: string | number | undefined): void {
        //Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        newSaveObject[`${property}`] = value;
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: 'ModelingView' }}
                text='View Grouped Accesses'
                styles={{
                    root: {
                        maxHeight: contentMaxHeight,
                        alignSelf: 'flex-end',
                        paddingRight: '17px',
                    },
                }}
            />

            <BasicDetailsModal
                title={`Grouped Special Accesses: ${getGroupedSpecialAccessesTitle(
                    props.specialAccesses,
                )}`}
                subTitle={getGroupedSpecialAcccesesSubTitle(props.specialAccesses)}
                editButton={basicDetailsModalEditButton}
                closeTitle={basicDetailsModalCloseButtonTitle}
                isOpen={isModalOpen}
                onEditClick={onEditButtonPress}
                onCloseClick={onCloseButtonPress}
                onDeleteClick={canDelete() ? onDeleteButtonPress : undefined}
                deleteDialogProps={{
                    title: 'Delete Confirmation',
                    subText:
                        'Are you sure you want to permanently delete these special access records?',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    autoClosed: isDeleteDialogAutoClosed,
                }}
                actionButtonDisabled={isDisabled}>
                <Stack>
                    <ActionButton
                        // The purpose of this button is to catch the intial propagated onClick event
                        // when the 'add special access' button is clicked to open up this modal.
                        // Otherwise the first stack item's onChange event will trigger e.g. in this case
                        // the dropdown will select and display the top item from the dropdown selection
                        style={{ maxHeight: 0, maxWidth: 0 }}
                        hidden={true}
                        onClick={(event): void => {
                            event.stopPropagation();
                        }}
                    />

                    {!canEdit() && (
                        <table>
                            <tbody>
                                <tr>
                                    <th></th>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {x.specialAccess}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Status</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {specialAccessStatusSpan(x)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Billet Type</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {x.billetType}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>File Number</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {x.fileNumber}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Position</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {x.position}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Submitted</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.submitDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Granted</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.grantDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Briefed</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.briefDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Debriefed</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.debriefDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Rebriefed</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.rebriefDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>
                                        <Label styles={labelStyles}>Denied</Label>
                                    </td>
                                    {props.specialAccesses.map((x, index) => (
                                        <td style={valueStyle} key={index}>
                                            {dateToLocalDate(x.denyDateUTCMilliseconds)}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    )}

                    {canEdit() && (
                        <>
                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label required={true}>Status</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <Dropdown
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                            ): void => {
                                                changeValue('status', option?.key);
                                            }}
                                            placeholder='Select status'
                                            options={specialAccessStatusDropdownOptions(
                                                saveObject?.status,
                                            )}
                                            style={
                                                mismatchingFields && mismatchingFields.has('status')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label required={true}>Clearance</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <Dropdown
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                            ): void => {
                                                changeValue('clearanceId', option?.key);
                                                if (saveObject) {
                                                    saveObject.clearance = props.clearances.find(
                                                        (x) => option?.key === x.id,
                                                    );
                                                    saveObject.clearanceId =
                                                        saveObject.clearance?.id;
                                                }
                                            }}
                                            defaultSelectedKey={
                                                saveObject && saveObject.clearanceId
                                            }
                                            placeholder='Select clearance'
                                            options={createClearanceDropdownOptions(
                                                props.clearances,
                                            )}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Billet Type</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <Dropdown
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                            ): void => {
                                                changeValue(
                                                    'billetType',
                                                    option?.key !== 'None'
                                                        ? option?.key
                                                        : undefined,
                                                );
                                            }}
                                            placeholder='Select billet type'
                                            options={specialAccessBilletTypeDropdownOptions(
                                                saveObject?.billetType,
                                            )}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('billetType')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>File Number</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <TextField
                                            ariaLabel='File Number'
                                            defaultValue={saveObject?.fileNumber}
                                            onChange={(
                                                event: React.FormEvent<
                                                    HTMLInputElement | HTMLTextAreaElement
                                                >,
                                                newValue?: string | undefined,
                                            ): void => {
                                                changeValue('fileNumber', newValue);
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('fileNumber')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Position</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <TextField
                                            ariaLabel='Position'
                                            defaultValue={saveObject?.position}
                                            onChange={(
                                                event: React.FormEvent<
                                                    HTMLInputElement | HTMLTextAreaElement
                                                >,
                                                newValue?: string | undefined,
                                            ): void => {
                                                changeValue('position', newValue);
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('position')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Submitted</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Submitted Date'
                                            placeholder='Select submitted date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.submitDateUTCMilliseconds
                                                    ? new Date(saveObject.submitDateUTCMilliseconds)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'submitDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('submitDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Granted</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Granted Date'
                                            placeholder='Select granted date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.grantDateUTCMilliseconds
                                                    ? new Date(saveObject.grantDateUTCMilliseconds)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'grantDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('grantDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Briefed</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Briefed Date'
                                            placeholder='Select briefed date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.briefDateUTCMilliseconds
                                                    ? new Date(saveObject.briefDateUTCMilliseconds)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'briefDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('briefDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Debriefed</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Debriefed Date'
                                            placeholder='Select debriefed date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.debriefDateUTCMilliseconds
                                                    ? new Date(
                                                          saveObject.debriefDateUTCMilliseconds,
                                                      )
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'debriefDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('debriefDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Rebriefed</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Rebriefed Date'
                                            placeholder='Select rebriefed date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.rebriefDateUTCMilliseconds
                                                    ? new Date(
                                                          saveObject.rebriefDateUTCMilliseconds,
                                                      )
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'rebriefDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('rebriefDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item align='center' styles={labelStackItem}>
                                        <Label>Denied</Label>
                                    </Stack.Item>
                                    <Stack.Item align='center' styles={valueStackItem}>
                                        <DatePicker
                                            ariaLabel='Denied Date'
                                            placeholder='Select denied date'
                                            allowTextInput={true}
                                            value={
                                                saveObject && saveObject.denyDateUTCMilliseconds
                                                    ? new Date(saveObject.denyDateUTCMilliseconds)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void => {
                                                {
                                                    changeValue(
                                                        'denyDateUTCMilliseconds',
                                                        newDate?.getTime(),
                                                    );
                                                }
                                            }}
                                            style={
                                                mismatchingFields &&
                                                mismatchingFields.has('denyDateUTCMilliseconds')
                                                    ? mismatchStyle
                                                    : matchStyle
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </>
                    )}
                </Stack>

                {errorMsg && (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </BasicDetailsModal>
        </Stack>
    );
}
