import config from 'environments/environment';
import { GetAadHttpOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { Dictionary } from 'assets/constants/global-constants';

// TODO - Dev only. Remove before release.
import {
    placeholderCriteria,
    placeholderScopes,
} from 'components/readiness/dev/readiness-dev-utils';
// End TODO - Dev onlu

export default class ReadinessClient {
    // TODO - May 3, 22 - Works in preliminary tests.
    static async getPersonnelCriteria(authContext: IAuthContext): Promise<IPersonnelCriterion[]> {
        return placeholderCriteria; // TODO - Dev only. Remove eventually.
        const { baseUrl, criterionEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + criterionEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    // TODO - Remove this comment when the following function is verified to work
    static async getPersonnelCriterion(
        authContext: IAuthContext,
        criterionId: string,
    ): Promise<IPersonnelCriterion | undefined> {
        // TODO - Dev only.
        return placeholderCriteria.find((criterion) => criterion.id === criterionId);
        const { baseUrl, criterionEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + criterionEndpoint + criterionId;
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 204:
                return undefined;
            default:
                throw response;
        }
    }

    // TODO - Remove this comment when the following function is verified to work
    static async createAttributeCriterion(
        authContext: IAuthContext,
        request: IAttributeCriterionRequest,
    ): Promise<IAttributeCriterion> {
        const requestVar: IPersonnelCriterionRequest = Object.assign(
            request,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { '$type': CriterionRecordType.attribute },
        );
        const response = await this._createPersonnelCriterionHttpReq(authContext, requestVar);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    // TODO - Remove this comment when the following function is verified to work
    private static async _createPersonnelCriterionHttpReq(
        authContext: IAuthContext,
        request: IPersonnelCriterionRequest,
    ): Promise<Response> {
        const { baseUrl, criterionEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'POST',
            body: JSON.stringify(request),
        };
        const url = baseUrl + criterionEndpoint;
        return fetch(url, httpOptions);
    }

    // TODO - Remove this comment when the following function is verified to work
    static async deletePersonnelCriterion(
        authContext: IAuthContext,
        criterionId: string,
    ): Promise<void> {
        const { baseUrl, criterionEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'DELETE',
        };
        const url = baseUrl + criterionEndpoint + criterionId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    // TODO - May 3, 22 - Works in preliminary tests
    static async getScopes(authContext: IAuthContext): Promise<IReadinessScopeDefinition[]> {
        return placeholderScopes;
        const { baseUrl, scopesEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + scopesEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    // TODO - May 3, 22 - Works when "request.name" has a defined value.
    //                    Other fields of "request" are not tested yet.
    static async createScope(
        authContext: IAuthContext,
        request: IReadinessScopeDefinitionRequest,
    ): Promise<void> {
        const { baseUrl, scopesEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'POST',
            body: JSON.stringify(request),
        };
        const url = baseUrl + scopesEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    // TODO - Remove this comment when the following function is verified to work
    // TODO - Replace any with a decent type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static async getStatuses(authContext: IAuthContext): Promise<any> {
        const { baseUrl, statusEndpoint, aadScopes } = config.readinessServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + statusEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }
}

// Reference: Models/Scope/ReadinessScopeDefinition.cs
//            Microsoft.Personnel.Readiness.Models.ReadinessScopeDefinition
export interface IReadinessScopeDefinition {
    '$type'?: ScopeRecordType; // TODO - When actual values become available, remove question mark ? and make this a required property.
    id: string;
    name: string;
    // Criteria. This is the list of criterion that when evaluated,
    // determine a persons' Readiness Status for this scope.
    criteria: IPersonnelCriterion[];
    // Primary criterion id.
    // The primary criterion can determine by itself if the user is 'Active' for the scope.
    // It's the same as doing a logical OR against the rest of the criterion for the scope.
    primaryCriterionId: string;
}

export interface IReadinessScopeDefinitionRequest {
    name: string;
    criteria?: IPersonnelCriterion[];
    readinessScopeStatusTable?: Dictionary<string>;
    primaryCriterionId?: string;
}

export enum ScopeRecordType {
    scope = 'Microsoft.Personnel.Readiness.Models.ReadinessScopeDefinition, Microsoft.Personnel.Readiness',
}

enum CriterionRecordType {
    attribute = 'Microsoft.Personnel.Readiness.Models.Scope.AttributeCriterion, Microsoft.Personnel.Readiness',
    cloudScreening = 'Microsoft.Personnel.Readiness.Models.CloudScreeningCriterion, Microsoft.Personnel.Readiness',
    eligibility = 'Microsoft.Personnel.Readiness.Models.EligibilityCriterion, Microsoft.Personnel.Readiness',
    groupMembership = 'Microsoft.Personnel.Readiness.Models.GroupMembershipCriterion, Microsoft.Personnel.Readiness',
}

export enum CriterionType {
    attribute = 'Attribute',
    cloudScreening = 'Cloud Screening ',
    eligibility = 'Eligibility ',
    groupMembership = 'Group Membership ',
}

export interface IPersonnelCriterionRequest {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '$type': CriterionRecordType;
    name: string;
}

// Reference: Models/Scope/IPersonnelCriterion.cs
//            Microsoft.Personnel.Readiness.Models
export interface IPersonnelCriterion {
    id: string;
    name: string;
}

// Reference: Models/Scope/AttributeCriterion.cs
export interface IAttributeCriterionRequest {
    name: string;
    attributeId: string;
    attributeName: string;
}

export interface IAttributeCriterion extends IAttributeCriterionRequest {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '$type': CriterionRecordType.attribute;
    id: string;
}

// Reference: Models/Scope/CloudScreeningCriterion.cs
export interface ICloudScreeningCriterionRequest {
    name: string;
    scopeName: string;
}

export interface ICloudScreeningCriterion extends ICloudScreeningCriterionRequest {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '$type': CriterionRecordType.cloudScreening;
    id: string;
}

// Reference: Models/Scope/EligibilityCriterion.cs
export interface IEligibilityCriterionRequest {
    name: string;
    elgibilityName: string;
    eligibilityId: string;
}

export interface IEligibilityCriterion extends IEligibilityCriterionRequest {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '$type': CriterionRecordType.eligibility;
    id: string;
}

// Reference: Models/Scope/GroupMembershipCriterion.cs
export interface IGroupMembershipCriterionRequest {
    name: string;
    groupId: string;
    groupName: string;
}

export interface IGroupMembershipCriterion extends IGroupMembershipCriterionRequest {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '$type': CriterionRecordType.groupMembership;
    id: string;
}

// Reference: Microsoft.Personnel.Readiness.Models.PersonnelCriterionStatus
export interface IPersonnelCriterionStatus {
    personnelId: string;
    readinessStatus: keyof typeof IReadinessStatus;
    criterion: IPersonnelCriterion;
}

// Key is personnelId
export type ReadinessScopeStatus = Dictionary<IPersonnelCriterionStatus[]>;

// Reference: Microsoft.Personnel.Readiness.Common.Enumerations
export enum IReadinessStatus {
    active = 'Active',
    inProgress = 'In Progress',
    eligible = 'Eligible',
    noStatus = 'No Status',
}
