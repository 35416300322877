import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

export enum ReadinessTabs {
    MyOrg = 'my-org', // URL is /readiness/my-org
    MyView = 'my-view', // URL is /readiness/my-view
}

// Breadcrumbs
const topBreadcrumb: ICustomBreadcrumbItem[] = [
    {
        title: 'Readiness',
        link: '',
    },
];

const myOrgBreadcrumbs: ICustomBreadcrumbItem[] = [
    ...topBreadcrumb,
    {
        title: 'My Org',
        link: '',
    },
];

const myViewBreadcrumbs: ICustomBreadcrumbItem[] = [
    ...topBreadcrumb,
    {
        title: 'My View',
        link: '',
    },
];

export const readinessBreadcrumbs = (tab: string | undefined): ICustomBreadcrumbItem[] => {
    switch (tab) {
        case ReadinessTabs.MyOrg:
            return myOrgBreadcrumbs;
        case ReadinessTabs.MyView:
            return myViewBreadcrumbs;
        default:
            return topBreadcrumb;
    }
};
