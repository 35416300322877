import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';

/**
 * See the recommended pattern in metric-tiles.tsx.
 */

interface MetricTileProps {
    count: number;
    label: string;
}

export default function MetricTile(props: MetricTileProps): JSX.Element {
    return (
        <Stack
            className={styles.top}
            horizontalAlign='center'
            aria-label={`${props.label} count ${props.count}`}>
            <div className={styles.count}>{props.count}</div>
            <div className={styles.label}>{props.label}</div>
        </Stack>
    );
}

const styles = mergeStyleSets({
    top: {
        width: 80,
        textAlign: 'center',
    },
    count: mergeStyles(
        {
            height: 60,
        },
        globalStyles.boldFont,
        globalStyles.h1Font,
    ),
    label: mergeStyles(
        {
            height: 35,
            whiteSpace: 'normal',
        },
        globalStyles.mediumFont,
        globalStyles.boldFont,
    ),
});
