import {
    DatePicker,
    ActionButton,
    Dropdown,
    IDropdownOption,
    IStackItemStyles,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IClearanceRecord, ISpecialAccessRecord } from 'clients/clearance-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { displayRequiredFieldsErrors } from 'utils/error-display-utils';
import { toTitleCase } from 'utils/string-utils';
import { dateToLocalDate } from 'utils/time-utils';
import EllipsisText from 'components/common/ellipsis-text';
import BasicDetailsModal, {
    ButtonParams,
    EditParams,
    SaveParams,
} from 'components/personnel-profile/common/basic-details-modal';
import {
    createClearanceDropdownOptions,
    getModalPixelWidthValueBasedOnTextLength,
    specialAccessBilletTypeDropdownOptions,
    specialAccessDropdownOptions,
    specialAccessStatusDropdownOptions,
    specialAccessStatusSpan,
    clearanceStringStatusSpan,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import { labelStackItem } from 'components/personnel-profile/common/common-types';

const validFields: string[] = ['clearanceId', 'specialAccess', 'status'];

enum Mode {
    Edit,
    View,
}

export interface SpecialAccessDetailsProps {
    specialAccess?: ISpecialAccessRecord;
    employee?: IEmployeeWithEditableData;
    buttonText: string;
    buttonIcon: string;
    modalTitle: string;
    upsertRecordsFunction: (records: ISpecialAccessRecord[]) => Promise<boolean>;
    deleteRecordFunction?: (id: string) => Promise<boolean>;
    clearances: IClearanceRecord[];
}

export default function SpecialAccessDetails(props: SpecialAccessDetailsProps): JSX.Element {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentMode, setMode] = useState<Mode>(Mode.View);
    const [basicDetailsModalEditButton, setBasicDetailsModalEditButton] = useState<ButtonParams>(
        props.specialAccess ? EditParams : SaveParams,
    );
    const [basicDetailsModalCloseButtonTitle, setBasicDetailsModalCloseButtonTitle] = useState<
        'Close' | 'Cancel'
    >('Close');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [errorMsg, setErrorMsg] = useState<JSX.Element>();

    const [saveObject, setSaveObject] = useState<ISpecialAccessRecord | undefined>();

    const [isDeleteDialogAutoClosed, setDeleteDialogAutoClosed] = useState<boolean>(false);

    const [specialAccesses, setSpecialAccesses] = useState<Set<string>>(new Set<string>());

    const minModalValueWidth = 32;

    const valueStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            width: getModalPixelWidthValueBasedOnTextLength(minModalValueWidth),
        },
    };

    useEffect(() => {
        if (props.specialAccess) {
            setMode(Mode.View);
        } else {
            setMode(Mode.Edit);
        }
    }, [props.specialAccess, props.employee]);

    useEffect(() => {
        if (isModalOpen) {
            setSaveObject(
                props.specialAccess
                    ? { ...props.specialAccess }
                    : ({ personnelId: props.employee?.data?.id } as ISpecialAccessRecord),
            );
            setSpecialAccesses(new Set<string>());
            if (currentMode === Mode.Edit) {
                setBasicDetailsModalEditButton(SaveParams);
                setBasicDetailsModalCloseButtonTitle('Cancel');
            } else {
                setBasicDetailsModalEditButton(EditParams);
                setBasicDetailsModalCloseButtonTitle('Close');
            }
        }
    }, [isModalOpen, currentMode]);

    function onEditButtonPress(): void {
        if (currentMode === Mode.View) {
            setMode(Mode.Edit);
        } else {
            if (saveObject && validateSpecialAccess(saveObject)) {
                setIsDisabled(true);
                const specialAccessRecords = isAdd() ? getSpecialAccessRecords() : [saveObject];
                props.upsertRecordsFunction(specialAccessRecords).then((result: boolean) => {
                    setIsDisabled(false);
                    if (result) {
                        setModalOpen(false);
                    } else {
                        setErrorMsg(<span>There was an error saving.</span>);
                    }
                });
            }
        }
    }

    function getSpecialAccessRecords(): ISpecialAccessRecord[] {
        const specialAccessRecords: ISpecialAccessRecord[] = [];

        if (specialAccesses && saveObject) {
            specialAccesses.forEach((x) => {
                const specialAccessRecord = { ...saveObject, specialAccess: x };
                specialAccessRecords.push(specialAccessRecord);
            });
        }

        return specialAccessRecords;
    }

    function validateSpecialAccess(specialAccessRecord: ISpecialAccessRecord | undefined): boolean {
        if (specialAccessRecord) {
            // this field won't be set for multi-select add special accesses
            specialAccessRecord.specialAccess = specialAccessRecord.specialAccess ?? '';

            const foundValid: string[] = [];
            for (const [key, value] of Object.entries(specialAccessRecord)) {
                const foundField = validFields.find((x) => x === key);
                // handle multi-select add special accesses differently
                const hasValue =
                    key === 'specialAccess' && isAdd() ? specialAccesses.size > 0 : value;
                if (foundField && hasValue) {
                    foundValid.push(foundField);
                }
            }
            if (foundValid.length === validFields.length) {
                return true;
            }

            const invalidFields = validFields
                .filter((x) => foundValid.findIndex((y) => y === x) === -1)
                .map((x) => {
                    if (x === 'clearanceId') {
                        return 'Clearance';
                    } else if (x === 'specialAccess') {
                        return 'Special Access';
                    }
                    return toTitleCase(x);
                });

            setErrorMsg(displayRequiredFieldsErrors(invalidFields));
        }
        return false;
    }

    function onDeleteButtonPress(): void {
        if (props.deleteRecordFunction && saveObject && saveObject.id) {
            setIsDisabled(true);
            props.deleteRecordFunction(saveObject.id).then((result: boolean) => {
                setIsDisabled(false);
                if (result) {
                    setModalOpen(false);
                } else {
                    setDeleteDialogAutoClosed(!isDeleteDialogAutoClosed);
                    setErrorMsg(<span>There was an error deleting.</span>);
                }
            });
        }
    }

    function onCloseButtonPress(): void {
        if (currentMode === Mode.View || props.specialAccess === undefined) {
            setModalOpen(false);
        } else {
            setMode(Mode.View);
        }
    }

    function canEdit(): boolean {
        return currentMode === Mode.Edit;
    }

    function canDelete(): boolean {
        return props.deleteRecordFunction !== undefined && currentMode === Mode.View;
    }

    function isAdd(): boolean {
        return props.specialAccess === undefined;
    }

    function changeValue(property: string, value: string | number | undefined): void {
        //Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        newSaveObject[`${property}`] = value;
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: props.buttonIcon }}
                text={props.buttonText}
                styles={{ root: { maxHeight: contentMaxHeight } }}
            />

            <BasicDetailsModal
                title={props.modalTitle}
                editButton={basicDetailsModalEditButton}
                closeTitle={basicDetailsModalCloseButtonTitle}
                isOpen={isModalOpen}
                onEditClick={onEditButtonPress}
                onCloseClick={onCloseButtonPress}
                onDeleteClick={canDelete() ? onDeleteButtonPress : undefined}
                deleteDialogProps={{
                    title: 'Delete Confirmation',
                    subText:
                        'Are you sure you want to permanently delete this special access record?',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    autoClosed: isDeleteDialogAutoClosed,
                }}
                actionButtonDisabled={isDisabled}>
                <Stack>
                    <ActionButton
                        // The purpose of this button is to catch the intial propagated onClick event
                        // when the 'add special access' button is clicked to open up this modal.
                        // Otherwise the first stack item's onChange event will trigger e.g. in this case
                        // the dropdown will select and display the top item from the dropdown selection
                        style={{ maxHeight: 0, maxWidth: 0 }}
                        hidden={true}
                        onClick={(event): void => {
                            event.stopPropagation();
                        }}
                    />

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>
                                    {isAdd() ? 'Special Accesses' : 'Special Access'}
                                </Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{props.specialAccess?.specialAccess}</>}
                                {canEdit() && (
                                    <Dropdown
                                        styles={{ dropdownItemsWrapper: { maxHeight: '300px' } }}
                                        multiSelect={isAdd()} // disabled for edit
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            if (isAdd()) {
                                                const newSpecialAccesses = new Set<string>(
                                                    specialAccesses,
                                                );
                                                const specialAccess = option?.key as string;
                                                newSpecialAccesses.has(specialAccess)
                                                    ? newSpecialAccesses.delete(specialAccess)
                                                    : newSpecialAccesses.add(specialAccess);
                                                setSpecialAccesses(newSpecialAccesses);
                                            } else {
                                                changeValue('specialAccess', option?.key);
                                            }
                                        }}
                                        placeholder={
                                            isAdd()
                                                ? 'Select special accesses'
                                                : 'Select special access'
                                        }
                                        options={specialAccessDropdownOptions(
                                            props.specialAccess?.specialAccess,
                                        )}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Status</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{specialAccessStatusSpan(props.specialAccess)}</>}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('status', option?.key);
                                        }}
                                        placeholder='Select status'
                                        options={specialAccessStatusDropdownOptions(
                                            props.specialAccess?.status,
                                        )}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Clearance</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                <>
                                    {!canEdit() && props.specialAccess && (
                                        <>
                                            {props.specialAccess?.clearance && (
                                                <>
                                                    {props.specialAccess?.clearance?.level &&
                                                        props.specialAccess?.clearance?.agency &&
                                                        clearanceStringStatusSpan(
                                                            props.specialAccess?.clearance,
                                                        )}
                                                </>
                                            )}
                                            {!props.specialAccess?.clearance && (
                                                <>{`${props.specialAccess?.clearanceId}`}</>
                                            )}
                                        </>
                                    )}
                                    {canEdit() && (
                                        <Dropdown
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                                index?: number,
                                            ): void => {
                                                changeValue('clearanceId', option?.key);
                                                if (saveObject) {
                                                    saveObject.clearance = props.clearances.find(
                                                        (x) => option?.key === x.id,
                                                    );
                                                    saveObject.clearanceId =
                                                        saveObject.clearance?.id;
                                                }
                                            }}
                                            defaultSelectedKey={
                                                saveObject && saveObject.clearanceId
                                            }
                                            placeholder='Select clearance'
                                            options={createClearanceDropdownOptions(
                                                props.clearances,
                                            )}
                                        />
                                    )}
                                </>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    {!canEdit() && (
                        <Stack.Item>
                            <Stack horizontal>
                                <Stack.Item align='center' styles={labelStackItem}>
                                    <Label>Contract</Label>
                                </Stack.Item>
                                <Stack.Item align='center' styles={valueStackItem}>
                                    <>{`${
                                        props.specialAccess?.clearance?.contractId
                                            ? props.specialAccess?.clearance?.contractId
                                            : ''
                                    }`}</>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    )}

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Billet Type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{props.specialAccess?.billetType}</>}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue(
                                                'billetType',
                                                option?.key !== 'None' ? option?.key : undefined,
                                            );
                                        }}
                                        placeholder='Select billet type'
                                        options={specialAccessBilletTypeDropdownOptions(
                                            props.specialAccess?.billetType,
                                        )}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>File Number</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.specialAccess?.fileNumber}
                                    />
                                )}
                                {canEdit() && (
                                    <TextField
                                        ariaLabel='File Number'
                                        defaultValue={props.specialAccess?.fileNumber}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('fileNumber', newValue);
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Position</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.specialAccess?.position}
                                    />
                                )}
                                {canEdit() && (
                                    <TextField
                                        ariaLabel='Position'
                                        defaultValue={props.specialAccess?.position}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('position', newValue);
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Submitted</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.submitDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Submitted Date'
                                        placeholder='Select submitted date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.submitDateUTCMilliseconds
                                                ? new Date(saveObject.submitDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'submitDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Granted</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.grantDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Granted Date'
                                        placeholder='Select granted date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.grantDateUTCMilliseconds
                                                ? new Date(saveObject.grantDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'grantDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Briefed</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.briefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Briefed Date'
                                        placeholder='Select briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.briefDateUTCMilliseconds
                                                ? new Date(saveObject.briefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'briefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Debriefed</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.debriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Debriefed Date'
                                        placeholder='Select debriefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.debriefDateUTCMilliseconds
                                                ? new Date(saveObject.debriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'debriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Rebriefed</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.rebriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Rebriefed Date'
                                        placeholder='Select rebriefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.rebriefDateUTCMilliseconds
                                                ? new Date(saveObject.rebriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'rebriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Denied</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.specialAccess?.denyDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Denied Date'
                                        placeholder='Select denied date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.denyDateUTCMilliseconds
                                                ? new Date(saveObject.denyDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'denyDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {errorMsg && (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </BasicDetailsModal>
        </Stack>
    );
}
